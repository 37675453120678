<template>
  <config-container :error_message="error_message" max_width="800">

      <v-row class="px-3 py-3">
        <h1> Array Details </h1>
        <v-spacer></v-spacer>
        <v-btn color="error" small v-if="!is_locked && !isNew" @click="show_delete=true"> <v-icon small dark left > mdi-delete </v-icon> Delete Array </v-btn>
      </v-row>
      <p> Please enter required information about the collector array </p>


      <delete-dialog
          :show="show_delete"
          header="Deleting Array"
          @delete="delete_array"
          @cancel="show_delete=false"
      >
       Are you sure you want to delete <v-chip label dense> Array {{array_name}} </v-chip> from this plant? <br>
       All information about the array will be lost!
      </delete-dialog>

      <v-form v-model="form_valid" lazy-validation @submit="create_array" ref="form">

        <v-row class="px-4 py-1" v-if="!isNew">
          <v-spacer></v-spacer>
          <v-btn color="ternary" small v-if="is_locked" @click="is_locked = !is_locked"> <v-icon small dark left > mdi-pencil </v-icon> Edit </v-btn>
          <v-btn color="ternary" small v-else text @click="is_locked = !is_locked"> <v-icon small dark left > mdi-pencil </v-icon> Cancel Edit </v-btn>
        </v-row>

        <ConfigSection name="Basics">
          <configTextFieldRow
              v-model="array_name"
              label="Array Name"
              description="Name of array. Must be unique within parent plant."
              placeholder="your custom name"
              :disabled="is_locked"
              required
          ></configTextFieldRow>
          <configTextFieldRow
              v-model="area_gr"
              label="Gross Area"
              suffix="m²"
              description="Total gross collector area of the collector array"
              placeholder="4000.0 (for example)"
              :minimum="0"
              :disabled="is_locked"
              required
          ></configTextFieldRow>
        </ConfigSection>

        <ConfigSection name="Position">
          <configTextFieldRow
              v-model="tilt"
              label="Tilt"
              suffix="°"
              description="Tilt angle of the array, defined as angle from the horizontal. Lying on the ground=0, facing the horizon=90"
              placeholder="30.0 (for example)"
              :minimum="0"
              :maximum="90"
              required
              :disabled="is_locked"
          ></configTextFieldRow>
          <configTextFieldRow
              v-model="azim"
              label="Azimuth"
              suffix="°"
              description="Azimuth angle of the array surface. North=0, East=90, South=180, West=270."
              placeholder="180.0 (for example)"
              required
              :minimum="0"
              :maximum="360"
              :disabled="is_locked"
          ></configTextFieldRow>
          <configTextFieldRow
              label="Row Spacing"
              v-model="row_spacing"
              suffix="m"
              description="
              Spacing between the collector rows in the array (front to front), measured on the ground (not on the horizontal projection).
              This information is used to calculated array-internal shading ('row-to-row shading' in our docs).
              For roof-integrated plants please use a large number for row spacing, like 100 m.
              For heterogeneous arrays, use the smallest row spacing value."
              placeholder="3.5 (for example)"
              :minimum="0.01"
              required
              :disabled="is_locked"
          ></configTextFieldRow>
          <configTextFieldRow
              label="Sun Minimal Elevation"
              v-model="min_elevation_shadow"
              suffix="°"
              description="Lower bound for sun elevation where no more external shading occurs (for example by adjacent buildings). The default is 0°, which means no external shading. Valid for all angles in [0°, 90°]."
              placeholder="0.0 (for no external shading)"
              :minimum="0"
              :maximum="90"
              :disabled="is_locked"
          ></configTextFieldRow>
          <!--          <config_input-->
          <!--              v-model="ground_tilt"-->
          <!--              label="Ground Tilt"-->
          <!--              suffix="degrees"-->
          <!--              description="Tilt angle of the ground or more generally of the plane on which the collector field is mounted; in the direction of the azimuth of the collector field; positive values increase the absolute tilt of the collectors"-->
          <!--              placeholder="0 (for example)"-->
          <!--              :minimum="0"-->
          <!--              :maximum="90"-->
          <!--              :disabled="is_locked"-->
          <!--          ></config_input>-->
          <!--          <config_input-->
          <!--              label="Ground Level"-->
          <!--              v-model="ground_level"-->
          <!--              suffix="m"-->
          <!--              description="Distance of the lowest part of a collector from the ground (back edge)"-->
          <!--              placeholder="0.5 (for example)"-->
          <!--              :minimum="0"-->
          <!--              :disabled="is_locked"-->
          <!--          ></config_input>-->
        </ConfigSection>

        <ConfigSection name="Collector">
          <v-row >
            <v-subheader class="label"> Collector: <sup :class="{is_invalid: !form_valid}">(*)</sup></v-subheader>
            <v-text-field
                @click.native.prevent.stop="showAllCollectors"
                @focus.once="showAllCollectors"
                filled dense hide-details outlined
                v-model="collector_name"
                :rules="[rules.required]"
                placeholder="(click to select a collector)"
                readonly
                :disabled="is_locked"
                clearable
            ></v-text-field>
         </v-row>
        </ConfigSection>
        <br>

        <v-dialog v-model="show_modal" max-width="1700px">
          <v-card class="pa-3">
            <h1> Select Collector </h1>
           <CollectorListComponent :selected_collector="collector_name" @input="selectCollector" cancel-button="Close" @cancel="show_modal = false"></CollectorListComponent>
          </v-card>
        </v-dialog>

        <v-row>
          <template v-if="is_locked">
            <v-btn color="white" outlined @click="$router.go(-1)"> Back </v-btn>
            <v-spacer></v-spacer>
          </template>
          <template v-else>
            <v-btn color="white" outlined @click="$router.go(-1)"> Cancel </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="white" light :class="{'disable-events': !form_valid}" @click="create_array"> Save </v-btn>
          </template>

        </v-row>
      </v-form>
    </config-container>
</template>

<script>

import * as backend from "@/plugins/backend_api";
import ConfigSection from "@/components/ConfigSection";
import configTextFieldRow from "@/components/Config_TextFieldRow.vue";
import configContainer from "@/components/ConfigContainer";
import DeleteDialog from "@/components/DialogDelete.vue";
import {INPUT_RULES} from "@/components/Config_TextField.vue";
import CollectorListComponent from "@/components/CollectorListComponent.vue";

export default {
  name: 'ArrayDetails',
  components: {
    CollectorListComponent, DeleteDialog, configContainer, configTextFieldRow, ConfigSection},
  props: {
    plant_id: {},
    array_id: {},
    unlocked: Boolean,
  },
  data: () => ({
    // Data from Backend
    collector_names: [],
    is_locked: true,
    show_delete: false,
    pooling_counter: 0,
    max_pooling_counter: 2000,

    // inputs
    azim: null,
    tilt: null,
    array_name: null,
    row_spacing: null,
    area_gr: null,
    ground_tilt: 0,
    ground_level: null,
    min_elevation_shadow: null,
    collector_name: null,

    // combined inputs used for updating/creating the array
    new_array: {},

    // internal use
    error_message: "",
    form_valid: false,
    rules: INPUT_RULES,
    show_modal: false,
    show_collector_edit: true,
  }),
  watch: {
    array_id(){
      this.populate_new_array()
    },
  },
  mounted() {
    this.is_locked = !this.unlocked && !this.isNew
    this.populate_new_array()
  },
  computed: {
    isNew(){
      return (this.array_id === undefined)
    }
  },
  methods: {
    // API Methods (get)
    async get_collector_info(){
      return await backend.get_array(this.array_id).then(res => {
        this.new_array = res.data
      }).catch(res => {
        this.error_message = res
      })
    },
    // OTHER METHODS
    showAllCollectors(){
      this.show_modal = true
      // this.$router.push({path: this.$router.currentRoute.fullPath + "/collectors"})
    },
    populate_new_array(){
      if (this.array_id !== undefined) {
        this.get_collector_info().then(() => {
          this.array_name = this.new_array["name"]
          this.collector_name = this.new_array["collector"]

          this.area_gr = !this.new_array["area_gr"] ? null: this.new_array["area_gr"]["magnitude"]
          this.azim = !this.new_array["azim"] ? null: this.new_array["azim"]["magnitude"]
          this.tilt = !this.new_array["tilt"] ? null: this.new_array["tilt"]["magnitude"]
          this.row_spacing = !this.new_array["row_spacing"] ? null: this.new_array["row_spacing"]["magnitude"]
          // this.ground_level = !this.new_array["ground_level"] ? 0 : this.new_array["ground_level"]["magnitude"]
          // this.ground_tilt = !this.new_array["ground_tilt"] ? 0 : this.new_array["ground_tilt"]["magnitude"]
          this.min_elevation_shadow = !this.new_array["min_elevation_shadow"] ? 0 : this.new_array["min_elevation_shadow"]["magnitude"]
        })
      }
    },

    printCollectorInfo(){
      let collector = this.collector
      if (collector === undefined){
        return "Select a collector to show its information"
      }
      let eta0hem = "eta0hem" in collector && collector.eta0hem !== null ? collector["eta0hem"].magnitude.toFixed(3) : "???"
      let a1 = "a1" in collector && collector.a1 !== null ? collector["a1"].magnitude.toFixed(3) : "???"
      let a2 = "a2" in collector && collector.a2 !== null ? collector["a2"].magnitude.toFixed(3) : "???"
      let a5 = "a5" in collector && collector.a5 !== null ? collector["a5"].magnitude.toFixed(3) : "???"
      let license_nr = "licence_number" in collector ? collector["licence_number"] : "no-licence"
      let test_type = "test_type" in collector ? collector["test_type"] : "unkown-test-type"
      return `eta0hem=${eta0hem}; a1=${a1}[W/m².K]; a2=${a2}[W/m².K²]; a5=${a5}[J/m².K]; ${license_nr}; ${test_type}`
    },
    selectCollector(collector){
      this.collector_name = collector.name
      this.show_modal = false
    },
    create_array() {
      this.$refs.form.validate()

      if (this.form_valid) {
        // append data
        // this.new_array = {} // reset array
        // this.new_array["id"] = this.array_id
        this.new_array["name"] = this.array_name
        this.new_array["area_gr"] = this.area_gr
        this.new_array["azim"] = this.azim
        this.new_array["tilt"] = this.tilt
        this.new_array["row_spacing"] = this.row_spacing
        this.new_array["min_elevation_shadow"] = this.min_elevation_shadow
        this.new_array["ground_level"] = this.ground_level
        this.new_array["ground_tilt"] = this.ground_tilt
        this.new_array["collector"] = this.collector_name

        // attach units to respective fields
        const field_units = {
          "azim": "°",
          "tilt": "°",
          "row_spacing": "m",
          "min_elevation_shadow": "°",
          "area_gr": "m²",
          "ground_level": "m",
          "ground_tilt": "°",
        }
        Object.keys(field_units).forEach(name => {
          const value = this.new_array[name]
          if (value != null) {
            this.new_array[name] = {"magnitude": value, "units": field_units[name],}
          }
        })

        // perform add or update
        if (this.isNew) {
          this.add_array(this.new_array)
        } else {
          this.update_array(this.new_array)
        }
      }
    },

    // API METHODS (create/update)
    async delete_array(){
      return await backend.delete_array(this.array_id).then(() => {
        this.routeToArrayOverview()
      }).catch(res => {
        this.error_message = res
      })
    },
    async add_array(array_data) {
      return await backend.create_array(this.plant_id, array_data).then(() => {
        this.routeToArrayOverview()
      }).catch(res => {
        this.error_message = res
      })
    },
    async update_array(array_data) {
      return await backend.update_array(array_data).then(() => {
        this.routeToArrayOverview()
      }).catch(res => {
        this.error_message = res
      })
    },

    // routing
    routeToArrayOverview(){
      let route = (this.$router.currentRoute.path.includes("/plant/")) ? "arrayOverview" : "setup_arrayOverview"
      this.$router.push({name: route, params: {"plant_id": this.plant_id}})
    },
  },
}
</script>

<style scoped>

.toolbar{
  padding: 10px  30px 5px 30px;
}


sup.is_invalid{
  color: #dc6565;
}


.label{
  width: 150px;
}

.disable-events {
  opacity: 0.5;
}


</style>