<template>
  <v-container
      class="configContainer"
      :style="{'max-width': max_width + 'px'}"
      @dragenter.stop.prevent="$emit('dragenter', $event)"
      @dragover.stop.prevent
      @dragleave.stop.prevent
      @drop.stop.prevent="$emit('drop', $event)"
  >
    <top-line-success :success_message="success_message"></top-line-success>
    <top-line-alert :error_message="error_message"></top-line-alert>
    <v-card :color="color" :dark="dark" class="config_card">
      <slot></slot>
    </v-card>


  </v-container>
</template>
<script>

import TopLineAlert from "@/components/TopLineAlert";
import TopLineSuccess from "@/components/TopLineSuccess.vue";
export default {
  components: {TopLineSuccess, TopLineAlert},
  props: {
    error_message: {default: ""},
    success_message: {default: ""},
    max_width: {default: 1200},
    error_response: {default: null},
    color: {default: "primary"},
    dark: {default: true, type: Boolean},
  },
  name: "configContainer"
}
</script>

<style scoped>

.config_card{
  padding: 30px;
}

.configContainer{
  white-space: pre-line;
  padding: 20px;
  height: 100%;
}
</style>