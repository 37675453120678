<template>
    <LoadingScreen title="Loading Demo-Plant" :value="true">
      The Demo-Plant will be created soon. <br> Please wait a few seconds.
    </LoadingScreen>
</template>

<script>
import {create_demo_plant} from "@/plugins/backend_api";
import LoadingScreen from "@/components/LoadingScreen.vue";

export default {
  name: "LoadDemo",
  components: {LoadingScreen},
  mounted() {
    this.createDemoPlant()
  },
  methods: {
    createDemoPlant() {
      create_demo_plant().then(() => {
        this.$router.push({name: "plant_selection"})
      }).catch(res => {
        this.$router.push({name: "plant_selection",  params: {error: res}})
      })
    },
  }
}
</script>

<style scoped>

</style>