<template>
  <v-alert prominent type="error" color="secondary">
  <h3> No Data Available </h3>
  <v-row class="pa-0 ma-0">
    <span> Please go to the Data Upload Page to upload data. </span>
    <v-spacer>
    </v-spacer>
    <v-btn color="ternary" :to="{name: 'dataUpload'}"> > Data Upload </v-btn>
  </v-row>
</v-alert>
</template>

<script>

export default {
  name: "TopLineAlertNoData",
  data: () => ({
  }),
  methods: {
  },
}
</script>

<style scoped>

p {
  margin-bottom: 0;
}
</style>