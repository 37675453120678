<template>
  <v-container class="plant_selection_container">
    <top-line-alert :error_message="error_message"></top-line-alert>

    <LoadingScreen title="Fetching Available Plants" v-model="isLoading">
      Fetching plants.<br> if this takes more than a few seconds then the backend might have some issues.
    </LoadingScreen>

    <v-toolbar flat >
      <h1> Plants </h1>
      <v-spacer></v-spacer>
      <v-text-field class="my-auto" height="30px" hide-details style="max-width: 300px" outlined dense v-model="search" placeholder="Filter Plants" @input="filterPlants" append-icon="mdi-magnify"></v-text-field>
      <v-btn class="mx-2" color="ternary" dark @click="exportAllPlants" > <v-icon> mdi-export </v-icon> Export </v-btn>
    </v-toolbar>

    <v-card color="primary" class="pa-2 ma-auto" width="90%">
      <v-simple-table class="primary plant_selection_table" dense dark height="calc(100vh - 350px)" fixed-header>
        <thead>
          <tr>
            <th class="secondary">ID</th>
            <th class="secondary">Name</th>
            <th class="secondary">Operator</th>
            <th class="secondary" style="text-align: center">Actions</th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="plant in this.filteredPlants" :key="plant.id" @click="selectPlant(plant)">
          <td >{{plant.id}}</td>
          <td >{{plant.name}}</td>
          <td >{{plant.operator}}</td>
          <td style="text-align: center">
            <v-btn-toggle rounded>
              <v-btn x-small @click.stop="showDeletePlant(plant)" color="secondary" title="delete plant"> <v-icon small color="warning" > mdi-delete </v-icon></v-btn>
              <v-btn x-small @click.stop="selectPlant(plant)" color="ternary" title="select plant"> <v-icon small color="white"> mdi-chevron-right </v-icon></v-btn>
            </v-btn-toggle>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
    </v-card>

    <v-row class="px-5">
      <v-btn class="mx-2" text color="ternary" @click="show_import_dialog = true"> <v-icon> mdi-import </v-icon> Import Plant </v-btn>
      <v-spacer></v-spacer>
      <v-btn class="mx-2" to="/load_demo" text color="ternary"> add demo Plant </v-btn>
      <v-btn class="mx-2 white--text" @click="goToAddPlant()" color="primary"> add Plant </v-btn>
    </v-row>


    <delete-dialog
        :show="show_delete"
        header="Deleting Plant"
        @delete="delete_plant"
        @cancel="show_delete=false"
    >
      Are you sure you want to delete the Plant <v-chip label dense> {{selected_plant ? selected_plant.name : ""}} </v-chip>? <br>
      All information and data about the plant will be deleted!
    </delete-dialog>

    <SunPeekDialog
        v-model="show_import_dialog"
        btn_confirm="Import"
        header="Plant Import"
        color="primary"
        color_header="white"
        width="600"
        persistent
        v-on:confirm="doImport"
    >
      <v-form ref="importForm" v-model="importFormValid" lazy-validation>
        <top-line-alert :error_message="error_message_import" style="margin: -25px -20px 10px -25px"></top-line-alert>
        <p> Please insert a valid plant-configuration file (json) to import the plant into SunPeek:</p>

        <v-file-input
            class="mb-1 mx-4"
            label="Choose a config file to import"
            v-model="importFileInput"
            dense outlined
            accept="application/json"
            hide-details
            single-line
            loader-height="5"
            @change="checkDuplicatedName"
            :rules="[value => !!value || value === 0 || 'Required Input']"
        ></v-file-input>

        <config-text-field-row
            class="py-2 ma-0"
            label="New plant name"
            v-model="importPlantName"
            v-if="importFileInput !== null"
            required
            description="Name that should be used for this plant."
            :rules="[value => !this.plants.map(p => p.name).includes(value) || 'A plant with this name already exists in the database. Please choose another name']"
        ></config-text-field-row>
      </v-form>


    </SunPeekDialog>
  </v-container>
</template>

<script>

  import * as backend from "@/plugins/backend_api"
  import TopLineAlert from "@/components/TopLineAlert.vue";
  import LoadingScreen from "@/components/LoadingScreen.vue";
  import DeleteDialog from "@/components/DialogDelete.vue";
  import {checkIfIncluded} from "@/pages/helper";
  import {exportPlant, importPlant} from "@/plugins/backend_api";
  import SunPeekDialog from "@/components/Dialog.vue";
  import ConfigTextFieldRow from "@/components/Config_TextFieldRow.vue";

  export default {
    name: 'PlantSelection',
    components: { ConfigTextFieldRow, SunPeekDialog, DeleteDialog, LoadingScreen, TopLineAlert},
    props: ["error"],
    data: () => ({
      filteredPlants: [],
      plants: [],
      search: "",
      error_message: "",
      error_message_import: "",
      isLoading: false,
      show_delete: false,

      selected_plant: null,

      show_import_dialog: false,
      importFormValid: true,
      importFileInput: null,
      importFileContent: null,
      importPlantName: null,

    }),
    watch: {
      error: {
        handler(){
          this.error_message = this.error || ""
        },
        deep: true
      }
    },
    mounted() {
      this.get_plants()
      this.error_message = this.error || ""
    },
    methods: {
      get_plants() {
        this.isLoading = true
        backend.get_plants().then(response => {
          this.plants = response.data
          this.plants.sort((a,b) => a.id - b.id)
          this.filterPlants()
          let noPlantsAvailable = this.filteredPlants.length === 0
          if (noPlantsAvailable) {
            this.$router.push({name: "landing_page"})
          }
        }).catch(res => {
          this.error_message = res
        }).finally(() => {
          this.isLoading = false
        })
      },
      filterPlants() {
        this.filteredPlants = []
        for (let i = 0; i < this.plants.length; i = i + 1) {
          let plant = this.plants[i];
          let searchString = this.search.toLowerCase();
          let isNameIncluded = checkIfIncluded(plant["name"], searchString)
          let isOperatorIncluded = checkIfIncluded(plant["operator"], searchString)
          let isLocationIncluded = checkIfIncluded(plant["location_name"], searchString)
          let isIDIncluded = checkIfIncluded(plant["id"], searchString)
          let isIncludedAnywhere = isNameIncluded || isOperatorIncluded || isLocationIncluded || isIDIncluded
          if (isIncludedAnywhere) {
            this.filteredPlants.push(plant)
          }
        }
      },
      goToAddPlant() {
        this.$router.push({name: "setup_newPlant"})
      },
      selectPlant(plant) {
        this.$router.push({name: "plant", params: {"plant_id": plant.id}})
      },
      exportAllPlants(){
        this.plants.forEach(plant => {
          this.doExport(plant)
        })
      },
      doExport(plant) {
        return exportPlant(plant).then(res => {
          const data = JSON.stringify(res.data)
          const blob = new Blob([data], {type: 'text/plain'})
          const link = document.createElement("a");
          link.setAttribute("href", window.URL.createObjectURL(blob));
          link.setAttribute("download", plant.name + ".json");
          link.click();
        })
      },

      readFile(file) {
        return new Promise((resolve, reject) => {
          let content = '';
          const reader = new FileReader();
          reader.onloadend = function(e) {
            content = JSON.parse(e.target.result)
            resolve(content);
          };
          reader.onerror = function(e) {reject(e);};
          reader.readAsText(file);
        });
      },
      checkDuplicatedName(){
        this.readFile(this.importFileInput).then((res) => {
          this.importPlantName = res.plant.name
          this.importFileContent = res
        })
      },
      doImport(){
        this.$refs.importForm.validate()
        if (this.importFormValid){
          this.importFileContent["plant"]["name"] = this.importPlantName
          importPlant(this.importFileContent).then(() => {
            this.show_import_dialog = false
            this.get_plants()
          }).catch(res => {
            this.error_message_import = res
          })
        }
      },
      showDeletePlant(plant){
        this.selected_plant = plant
        this.show_delete = true
      },
      delete_plant(){
        return backend.delete_plant(this.selected_plant.id).then(() => {
          this.$router.go() //refreshing the page
        }).catch(res => {
          this.error_message = res
        }).finally(() => {
          this.selected_plant = null
          this.show_delete = false
        })
      }

    }
}
</script>

<style scoped>

  .plant_selection_container{
    height: 100%;
    width: 100%;
    padding: 30px;
  }

  .plant_selection_container .row{
    margin: 10px;
  }

  .plant_selection_table tr:hover td{
    background-color: var(--v-ternary-base) !important;
  }
</style>