<template>
  <v-container fluid class="pa-0 ma-0">

    <SensorMapping_Plant
        v-if="component.type.toLowerCase() === 'plant'"
        :sensor_names="sensor_names"
        :plant="component"
        :raw_sensors="raw_sensors"
        v-on:save="$emit('save')"
        v-on:error="res => $emit('error', res)"
        v-on:isValid="res => $emit('isValid', res)"
        :hide_optional="hide_optional"
    ></SensorMapping_Plant>

    <SensorMapping_Array
        v-else-if="component.type.toLowerCase() === 'array'"
        :sensor_names="sensor_names"
        :array="component"
        :raw_sensors="raw_sensors"
        v-on:save="$emit('save')"
        v-on:error="res => $emit('error', res)"
        v-on:isValid="res => $emit('isValid', res)"
        :hide_optional="hide_optional"
    ></SensorMapping_Array>


    <v-row class="px-2 mb-1 ma-0">
      <v-spacer></v-spacer>
      <span style="font-size: 12px">
          {{component.isValid ? 'sensor mapping successful' : 'some required sensor is still missing on this page' }}
          <v-icon right small v-if="component.isValid"> mdi-check  </v-icon>
          <v-icon right small v-else title="Mapping incomplete" color="lightwarning" > mdi-alert-circle </v-icon>
      </span>

<!--      <v-spacer></v-spacer>-->
<!--      <v-btn-toggle dense rounded active-class="None" :max="0" multiple :value="[]">-->
<!--        <v-btn x-small color="secondary" outlined @click="$emit('switch',-1)"> Prev</v-btn>-->
<!--        <v-btn x-small color="secondary" @click="$emit('switch',+1)" :disabled="!component.isValid"> Next</v-btn>-->
<!--      </v-btn-toggle>-->
    </v-row>

  </v-container>
</template>
<script>

import SensorMapping_Plant from "@/components/SensorMapping_Plant.vue";
import SensorMapping_Array from "@/components/SensorMapping_Array.vue";

export default {
  name: "SensorMapping",
  components: {SensorMapping_Array, SensorMapping_Plant},
  props: {
    component: {type: Object},
    raw_sensors: {type: Array},
    sensor_names: {type: Array},
    hide_optional: Boolean,
  },
}
</script>

<style scoped>

</style>