<template>
  <v-btn class="flexcol" icon :disabled="disabled" :color="color">
    <v-icon small> {{ icon }} </v-icon>
    <span style="font-size: 8px"> {{text}}</span>
  </v-btn>
</template>


<script>
  export default {
    name: "VerticalButton",
    props: {
      text: String,
      icon: String,
      color: String,
      small: Boolean,
      disabled: Boolean,
  },
  }
</script>

<style>
.flexcol .v-btn__content {
  flex-direction: column;
}
</style>

