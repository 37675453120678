<template>
  <v-container>
    <top-line-alert :error_message="error_message"></top-line-alert>
    <loading-screen title="Generating PC-Method Report" v-model="isGeneratingReport">
      The PC-Report will be created soon. <br> Please wait a few seconds.
    </loading-screen>

    <TopLineAlertNoData v-if="isNoData"></TopLineAlertNoData>
    <template v-else>
    <v-container style="max-width: 1200px">

      <v-row style="padding: 10px">
        <h2> Thermal Power Check </h2>
        <InfoTooltip>
          The <b>Power Check</b> is a procedure to check the power output of collector arrays as specified in Chapter 5 of ISO 24194:2022.
          It compares measured and estimated power close to stable full power operation, using the measurement data and data sheet parameters of the collectors.
          <br><i>No warranty is given for the accuracy and completeness of the calculation.</i>
        </InfoTooltip>
        <v-spacer></v-spacer>
      </v-row>

      <v-toolbar dense color="primary" flat dark outlined class="my-0">
        <v-subheader> Settings: </v-subheader>
        <v-chip class="mx-1" small @click="isSettingsOpen=true" outlined> Method: {{ getSettingValue('evaluation_mode')}} </v-chip>
        <v-chip class="mx-1" small @click="isSettingsOpen=true" outlined> Formula: {{ getSettingValue('formula') }}</v-chip>
        <v-chip class="mx-1" small @click="isSettingsOpen=true" outlined> f <sub>u</sub>: {{ getSettingValue('safety_uncertainty', "%") }} </v-chip>
        <v-chip class="mx-1" small @click="isSettingsOpen=true" outlined> f <sub>p</sub>: {{ getSettingValue('safety_pipes', "%") }} </v-chip>
        <v-chip class="mx-1" small @click="isSettingsOpen=true" outlined> f <sub>o</sub>: {{ getSettingValue('safety_others', "%") }} </v-chip>
        <v-chip class="mx-1" small @click="isSettingsOpen=true" outlined> use wind: {{ getSettingValue('wind_used') }}</v-chip>
        <v-spacer></v-spacer>
        <v-icon @click="isSettingsOpen=true"> mdi-cog</v-icon>
      </v-toolbar>

      <v-toolbar color="primary" dark flat outlined>
        <v-subheader> Measurement Period: </v-subheader>
        <v-text-field class="mx-2" label="Start" outlined dense type="date" style="max-width: 160px" hide-details v-model="start"></v-text-field>
        <v-text-field class="mx-2" label="End" outlined dense type="date" style="max-width: 160px" hide-details v-model="end"></v-text-field>
        <v-icon small title="clear range" @click="reset_start_end"> mdi-close</v-icon>
        <v-spacer></v-spacer>
        <v-btn color="accent" class="primary--text" @click="runPcMethod"> Run </v-btn>
        <v-btn color="ternary" @click="generatePDF" class="mx-2 px-3"> <v-icon> mdi-download</v-icon> PDF </v-btn>
      </v-toolbar>


      <v-card class="mt-4" flat>
        <v-card color="white" elevation="0" class="pa-0" height="400px">

          <v-row class="pa-0 ma-0 fill-height">

            <div class="noResultsSpan" v-if="isInitial">
              Please click on RUN to execute the Performance Check
            </div>

            <div class="noResultsSpan" v-else-if="isLoading">
              <v-progress-circular color="primary" size="14" width="2" indeterminate></v-progress-circular> <span> Calculating results. </span>
            </div>

            <div class="noResultsSpan" v-else-if="error_message != ''">
              <span style="margin-left: 5px; color: red"> An Error occurred. Please check the error message above and try again </span>
            </div>
            <div class="noResultsSpan" v-else-if="results.at(-1).timestamps === null">
              <span style="margin-left: 5px; color: red"> No valid Timestamps found. Please check your Configuration and upload more data in the DataUploader </span>
            </div>

            <template v-else>
              <pc-scatter class="scatter_plot" :tool="tool" v-model=selected_points :estimated="estimated_power" :measured="measured_power" :timestamps="timestamps" :f_total="f_total" :useSafety="withSafety" :reset_counter="reset_counter"></pc-scatter>
              <pc-timeseries class="timeseries_plot" :tool="tool" v-model=selected_points :estimated="estimated_power" :measured="measured_power" :timestamps="timestamps" :f_total="f_total" :useSafety="withSafety" :reset_counter="reset_counter" :UTC_OFFSET="UTC_offset"></pc-timeseries>
            </template>

            <v-spacer></v-spacer>
            <FigureToolbar :disabled="isLoading || isInitial" v-on:export="exportResults" v-on:home="reset_counter += 1" v-model="tool" ></FigureToolbar>
          </v-row>
        </v-card>

        <!-- TABLE    -->
        <v-card class="px-0 py-0 my-4" elevation="1" outlined color="veryverylight" v-if="!isInitial && !isLoading">

          <v-toolbar dense color="transparent" flat >
            <v-subheader> Saftey Factor: {{f_total}} % </v-subheader>
            <v-spacer></v-spacer>
            <v-switch color="ternary" dense hide-details label="with safety factor" v-model="withSafety"></v-switch>
          </v-toolbar>

          <v-card elevation="2" >
          <v-simple-table class="white" width="100%" dense>
            <thead>
              <tr>
                <th class="secondary white--text"> Selected </th>
                <th class="secondary white--text" style="text-align: left"> Name </th>
                <th class="secondary white--text" style="text-align: left"> Type </th>
                <th class="secondary white--text" style="text-align: left"> Gross Area </th>
                <th class="secondary white--text" style="text-align: center"> &Oslash; Measured <InfoTooltip small color="white"> Average measured Power </InfoTooltip></th>
                <th class="secondary white--text" style="text-align: center; line-height: 12px; padding:5px; min-width: 100px;"> &Oslash; Estimated <InfoTooltip  small color="white"> Average Guaranteed Power ({{ isWithSafetyString }} Safety Factors) </InfoTooltip> <br> <span style="font-size: smaller; padding-right: 12px">({{ isWithSafetyString }} safety) </span> </th>
                <th class="secondary white--text" style="text-align: center; line-height: 12px; padding:5px; min-width: 100px;"> Ratio  <InfoTooltip small color="white"> Comparing Measured vs Guaranteed power </InfoTooltip> <br><span style="font-size: smaller; padding-right: 12px">({{ isWithSafetyString }} safety) </span> </th>
                <th class="secondary white--text" style="text-align: center"> Valid Intervals <InfoTooltip small color="white"> Number of valid 1-hour intervals found within the data </InfoTooltip> </th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="isLoading || isInitial">
                <td colspan="7" rowspan="6" style="text-align: center" class="gray--text"> Please click on run to execute the Performance Check and show the results </td>
              </tr>
              <tr v-else v-for="result in results" :key="result.id" :class="{'selected': result.is_selected}" @click="selectRow(result)">
                <td style="background-color: transparent"> <v-icon v-if="result.is_selected"> mdi-arrow-right </v-icon></td>
                <td style="text-align: left"> {{ result.name }} </td>
                <td style="text-align: left"> {{ result.type }} </td>
                <td style="text-align: left"> {{ result.area }} [m²] </td>
                <td style="text-align: right" :class="{'gray--text': !result.tp_sp_measured}"> {{ result.tp_sp_measured ? getFormattedAvg(result.tp_sp_measured) : 'No Data' }}</td>
                <td style="text-align: right"> {{ withSafety ? getFormattedAvg(result.tp_sp_estimated_safety) : getFormattedAvg(result.tp_sp_estimated) }}</td>
                <td style="text-align: right" :class="{'gray--text': !result.tp_sp_measured}"> {{ result.tp_sp_measured ? getRatio(result, withSafety) + "%" : "No Data" }}</td>
                <td style="text-align: right"> {{ getNrValid(result) }} </td>
              </tr>
            </tbody>
          </v-simple-table>
          </v-card>
        </v-card>
      </v-card>

      <!--  SETTINGS   -->
      <v-dialog v-model="isSettingsOpen" width="750" eager>
        <PC_Method_Settings
            :plant_id="plant_id"
            v-on:update="res => saved_settings = res"
            v-on:error="res => error_message=res"
            v-on:cancel="isSettingsOpen=false"
            v-on:saved="reset_all"
        ></PC_Method_Settings>
      </v-dialog>

    </v-container>
    </template>
  </v-container>
</template>

<script>


import * as backend from "@/plugins/backend_api";
import InfoTooltip from "@/components/_InfoTooltip";
import TopLineAlert from "@/components/TopLineAlert";
import PcTimeseries from "@/components/PerformanceCheck_PlotTimeseries.vue";
import PcScatter from "@/components/PerformanceCheck_PlotScatter.vue";
import LoadingScreen from "@/components/LoadingScreen.vue";
import FigureToolbar from "@/components/FigureToolbar.vue";
import PC_Method_Settings from "@/components/PerformanceCheck_Settings.vue";
import TopLineAlertNoData from "@/components/TopLineAlert_NoData.vue";
import {apiDateToLocalDate, localDateToAPIString} from "@/pages/helper";


export default {
  name: "Plant_PCmethod",
  components: {
    TopLineAlertNoData,
    PC_Method_Settings,
    FigureToolbar,
    LoadingScreen,
    PcScatter,
    PcTimeseries,
    TopLineAlert,
    InfoTooltip,
  },
  props: {
    plant_id: {},
  },
  data: () => ({
    error_message: "",
    isInitial: true,
    isLoading: false,
    isSettingsOpen: false,
    isGeneratingReport: false,

    // save and fetch pc-method defaults
    saved_settings: {},
    result_settings: {},
    start: null,
    end: null,

    // selected from user
    selected_row: -1,
    withSafety: true,

    // from backend
    range: [],
    results: [],
    plant_name: "",
    UTC_offset: "",
    UTC_offset_hours: 0,

    // selected
    estimated_power: [],
    estimated_power_safety: [],
    measured_power: [],
    timestamps: [],
    selected_points: [],

    reset_counter: 0,
    tool: "zoom",
    isNoData: false,
  }),
  mounted() {
    this.getUTCOffset()
    this.getRange()
    this.reset_all()
  },
  computed: {
    isWithSafetyString() {
      return this.withSafety ? "with" : "no"
    },
  },
  methods: {
    // API calls
    getUTCOffset(){
      return backend.get_plant_utc(this.plant_id).then(res => {
        this.UTC_offset_hours = res/60
        this.UTC_offset = "UTC+"+String(res/60).padStart(2, '0')
        this.reset_start_end()
      })
    },
    getRange(){
      return backend.get_start_end(this.plant_id).then(res => {
        this.range = [res.data.start, res.data.end]
        this.isNoData = (this.range[0] === null) && (this.range[1] === null)
        this.reset_start_end()
      }).catch(res => {
        this.error_message = res
      })
    },
    reset_all(){
      this.isSettingsOpen=false
      this.isInitial = true

      // reset data
      this.estimated_power = null
      this.estimated_power_safety = null
      this.measured_power = null
      this.timestamps = []
      this.results = []
    },
    reset_start_end(){
      let start = apiDateToLocalDate(this.range[0], this.UTC_offset_hours)
      let end = apiDateToLocalDate(this.range[1], this.UTC_offset_hours)
      end.setDate(end.getDate() + 1)    // because of rounding when getting the substring
      this.start = start.toISOString().substring(0, 10)
      this.end = end.toISOString().substring(0, 10)
    },
    getSettingValue(key, suffix=""){
      let used = !this.result_settings[key] ? '' : ' (' + this.result_settings[key] + suffix + ')'
      let saved = this.saved_settings[key] === null ? 'AUTO' + used : this.saved_settings[key] + suffix
      return saved
    },
    generatePDF(){
      this.isGeneratingReport = true
      this.error_message = ""
      let start = localDateToAPIString(this.start, this.UTC_offset/60)
      let end = localDateToAPIString(this.end, this.UTC_offset/60)

      return backend.get_report(this.plant_id, this.saved_settings, start, end)
          .catch(res => {
            this.error_message = res
          }).then(res => {
            let file_name ="SunPeek Report - Thermal Power Check.pdf"
            try {
              let disposition = res.headers["content-disposition"]
              file_name = decodeURI(disposition.split("filename*=utf-8''")[1])
            } catch {
              console.log("Could not read filename. Using Default instead")
            }

            let a = document.createElement('a');
            a.href = window.URL.createObjectURL(res.data);
            a.download = file_name;
            document.body.appendChild(a);
            a.click();
            a.remove();
          }).finally(() => {
            this.isGeneratingReport = false
          })
    },
    runPcMethod(){
      this.error_message = ""
      this.isInitial = false
      this.isLoading = true
      let start = localDateToAPIString(this.start, this.UTC_offset_hours)
      let end = localDateToAPIString(this.end, this.UTC_offset_hours)

      return backend.run_pc(this.plant_id, this.saved_settings, start, end).catch(res => {
        this.results = []
        this.error_message = res
        this.selectRow(this.results)
      }).then(res => {
        this.parsePCResults(res)
        this.selectRow(this.results.at(-1))
      }).finally(() =>{
        this.isLoading = false
      })
    },
    parsePCResults(res){
      if (res === undefined || res === null) return

      // first update the user input, this should always match.
      this.plant_name = res.data.plant.name


      let settings = res.data.settings
      settings["evaluation_mode"] = !res.data.evaluation_mode ? null :  res.data.evaluation_mode.toUpperCase()
      settings["formula"] = res.data.formula
      settings["wind_used"] = res.data.wind_used
      settings.safety_uncertainty *= 100
      settings.safety_others *= 100
      settings.safety_pipes *= 100
      settings.safety_combined *=  100

      this.result_settings = settings
      this.f_total = settings.safety_combined

      // parse Timestamps (simply using start of interval)
      let plant_result = res.data.plant_output
      let arrays_result = res.data.array_output

      // todo: please have timestamps also for the arrays! or not in plant_output
      let timestamps = res.data.plant_output.datetime_intervals_start

      // parse Plant
      let parsed_plant = {}
      parsed_plant["name"] = "Plant Total"
      parsed_plant["is_selected"] = true
      parsed_plant["timestamps"] = plant_result.datetime_intervals_start
      parsed_plant["tp_sp_estimated"] = plant_result.tp_sp_estimated
      parsed_plant["tp_sp_estimated_safety"] = plant_result.tp_sp_estimated_safety
      parsed_plant["tp_sp_measured"] = plant_result.tp_sp_measured
      parsed_plant["type"] = "Total"

      // Parse Arrays
      let parsed_arrays = []
      arrays_result.forEach(item => {
        let array = {}
        array["name"] = item["array"]["name"]
        array["is_selected"] = false
        array["timestamps"] = timestamps
        array["tp_sp_estimated"] = item.tp_sp_estimated
        array["tp_sp_estimated_safety"] = item.tp_sp_estimated_safety
        array["tp_sp_measured"] = item.tp_sp_measured
        array["type"] = "Array"
        array["area"] = item["array"]["area_gr"]["magnitude"]
        parsed_arrays.push(array)
      })
      parsed_plant["area"] = parsed_arrays.reduce((partialSum, array) => partialSum + array["area"], 0)

      // but them all in an "equal" array
      this.results = [...parsed_arrays, parsed_plant]
    },
    selectRow(result){
      if (result === undefined || result === null || result.length === 0) {
        this.estimated_power = null
        this.estimated_power_safety = null
        this.measured_power = null
        this.timestamps = []
        return
      }

      // select item
      this.results.forEach(item => item["is_selected"] = false)
      result.is_selected = true

      // updates selected measurements
      this.estimated_power_safety = result.tp_sp_estimated_safety
      this.estimated_power = result.tp_sp_estimated
      this.measured_power = result.tp_sp_measured
      this.timestamps = result.timestamps
    },
    exportResults(){
      let export_date = (new Date()).toISOString().split('T')[0]
      let plant_name = this.plant_name
      let file_name = export_date+"."+plant_name+"_safety_factor=" + Math.trunc(this.f_total).toString()+"%__performance_check_results.csv"

      let data = [this.timestamps]
      let col_names = ["timestamp"]

      this.results.forEach(val => {
        // write measured
        col_names.push(val.name + " Measured [W/m2]")
        data.push(!val.tp_sp_measured ? Array(this.timestamps.length) : val.tp_sp_measured.magnitude)

        // write estimated
        col_names.push(val.name + " Estimated[W/m2]")
        data.push(!val.tp_sp_estimated ? Array(this.timestamps.length) : val.tp_sp_estimated.magnitude)

        col_names.push(val.name + " Guaranteed(with safety) [W/m2]")
        data.push(!val.tp_sp_estimated_safety ? Array(this.timestamps.length) : val.tp_sp_estimated_safety.magnitude)
      })

      //pivot data
      data = data[0].map((_, colIndex) => data.map(row => row[colIndex]));

      // export data
      let csvContent = "data:text/csv;charset=utf8,";
      csvContent += [col_names.join(";"), ...data.map(item => item.join(";"))].join("\n")
          .replace(/(^\[)|(\]$)/gm, "");
      const encodedCsvData = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedCsvData);
      link.setAttribute("download", file_name);
      link.click();
    },

    // Helper Methods
    getRatio(result, withSafety=true){
      if (result === undefined || result === null){return 0}
      let estimated = withSafety ? this.calculateAverage(result.tp_sp_estimated_safety) : this.calculateAverage(result.tp_sp_estimated)
      let measured = this.calculateAverage(result.tp_sp_measured)
      let diff =  measured/estimated
      return (diff*100).toFixed(1)
    },
    getNrValid(result){
      if (result === undefined || result === null || result.timestamps === null || result.timestamps === undefined){return 0}
      // limit only to selected values
      let filter_is_empty = this.selected_points.length === 0
      return filter_is_empty ? result.timestamps.length + " [h]" : this.selected_points.length + " [h]"
    },
    calculateAverage(result) {
      if (result === undefined || result === null){return 0}
      //limiting only to selected values
      let values = result.magnitude
      let filter_is_empty = this.selected_points.length === 0
      let filtered_result = filter_is_empty ? values : values.filter((item, i) => this.selected_points.includes(i))
      let n_values = filtered_result.length
      return filtered_result.reduce((partialSum, a) => partialSum + a, 0) / n_values
    },
    getFormattedAvg(result){
      if (result === undefined || result === null){return 0}
      let estimated_value = this.calculateAverage(result);
      let number_as_string = new Intl.NumberFormat('en', { maximumFractionDigits: 0}).format(estimated_value)
      return number_as_string + " [" + result.units + "]"
    }
  },
}
</script>

<style scoped>

.timeseries_plot{
  width: calc(0.6 * (100% - 45px));
}
.scatter_plot{
  width: calc(0.4 * (100% - 45px));
  max-height: 100%;
  aspect-ratio: 1.0 / 1.0;
}

.noResultsSpan{
  color: gray;
  padding: 20px;
  margin: auto;
  text-align: center;
  width: calc(100% - 50px)
}

</style>