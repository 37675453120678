<template>
    <div class="ma-2 pa-1" style="line-height: 1.0; text-align: right; opacity: 0.5">
      <span class="caption white--text"> SunPeek API ({{ sunpeek_version }}) </span><br>
      <span class="caption white--text">  ({{git_hash}})</span>
    </div>
</template>

<script>
import * as backend from "@/plugins/backend_api";

export default {
  name: "SunPeek_VersionInfo",
  data: () => ({
    sunpeek_version: "no connection",
    git_hash: "ERROR",
    error_counter: 0
  }),
  mounted() {
    this.getVersion()
    this.getGitInfo()
  },
  methods: {
    getVersion(){
      backend.getAbout().then(res => {
        this.sunpeek_version = res.data.version
        this.error_counter = 0
      }).catch(() => {
        this.error_counter += 1
        setTimeout(this.getVersion, 1000)
      })
    },
    getGitInfo(){
      this.git_hash = process.env.VUE_APP_GIT_HASH
    },
  },
}
</script>

<style scoped>

</style>