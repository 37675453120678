import {uniq} from "lodash";


// Getter

export function getCompatibleUnits(sensor, sensor_types) {
    // returns common units compatible to the sensor_type as well as the unit currently used for the sensor
    let current_unit = [sensor.formatted_unit] || []
    let sensor_type = getSensortype(sensor, sensor_types)
    let common_units = sensor_type === undefined ? [] : sensor_type.common_units
    common_units = common_units === null ? [] : common_units
    let combined_units = [...current_unit, ...common_units]
    return uniq(combined_units)
}

export function  getSensortype(sensor, sensortypes){
    return sensortypes.find(sensortype => sensortype.name === sensor["sensor_type"])
}


// GENERAL

export function checkIfIncluded(property, search_string) {
    let isIncluded = false;
    if (property) {
        property = property.toString()        //conversion in case we have a integer
        property = property.toLowerCase()
        isIncluded = property.includes(search_string)
    }
    return isIncluded
}


// ----------------------
// CONFIGURATION CHECKS
// ----------------------


export function plantExists(plant_data){
    return !!plant_data
}

export function hasArray(plant_data){
    return plantExists(plant_data) && (plant_data["arrays"].length > 0)
}

export function hasSensors(plant_data){
    return plantExists(plant_data) && hasArray(plant_data) && plant_data["raw_sensors"].filter(sensor => !sensor.is_virtual).length > 0
}


// MAPPING
export function isSensorsMapped(plant_data){
    if (!plantExists(plant_data) || !hasArray(plant_data)){
        return false
    }
    let sensors = plant_data.raw_sensors
    let plant_mapped = isPlantMapped(plant_data, sensors)
    let arrays_mapped = plant_data["arrays"].map(array => isArrayMapped(array, sensors))
    let all_arrays_mapped = arrays_mapped.reduce((a,b) => (a|b))
    return plant_mapped && all_arrays_mapped
}


export const REQUIRED_PLANT_SLOTS = ["te_amb", "tp"]
export const REQUIRED_ARRAY_SLOTS = ["te_in", "te_out", "in_global",]


export function isPlantMapped(plant_data, sensors){
    return __isMappingCompleted(plant_data["sensor_map"], sensors, REQUIRED_PLANT_SLOTS)
}
export function isArrayMapped(array_data, sensors){
    return __isMappingCompleted(array_data["sensor_map"], sensors, REQUIRED_ARRAY_SLOTS)
}

export function __isMappingCompleted(mapping, sensors, required_slots){
    let isMapped = true
    required_slots.forEach(slot => {
        let sensor_name = mapping[slot]
        let isValid = isSensorCalculatable(sensors, sensor_name)
        if (!isValid) {
            isMapped = false
            return isMapped
        }
    })
    return isMapped
}

export function isSensorCalculatable(sensors, sensor_name){
    if (sensors.length === 0) {return false}
    let sensor = sensors.find(val => val.raw_name === sensor_name)
    return sensor !== undefined && (!sensor.is_virtual || sensor.can_calculate)
}


export function isConfigurationCompleted(plant_data){
    let allValid = true
    let sensors = plant_data.raw_sensors
    sensors.forEach(sensor => {
        if (!isSensorConfiguredCorrectly(sensor)) {
            allValid = false
            return allValid
        }
    })
    return allValid
}
export function isSensorConfiguredCorrectly(sensor){
    // Note: If not mapped we don't care if it is correctly configured
    return (!sensor.is_mapped || sensor.is_virtual || sensor.is_infos_set)
}

export function isConfigDone(plant_data){
    let plant_done = plantExists(plant_data)
    let array_done = hasArray(plant_data)
    let sensors_known = hasSensors(plant_data)
    let mapping_done = isSensorsMapped(plant_data)
    let info_set = isConfigurationCompleted(plant_data)
    return plant_done && array_done && sensors_known && mapping_done && info_set
}


export function apiDateToLocalDate(date, offset_hours){
    date = new Date(date)   // conversion to Date if neccessary
    return new Date(date.getTime() + offset_hours * 60 * 60 * 1000)
}

export function localDateToAPIString(local_date, offset_hours){
    // note that it converts that to GMT, but still takes into account the time shift
    if (isNaN(offset_hours)){offset_hours = 0}
    local_date = new Date(local_date)
    return new Date(local_date.getTime() - offset_hours * 60 * 60 * 1000).toISOString()
}