<template>
  <config-container :error_message="error_message" :success_message="success_message" @dragenter="handleDragStart" @drop="drop_data" max_width="1600">
    <h1> Data Upload </h1>
    <p> Please specify the data format and upload the data files. </p>

    <!--    DRAG AND DROP OVERLAY      -->
    <v-overlay
        absolute
        :color="hovering ? 'ternary' : 'primary'"
        opacity="0.9"
        :value="is_file_dragged"
        class="overlay_data_upload"
        :class="{'dragging_inside': hovering, 'dragging_outside': is_file_dragged & !hovering}"
        @dragover="hovering=true"
        @dragleave="hovering=false"
        @drop="hovering=false"
    >
      <v-row justify="center" >
        <v-icon x-large> mdi-download </v-icon>
        <v-card-title v-if="!hovering"> Drop your Files here </v-card-title>
        <v-card-title v-if="hovering"> Release the Files to upload </v-card-title>
      </v-row>
    </v-overlay>


    <!--    UPLOAD SECTION      -->
    <v-row class="my-4 pa-0 mx-15" flat>
      <v-file-input
          class="mb-1"
          label="Choose files or drag them here"
          v-model="csvFileInput"
          show-size
          accept="text/csv"
          outlined
          multiple
          @change="uploadCSVFiles"
          hide-details
          single-line
          @dragleave.stop.prevent="handleDragLeave"
          loader-height="5"
      ></v-file-input>
      <DataUpload_Settings dense v-model="csv_settings" :plant_id="plant_id" @error="res => error_message=res"></DataUpload_Settings>
    </v-row>

    <!--    TABLE - TOOLBAR   -->
    <v-toolbar class="mx-5 mt-7" color="primary" flat style="font-size: 12px" dense>

      <span class="mx-1" > {{ n_files_stored }} valid files </span>
      <span class="mx-1 lighter--text" v-if="n_files_uploading > 0"> uploading files: {{ n_files_uploading }} </span>

      <v-spacer></v-spacer>

      <v-btn text disabled x-small color="white"> sort by:  </v-btn>
      <v-btn-toggle background-color="primary" class="mx-2" multiple>
        <v-menu top offset-y >
          <template v-slot:activator="{ on, attrs }">
            <v-btn x-small color="secondary_light" class="mr-0" width="100px" v-bind="attrs" v-on="on" active-class="no-active"> {{SORT_OPTIONS[sort_key] }}</v-btn>
          </template>
          <v-list dense color="secondary" dark>
            <v-subheader style="text-align: center"> sort by</v-subheader> <v-divider></v-divider>
            <v-list-item v-for="(item, index) in SORT_OPTIONS" :key="index" dense @click="sort_key = index">
              <v-list-item-title> {{ item }} </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn x-small color="secondary_light" class="ml-0" width="10px" @click="isSortAsc = !isSortAsc" active-class="no-active"> {{ isSortAsc ? 'ASC' : 'DSC' }} </v-btn>
      </v-btn-toggle>

      <v-btn-toggle background-color="primary" class="mx-2">
        <v-btn text disabled x-small> {{n_files_invalid}} invalid Uploads   </v-btn>
        <v-btn x-small color="warning" title="delete invalid uploads" @click="removeAllInvalidEntries()" :disabled="n_files_invalid === 0"> <v-icon small color="white"> mdi-trash-can </v-icon> </v-btn>
      </v-btn-toggle>


      <v-btn x-small color="darkwarning" class="mx-2" :disabled="n_files_stored === 0" @click="show_delete_dialog = true"> All Files <v-icon small> mdi-database-remove</v-icon> </v-btn>
      <delete-dialog header="Delete all data?" :show="show_delete_dialog" @delete="deleteAll" @cancel="show_delete_dialog = false">
        If you click on Delete you will delete <v-chip> all measurement data </v-chip> associated with this plant!
        Are you sure you want to continue?
      </delete-dialog>
    </v-toolbar>


    <!--    TABLE   -->
    <v-card outlined color="ternary" class="mx-5 mb-5 mt-0">
        <v-simple-table dense class="secondary" height="calc(100vh - 550px)" fixed-header>
        <thead>
          <tr>
            <th class="ternary" ></th>
            <th class="ternary" @click="changeSort('name')" style="text-align: left"> File Name </th>
            <th class="ternary" @click="changeSort('n_rows')" > Rows <InfoTooltip> Number of rows in the file </InfoTooltip></th>
            <th class="ternary" > Sensors <InfoTooltip> Number of Sensors (Columns) identified </InfoTooltip> </th>
            <th class="ternary" @click="changeSort('start')" v-if="$vuetify.breakpoint.xlOnly"> Data Start <InfoTooltip> First timestamp of data in file </InfoTooltip></th>
            <th class="ternary" @click="changeSort('end')" v-if="$vuetify.breakpoint.xlOnly"> Data End <InfoTooltip> Last timestamp of data in file </InfoTooltip></th>
            <th class="ternary" @click="changeSort('size_bytes')" v-if="$vuetify.breakpoint.xlOnly"> Size <InfoTooltip> Size of uploaded file </InfoTooltip> </th>
            <th class="ternary" @click="changeSort('date_of_upload')" v-if="$vuetify.breakpoint.xlOnly"> Uploaded at <InfoTooltip> Date of Upload </InfoTooltip></th>
            <th class="ternary" > Status <InfoTooltip>
              <b> Done </b>     - Upload of file was successful <br>
              <b> Sending </b>  - Data upload in progress <br>
              <b> Parsing </b>  - Data upload in progress <br>
              <b> Error </b>    - There has been an error during the upload <br>
              <b> Overwritten </b>  - Data has been overwritten with newer file <br>
            </InfoTooltip> </th>
            <th class="ternary" > Actions </th>
          </tr>
        </thead>

        <tbody>

          <!-- NO DATA  -->
          <tr v-if="uploaded_files === null || uploaded_files.length === 0">
            <td colspan="100%" style="text-align: center"> No files uploaded yet. </td>
          </tr>

          <!-- IF DATA  -->
          <tr v-for="(file, i) in sorted_entries" :key="i" :class="{'selected': selected_id === file.id}" @click="selected_id = file.id">
            <td>
              <v-icon v-if="isDone(file)" color="white" small> mdi-check </v-icon>
              <v-icon v-if="isError(file)" color="red" small> mdi-close </v-icon>
              <v-progress-circular v-if="isSending(file) || isParsing(file)" size="16" width="2" color="lighter" indeterminate></v-progress-circular>
            </td>

            <td style="text-align: left"> <v-chip label outlined> {{file.name}} </v-chip> </td>
            <td :class="{'warning--text': file.rows === 0}"> {{ !file.n_rows ? NO_DATA_STR : file.n_rows }} </td>

            <td :class="{'warning--text': file.missing_columns.length > 0}">
              <v-tooltip top v-if="file.missing_columns.length > 0">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on"> {{ sensor_names.length - file.missing_columns.length }} / {{ sensor_names.length }} </span>
                </template>
                <span> The following sensors are missing: {{file.missing_columns}}</span>
              </v-tooltip>
              <span v-else-if="isError(file)"> {{ NO_DATA_STR}} </span>
              <span v-else> {{ sensor_names.length - file.missing_columns.length }} / {{ sensor_names.length }} </span>
            </td>

            <td v-if="$vuetify.breakpoint.xlOnly"> {{ formatDate(file.start) }} </td>
            <td v-if="$vuetify.breakpoint.xlOnly">  {{ formatDate(file.end) }} </td>
            <td v-if="$vuetify.breakpoint.xlOnly"> {{ formatBytes(file.size_bytes) }} </td>
            <td v-if="$vuetify.breakpoint.xlOnly"> {{ formatDate(file.date_of_upload) }} </td>

            <td style="width: 100px">
              <span v-if="isError(file)" class="warning--text"> Error <InfoTooltip color="warning"> {{ file.error_cause }} </InfoTooltip></span>
              <span v-else-if="isWaiting(file)" class="lighter--text"> Waiting </span>
              <span v-else-if="isSending(file)" class="lighter--text"> Sending ({{ file.progress }}%)</span>
              <span v-else-if="isParsing(file)" class="lighter--text"> Parsing </span>
              <span v-else-if="isDone(file)" class="white--text"> Done </span>
            </td>

            <td>
              <v-btn-toggle small dense rounded background-color="transparent">
                <v-btn x-small @click="inspectData(file)" title="show file response" color="secondary" v-if="('file' in file) & (isError(file) || isDone(file))"> <v-icon small color="white"> mdi-file-find </v-icon></v-btn>
                <v-btn x-small color="secondary" title="retry upload" v-if="isError(file) & ('file' in file)" @click="redoUploadFile(file)"> <v-icon small color="white"> mdi-refresh </v-icon> </v-btn>
                <v-btn x-small color="warning" @click="removeEntry(file)" title="discard entry" v-if="isError(file)"> <v-icon small color="white"> mdi-trash-can </v-icon> </v-btn>
                <v-btn x-small color="darkwarning" @click="deleteFileData(file)" title="delete with data" v-else-if="isDone(file)"> <v-icon small color="white"> mdi-database-remove </v-icon></v-btn>
              </v-btn-toggle>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>

    <v-overlay opacity="0.9" v-show="inspect_data">
      <v-card color="primary" style="width: 80vw; min-width: 300px; max-width: 1400px" class="pa-5">
        <v-row class="pa-0 ma-0 mb-4">
          <h3> Inspection: {{!selected_file ? "MISSING FILE" : selected_file.name}} </h3>
          <v-spacer></v-spacer>
        </v-row>

        <v-row class="mx-4 pa-0 my-0">
          <v-subheader> CSV-Settings: </v-subheader>
          <v-spacer></v-spacer>
          <DataUpload_SettingToolbar dense v-model="csv_settings" :plant_id="plant_id"></DataUpload_SettingToolbar>
        </v-row>

        <DataUpload_InspectionTable :plant_id="plant_id" :csv_settings="csv_settings" :csv-file="selected_file" class="fill-height"></DataUpload_InspectionTable>

        <v-row class="ma-0 pa-0">
          <v-btn color="white" outlined @click="clearInspectData"> Back </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="ternary" @click="redoUploadFile(selected_file)"> Upload </v-btn>
        </v-row>
      </v-card>
    </v-overlay>

  </config-container>
</template>

<script>
import * as backend from "@/plugins/backend_api";
import configContainer from "@/components/ConfigContainer";
import * as d3 from "d3"
import InfoTooltip from "@/components/_InfoTooltip.vue";
import DataUpload_Settings from "@/components/DataUpload_Settings.vue";
import DeleteDialog from "@/components/DialogDelete.vue";
import DataUpload_InspectionTable from "@/components/DataUpload_InspectionTable.vue";
import DataUpload_SettingToolbar from "@/components/DataUpload_SettingToolbar.vue";

const MAX_CONCURRENT_SENDS = 2;

const FILE_STATUS = {
  NONE: null,
  WAITING: 'Waiting',
  SENDING: 'sending',
  PARSING: 'parsing ...',
  DONE: 'done',
  ERROR: 'ERROR'
};

export default {
  name: "DataUploadHistory",
  components: {
    DataUpload_SettingToolbar,
    DataUpload_InspectionTable, DeleteDialog, DataUpload_Settings, InfoTooltip, configContainer},
  props: {
    plant_id: {},
  },
  data: () => ({
    csvFileInput: [],
    uploaded_files: [],
    sensor_names: [],
    csv_settings: {},

    error_message: "",
    success_message: "",
    is_file_dragged: false,
    inspect_data: false,
    hovering: false,
    show_delete_dialog: false,

    selected_file: null,
    selected_id: null,
    sort_key: "date_of_upload",
    isSortAsc: false,

    SORT_OPTIONS: {
      name: "File Name",
      date_of_upload: "Uploaded at",
      start: "Data Start",
      end: "Data End",
      size_bytes: "Size",
      n_rows: "#Rows",
    },

    UTC_offset: 0,
    upload_counter: -1,
    NO_DATA_STR: "--",
  }),
  mounted() {
    addEventListener("dragover", this.handleDragStart)
    addEventListener("dragleave", this.handleDragLeave)
    addEventListener("drop", this.handleDragLeave)
    this.getSensors()
    this.getUploadHistory()
    this.getPlantTimezone()
  },
  methods: {
    inspectData(file){
      this.selected_file = file
      this.inspect_data = true
    },
    clearInspectData(){
      this.selected_file = null
      this.inspect_data = false
    },
    getPlantTimezone(){
      backend.get_plant_utc(this.plant_id).then(res => {
        this.UTC_offset = res
      })
    },
    getSensors(){
      backend.get_sensors(this.plant_id).then(res => {
        this.sensor_names = res.data.filter(item => !item["is_virtual"]).map(item => item.raw_name)
      }).catch(res => {
        this.error_message = res
      })
    },
    getUploadHistory(){
      backend.get_data_upload_history(this.plant_id).then(res => {
        this.uploaded_files = res.data
      }).catch(res => {
        this.error_message = res
      })
    },

    uploadCSVFiles(){
      this.uploadFiles(this.csvFileInput)
      this.csvFileInput = []
      document.activeElement.blur();
    },

    uploadFiles(files){
      let parsed_files = this.prepareFilesForUpload(files)
      this._queueUploadFiles(parsed_files)
    },
    prepareFilesForUpload(unparsed_files){
      let parsed_files = []
      unparsed_files.forEach(file => {
        this.upload_counter = (this.upload_counter - 1)
        let preparedFile = {
          name: file.name,
          size_bytes: file.size,
          status: FILE_STATUS.WAITING,
          id: this.upload_counter ,
          progress: 0,
          missing_columns: [],
          error_cause: null,
          n_rows: null,
          start: null,
          end: null,
          date_of_upload: new Date().toISOString(),
          file: file,
        }
        this.uploaded_files.push(preparedFile)
        parsed_files.push(preparedFile)
      })
      return parsed_files
    },
    async _queueUploadFiles(files){
      let running_promises = []
      for (let i=0; i < files.length; i++){
        if (i >= MAX_CONCURRENT_SENDS){
          let blocking_promises = running_promises.slice(i - MAX_CONCURRENT_SENDS, i)
          await Promise.any(blocking_promises);
        }
        let new_file_to_send = files[i]
        running_promises.push(this.uploadSingleFile(new_file_to_send))
      }
    },
    async uploadSingleFile(file){
      let onProgressUpdate = (progressEvent) => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        file.progress = percentCompleted
        file.status = percentCompleted >= 100 ?  FILE_STATUS.PARSING : FILE_STATUS.SENDING
      }
      return backend.upload_data(this.plant_id, file.file, this.csv_settings, onProgressUpdate).then(res => {
        let is_selected_file = this.selected_id ===  file["id"] // is overwritten soon
        let response = res.data
        let response_this_file = response["response_per_file"][0]
        Object.keys(response_this_file).forEach(key => {
          file[key] = response_this_file[key]
        })
        if (is_selected_file){
          this.selected_id = response_this_file["id"]
        }
      }).catch(res => {
        file.status = FILE_STATUS.ERROR
        file.error_cause = backend.getErrorMessage(res)
        this.error_message = "ERROR Parsing files. Please check the 'ERROR' messages of the individual files below"
      })
    },
    redoUploadFile(file_info){
      this.selected_file = null
      this.inspect_data = false
      this.removeEntry(file_info).then(() =>{
        this.uploadFiles([file_info.file])
      })
    },
    removeEntry(file){
      if (file.id >= 0){
        return this.removeEntryFromDB(file)
      }
      this.uploaded_files = this.uploaded_files.filter(entry => entry.id !== file.id)
      return Promise.resolve()
    },
    removeEntryFromDB(file){
      return backend.delete_history_entry(this.plant_id, file.id).then(() => {
        this.uploaded_files = this.uploaded_files.filter(entry => entry.id !== file.id)
      }).catch(res => {
        this.error_message = res
      })
    },
    removeAllInvalidEntries(){
      let invalid_files = this.uploaded_files.filter(file => !this.isDone(file))
      invalid_files.forEach(file => {
        this.removeEntry(file)
      })
    },

    deleteFileData(file){
      backend.delete_data(this.plant_id, file.start, file.end).then(() => {
        this.success_message = "File Deleted successfully"
        this.removeEntryFromDB(file)
      }).catch(res => {
        this.error_message = res
      })
    },

    deleteAll(){
      backend.delete_all_data(this.plant_id).then(() => {
        this.success_message = "All Files Deleted successfully"
        this.uploaded_files.forEach(file => this.removeEntryFromDB(file))
      }).catch(res => {
        this.error_message = res
      }).finally(() => {
        this.show_delete_dialog = false
      })
    },

    changeSort(key){
      if (this.sort_key === key){
        this.isSortAsc = ! this.isSortAsc
      }
      this.sort_key = key
    },


    // methods for Drag & Drop
    handleDragStart(){this.is_file_dragged = true},
    handleDragLeave(){this.is_file_dragged = false},
    drop_data(e){
      this.handleDragLeave()
      this.csvFileInput = e.dataTransfer.files
      this.uploadCSVFiles()
    },


    // Other methods
    next() {
      let name = (this.$router.currentRoute.path.includes("/plant/")) ? "validation" : "plant_success"
      this.$router.push({name: name, params: {"plant_id": this.plant_id}})
    },
    back(){
      let name = (this.$router.currentRoute.path.includes("/plant/")) ? "sensorOverview" : "setup_sensorOverview"
      this.$router.push({name: name, params: {"plant_id": this.plant_id}})
    },

    //Formatting
    formatDate(date){
      if (date === null) {return this.NO_DATA_STR}
      let parsed_date = new Date(date)
      // unfortunately Date() always represents in locale (i.e. browser timezone), so we have to subtract the zone again but need to add the plant local time zone
      let local_offset = parsed_date.getTimezoneOffset()
      let database_offset = this.UTC_offset
      let final_date = new Date(parsed_date.getTime() + (database_offset - local_offset)*60000)
      return final_date.toISOString().substring(0, 10)
    },
    formatBytes(bytes){
      let format = d3.format(".3s")
      let formatted = format(bytes) + "B"
      let formatted_with_space_after_numbers = formatted.replace(/\D/,' $&')
      return formatted_with_space_after_numbers
    },
    isError(file){ return file.error_cause !== null},
    isSending(file){ return file.status === FILE_STATUS.SENDING},
    isParsing(file){ return file.status === FILE_STATUS.PARSING},
    isWaiting(file){ return file.status === FILE_STATUS.WAITING},
    isDone(file){ return !this.isError(file)  &  !this.isSending(file)  & !this.isParsing(file) & !this.isWaiting(file)},
  },
  computed: {
    n_files_stored(){
      return this.uploaded_files.filter(file => this.isDone(file)).length
    },
    n_files_uploading(){
      return this.uploaded_files.filter(file => this.isParsing(file) | this.isSending(file) | this.isWaiting(file)).length
    },
    n_files_invalid(){
      return this.uploaded_files.filter(file => this.isError(file)).length
    },
    sorted_entries(){
      let files = [...this.uploaded_files]
      let isDateType = (this.sort_key === "end") ||  (this.sort_key === "start") || (this.sort_key === "date_of_upload")
      let ascending_factor = this.isSortAsc ? 1 : -1
      if (isDateType){
        files.sort((a, b) => {
          let a_val = new Date( a[this.sort_key] )
          let b_val =new Date( b[this.sort_key] )
          return  (a_val - b_val) * ascending_factor
        })
      } else {
        files.sort((a, b) => {
          let a_val = a[this.sort_key]
          let b_val = b[this.sort_key]
          return  (a_val - b_val) * ascending_factor
        })
      }
      return files
    },
  },
}
</script>

<style scoped>

tr.selected{
  background-color: var(--v-darkgray-base) !important;
}

tr:hover{
  background-color: var(--v-darkgray-base) !important;
}

.dragging_outside {
  margin: 10px;
  border: 1px dashed white;
}
.dragging_inside {
  margin: 10px;
  border: 1px solid white;
}

.v-overlay{
  transition: 0.01s cubic-bezier(0.25, 0.8, 0.5, 1), z-index 1ms;
}



</style>