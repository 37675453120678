<template>
  <Plotly style="height: 100%;" :layout="layout" :data="traces" :display-mode-bar="false" ></Plotly>
</template>


<script>
import { Plotly } from 'vue-plotly'

export default {
  name: "SensorTimeseriesPlot",
  components: {
    Plotly
  },
  emits: ['input:value'],
  props: {
    data: {},
    sensor: {},
    utc_offset: {default: null, type: Number},
  },
  data: () => ({

  }),
  computed: {
    layout() {
      let utc_string = this.utc_offset === null ? "" : "UTC+"+String(this.utc_offset/60).padStart(2, '0')

      let layout = {
          margin: {l: 150, b:60, r: 150, t:50, pad:5},
          xaxis: {
            type: 'date',
            linecolor: 'lightgrey',
            linewidth: 1,
            mirror: true,
            hoverformat: "%Y-%m-%d <br> %H:%M " + utc_string,
          },
          yaxis: {
            title: {text:  this.sensor.sensor_type + " [" + this.sensor.formatted_unit + "]", font: {size: 11}},
            linecolor: 'lightgrey',
            linewidth: 1,
            mirror: true
          },
        showlegend: true,
        legend: {"orientation": "h", x: 0.9, y: 1.1, xanchor: "right",}
      }
      return layout
    },
    traces() {
      let offset = this.utc_offset*60*1000
      let timestamps = Object.keys(this.data).map(val => Number(val) - offset)
      let values = Object.values(this.data)

      let data = [{
        x: timestamps,
        y: values,
        type: "scattergl",
        mode: 'lines+markers',
        name: this.sensor.raw_name,
        marker: {size: 4},
      }]
      return data
    },
  },

  // methods: {
  // },
}
</script>

<style scoped>


</style>