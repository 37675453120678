<template>
  <v-alert v-if="message" v-model="isActive" type="success" prominent :onclose="clear_message" dismissible>
    {{success_message}} </v-alert>
</template>

<script>


export default {
  props: ["success_message"],
  name: "TopLineSuccess",
  data: () => ({
    isActive: true,
    message: "",
  }),
  watch: {
    success_message: function() {
      this.isActive = true
      this.message = this.success_message
    }
  },
  methods: {
    clear_message(){
      this.message = ""
    },
  },
}
</script>

<style scoped>

p {
  margin-bottom: 0;
}
</style>