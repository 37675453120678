<template>
  <v-list-item link router :to="route" :dense="dense">
    <v-list-item-icon class="icon">
      <v-icon :small="small" :class="{'pa-1': small}" :color="small ? 'lightgray' : 'inherit'"> {{ icon }} </v-icon>
    </v-list-item-icon>

    <v-list-item-content link>
      <v-list-item-title> {{ name }} </v-list-item-title>
    </v-list-item-content>

  </v-list-item>
</template>

<script>
export default {
  props:{
    name: {default: ""},
    icon: {default: null},
    route: {default: ""},
    dense: {type: Boolean, default: false},
    small: {type: Boolean, default: false},
  },
  name: "NavigationItem",
  data: () => ({
  }),
}
</script>

<style scoped>

.icon{
  margin-right: 12px !important;
}
</style>