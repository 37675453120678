<template>
  <config-container :error_message="error_message" max_width="800">

      <v-row class="px-3 py-3">
        <h1> Plant </h1>
        <v-spacer></v-spacer>
        <v-btn color="error" small v-if="!is_locked && !isNew" @click="show_delete=true"> <v-icon small dark left > mdi-delete </v-icon> Delete Plant </v-btn>
      </v-row>
      <p> Please enter required information about the solar thermal plant </p>

      <v-form v-model="form_valid" lazy-validation @submit="saveUpdate" ref="form">
        <v-row class="px-4 py-1" v-if="!isNew">
          <v-spacer></v-spacer>
          <v-btn color="ternary" small v-if="is_locked" @click="is_locked = !is_locked"> <v-icon small dark left > mdi-pencil </v-icon> Edit </v-btn>
          <v-btn color="ternary" outlined small v-else @click="is_locked = !is_locked"> <v-icon small dark left > mdi-pencil </v-icon> Cancel Edit </v-btn>
        </v-row>

        <ConfigSection name="Basics">
          <configTextFieldRow
              v-model="plant_name"
              label="Plant Name"
              description="Plant name. Must be unique within SunPeek 'plant' database."
              placeholder="your custom name"
              required
              :rules="[value => /^[A-Za-z0-9 äÄöÖüÜß #_\-\s \u00C0-\u017F]*$/.test(value) || 'Plant name should only contain letters [Aa-Zz], numbers[0-9], whitespace, or specific symbols [#_-]']"
              :disabled="is_locked"
          ></configTextFieldRow>
        </ConfigSection>

        <ConfigSection name="Position">
          <configTextFieldRow
              v-model="latitude"
              label="Latitude"
              description="Geographical latitude. Positive is north of the equator"
              placeholder="47.076668 (example Graz)"
              suffix="°"
              required
              :minimum="-90"
              :maximum="90"
              :disabled="is_locked"
          ></configTextFieldRow>
          <configTextFieldRow
              v-model="longitude"
              label="Longitude"
              description="Geographical longitude. Positive is east of the prime meridian."
              placeholder="15.421371 (example Graz)"
              suffix="°"
              required
              :minimum="-180"
              :maximum="180"
              :disabled="is_locked"
          ></configTextFieldRow>
          <configTextFieldRow
              v-model="elevation"
              label="Elevation"
              description="Location elevation (altitude), e.g. 440 [m]. Used to improve solar position calculation."
              placeholder="353 (example Graz)"
              suffix="m"
              :minimum="0"
              :disabled="is_locked"
          ></configTextFieldRow>
        </ConfigSection>

        <ConfigSection name="Additional Information">
          <configTextFieldRow
              v-model="owner"
              label="Owner"
              description="Name of plant owner."
              :disabled="is_locked"
          ></configTextFieldRow>
          <configTextFieldRow
              v-model="operator"
              label="Operator"
              description="Name of plant operator."
              :disabled="is_locked"
          ></configTextFieldRow>
          <configTextFieldRow
              v-model="description"
              label="Description"
              description="Description of the plant, its components, hydraulic setup and other relevant information."
              :disabled="is_locked"
          ></configTextFieldRow>
        </ConfigSection>

        <br>

        <v-row>
          <template v-if="is_locked">
            <v-btn color="white" light outlined @click="$emit('back')"> Back </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="is_locked" color="white" outlined light @click="next()"> Next </v-btn>
          </template>
          <template v-else>
            <v-btn color="white" text @click="plant_id === undefined ? $router.push('/home') : is_locked = !is_locked"> Cancel </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="!is_locked" color="white" light :class="{'disable-events': !form_valid}" @click="saveUpdate"> Save </v-btn>
          </template>
        </v-row>
      </v-form>

    <delete-dialog
        :show="show_delete"
        header="Deleting Plant"
        @delete="delete_plant"
        @cancel="show_delete=false"
    >
      Are you sure you want to delete the Plant <v-chip label dense> {{plant_name}} </v-chip>? <br>
      All information and data about the plant will be deleted!
    </delete-dialog>

  </config-container>
</template>

<script>
 
  import * as backend from "@/plugins/backend_api";
  import ConfigSection from "@/components/ConfigSection";
  import configTextFieldRow from "@/components/Config_TextFieldRow.vue";
  import configContainer from "@/components/ConfigContainer";
  import DeleteDialog from "@/components/DialogDelete.vue";

  export default {
    name: 'ConfigPlant',
    components: {DeleteDialog, configContainer, configTextFieldRow, ConfigSection},
    props: {
      plant_id: null,
    },
    data: () => ({
      //input values
      plant_name: null,
      latitude: null,
      longitude: null,
      elevation: null,
      description: null,
      operator: null,
      owner: null,

      // variable for setting plant
      new_plant: {},

      //helper
      show_delete: false,
      form_valid: false,
      error_message: "",
      is_locked: false,
    }),
    computed: {
      isNew(){
        return this.plant_id === undefined
      },
    },
    watch: {
      plant_id(){
        this.populate_new_plant()
      },
      isLocked(){
        if (this.is_locked) {
          this.populate_new_plant()
        }
      },
    },
    mounted() {
      this.populate_new_plant()
    },
    methods: {
      // API Methods (get)
      async get_plant(){
        return await backend.get_plant(this.plant_id).then(res => {
          this.new_plant = res.data
        }).catch(res => {
          this.error_message = res
        })
      },

      // OTHER METHODS
      populate_new_plant(){
        this.is_locked = !this.isNew;
        if (this.plant_id !== undefined) {
          this.get_plant().then(() => {
            this.plant_name = this.new_plant["name"]
            this.operator = this.new_plant["operator"]
            this.owner = this.new_plant["owner"]
            this.description =  this.new_plant["description"]
            this.longitude = !this.new_plant["longitude"] ? null: this.new_plant["longitude"]["magnitude"]
            this.latitude = !this.new_plant["latitude"] ? null: this.new_plant["latitude"]["magnitude"]
            this.elevation = !this.new_plant["elevation"] ? null : this.new_plant["elevation"]["magnitude"]
          })
        }
      },
      saveUpdate(){
        this.$refs.form.validate()
        if (this.form_valid){

          // connect the variables and info
          this.new_plant = {} // resetting so sensors and other not-changed infos are not always passed back and forth
          this.new_plant["id"] = this.plant_id
          this.new_plant["name"] = this.plant_name
          this.new_plant["longitude"] = this.longitude
          this.new_plant["latitude"] = this.latitude
          this.new_plant["elevation"] = !this.elevation ? null : this.elevation
          this.new_plant["operator"] = this.operator
          this.new_plant["owner"] = this.owner
          this.new_plant["description"] = this.description

          // attach units to respective fields
          const field_units = {
            "latitude": "°",
            "longitude": "°",
            "elevation": "m",
          }
          Object.keys(field_units).forEach(name => {
            let value = this.new_plant[name]
            if (value !== null) {
              this.new_plant[name] = {"magnitude": value, "units": field_units[name],}
            }
          })

          if (this.isNew){
            this.add_plant(this.new_plant)
          } else {
            this.update_plant(this.new_plant)
          }
        }
      },

      next(id){
        id = !id ? this.plant_id : id
        let name = (this.$router.currentRoute.path.includes("/plant/")) ? "arrayOverview" : "setup_arrayOverview"
        this.$router.push({name: name, params: {"plant_id": id}})
      },


      // API Methods (create/update)
      async add_plant(plant_data){
        return await backend.create_plant(plant_data).then(res => {
          const response = res.data
          const id = response["id"]
          this.next(id)
        }).catch(res => {
          this.error_message = res
        })
      },
      async update_plant(plant_data){
        return await backend.update_plant(plant_data).then(() => {
          this.populate_new_plant()
        }).catch(res => {
          this.error_message = res
        })
      },
      delete_plant(){
        return backend.delete_plant(this.plant_id).then(() => {
          this.$router.push({path: "/home"})
        }).catch(res => {
          this.error_message = res
        })
      }
    },
  }
</script>

<style scoped>

.disable-events {
  pointer-events: none;
  background-color: gray;
  opacity: 0.5;
}

</style>