<template>
  <v-row>
    <v-subheader class="label">
      {{ label }}
      <sup v-if="required"> (*) </sup>
    </v-subheader>
    <v-autocomplete
        outlined
        dense
        hide-details
        filled
        :background-color="color"
        :chips="chips"
        :clearable="clearable"
        :disabled="disabled"
        :value="value"
        :items="items"
        @input="emitValue"
        :placeholder="placeholder"
        :rules="rule_array"
    ></v-autocomplete>
    <InfoTooltip v-if="description || error_message" :error_message="error_message"> {{description}} </InfoTooltip>
  </v-row>
</template>

<script>
import InfoTooltip from "@/components/_InfoTooltip";

export const INPUT_RULES = {
  required: value => !!value ||  'Required.',
  number: value => !isNaN(value) || 'Please insert a number (use . for decimals).',
  minimum: (value, minimum) => value >= minimum || 'Value is below minimum of ' + minimum.toString() + ". ",
  maximum: (value, maximum) => value <= maximum || 'Value is above maximum of ' + maximum.toString() + ". ",
}

export default {
  name: "configAutocomplete",
  components: {InfoTooltip},
  emits: ['input:value'],
  props: {
    items: {default: []},
    value: {default: ""},
    label: {default:""},
    suffix: {default:""},
    description: {default: ""},
    placeholder: {default: ""},
    type: {default: "text"},
    maximum: {},
    minimum: {},
    required: Boolean,
    disabled: Boolean,
    clearable: Boolean,
    chips: Boolean,
    color: {default: "inherit"},
  },
  data: () => ({

  }),
  methods: {
    emitValue(newValue){
      this.$emit('input', newValue);
    },
  },
  computed: {
    rule_array(){
      return [
        this.required ? INPUT_RULES.required(this.value) : true,
        this.maximum || this.minimum ? INPUT_RULES.number(this.value) : true,
        this.maximum ? INPUT_RULES.maximum(this.value, this.maximum) : true,
        this.minimum ? INPUT_RULES.minimum(this.value, this.minimum) : true,
      ]
    },
    error_message() {
      let error_messages = this.rule_array.filter(val => val !== true)
      return error_messages[0] || ""
    },
  }
}


</script>

<style scoped>

.label{
  width: 150px;
}

</style>