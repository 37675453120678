<template>
  <v-dialog :value="value" :width="width" @click:outside="outside" persistent>
    <v-card>
      <v-card-title :class="[color, color_header+'--text']" class="text-h5 lighten-2">
        {{ header }}
      </v-card-title>
      <br>
      <v-card-text>
        <slot></slot>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn :color="color_cancel" text @click="cancel"> {{ btn_cancel }} </v-btn>
        <v-spacer></v-spacer>
        <v-btn :color="color" text @click="confirm"> {{ btn_confirm }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
export default {
  name: "SunPeekDialog",
  props: {
    value: {type: Boolean},
    header: {default: ""},
    btn_confirm: {default: "Confirm"},
    btn_cancel: {default: "Cancel"},
    color: {default: "primary"},
    color_cancel: {default: "gray"},
    color_header: {default: "black"},
    persistent: {default: false, type:Boolean},
    width: {default: 500},
  },
  methods: {
    confirm(){
      this.$emit('confirm')
    },
    outside(){
      this.$emit('click_outside')
      if (!this.persistent){
        this.$emit('input', false)
      }
    },
    cancel(){
      this.$emit('cancel')
      this.$emit("input", false)
    }
  }
}
</script>

<style scoped>

</style>