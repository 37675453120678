import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify, {
    options: {
        customProperties: true
    }
})

export default new Vuetify({
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                darker: "#1d2533",
                primary: "#212b3c",
                primary_shade: "#323C4B",
                secondary: "#2d3b50",
                secondary_shade: "#323C4B",
                secondary_light: "#46566f",
                ternary: "#6283b2",
                lighter: "#9cbae3",
                lightblue2: "#ced3e1",
                lightblue: "#f5f7fc",
                lightgreen: "#ebfae1",
                accent: "#fad364",
                lightaccent: "#ffe5a8",
                darkgray: "#5d6471",
                gray: "#8c8c8c",
                lightgray: "#bdbdbd",
                light: "#cccccc",
                light_shade: "#dfdfdf",
                verylight: "#ededed",
                veryverylight: "#f5f5f5",
                almost_white: "#fcfcfc",
                darkwarning: "#933a3a",
                warning: "#cc4e4e",
                lightwarning: "#d7a2a2",
                verylightwarning: "#fcf0f0",
            },
        },
    },
});

