<template>
  <v-container class="landing_page_container">
    <v-row style="height: 100%;" align="center">

      <v-col v-if="!isMobile" align-self="center">
        <v-icon size="350" color="light">mdi-rocket-launch</v-icon>
      </v-col>

      <v-col>
        <v-card elevation="10" color="primary" dark height="400" class="d-flex flex-column">
          <v-img
              style="margin: 0 auto"
              alt="SunPeek Logo"
              :src="require('../assets/logo_nolines.svg')"
              contain
              max-width="300"
              max-height="50"
          />

          <v-spacer></v-spacer>

          <p class="text-h6"> Nice work! </p>
          <p class="text-body-1">
            You have successfully launched SunPeek.
            Add a new plant to check its performance and monitor guarantees.
          </p>

          <v-spacer></v-spacer>
          <v-row >
            <v-btn width="100%" color="white" light to="/setup"> Add new Plant </v-btn>
          </v-row>
          <v-spacer></v-spacer>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn color="ternary" text light to="/load_demo">  or Try the Demo </v-btn>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    name: 'LandingPage',
    data: () => ({
      loadDemo: false,
    }),
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.smAndDown;
      }
    },
    methods: {
      setup_demo_plant(){
        this.loadDemo = true
      },
    },
  }
</script>

<style scoped>

.landing_page_container{
  height: 100%;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.landing_page_container .v-card{
  padding: 30px;
  max-width: 400px;
  margin: 0 auto;
}

.landing_page_container .v-btn{
  height: unset !important;

}

</style>