<template>
   <config-container :error_message="error_message">
     <h1> Sensors </h1>
     <p> Configure the sensor details so SunPeek can interpret the data. </p>

     <v-row color="primary">
       <v-spacer></v-spacer>
       <v-btn small color="ternary" class="py-4 px-3 mx-2" @click="goToNewSensors"> Change Sensors </v-btn>
     </v-row>

     <v-container class="mt-7">
       <v-toolbar color="primary" flat dense>
         <v-switch v-model="filter_mapped" label="Mapped Sensors" title="Only show mapped sensors" hide-details class="my-auto mx-5" small color="ternary"></v-switch>
         <v-spacer></v-spacer>

         <v-btn-toggle background-color="primary" class="ml-2">
           <v-btn text disabled x-small> {{ n_not_configured_sensors }} not configured sensors</v-btn>
           <v-btn x-small color="primary" title="delete invalid entries" @click="deleteUnconfiguredSensors" :disabled="n_not_configured_sensors === 0"> <v-icon small color="white"> mdi-trash-can </v-icon> </v-btn>
         </v-btn-toggle>
         <InfoTooltip small :disabled="false" color="gray">
           Not configured sensors are not used by SunPeek.
           Nevertheless, the data of the not configured sensors is stored in the database (for potential later use).
           Deleting the not configured sensors will hence speed up the DataUpload.
           However, this also means that sensors and corresponding data is deleted (and must be resubmitted again if the sensor is needed at a later point in time).
           </InfoTooltip>
       </v-toolbar>

       <v-card outlined color="secondary" class="mb-5">
         <v-simple-table dense class="primary sensor_table" height="calc(100vh - 450px)" fixed-header>
           <thead >
             <tr >
               <th class="secondary px-2" style="min-width: 70px"> Status <InfoTooltip small>
                 Shows whether the sensor is configured correctly
                 <br> <v-icon x-small color="#00F700"> mdi-check </v-icon> Sensor fully configured
                 <br> <v-icon x-small color="red"> mdi-close </v-icon> Missing information
                 <br> <v-icon x-small color="gray"> mdi-power-off </v-icon> Sensor not configured
               </InfoTooltip> </th>
               <th class="secondary " style="min-width: 120px"> Sensor Name <InfoTooltip small> Name of the sensors as it appears in the input data </InfoTooltip></th>
               <th class="secondary " style="min-width: 200px" > Sensor Type <InfoTooltip small> Type of the sensors. (If the sensor is mapped, the sensor type is automatically assigned and cannot be changed) </InfoTooltip></th>
               <th class="secondary " style="min-width: 130px; text-align: center"> Unit <InfoTooltip small> Measurement unit of the sensors as it appears in the input data </InfoTooltip> </th>
               <th class="secondary" style="min-width: 120px; text-align: center"> Parameters <InfoTooltip small> additional parameters needed to interpret the sensor correctly </InfoTooltip> </th>
               <th class="secondary" style="min-width: 110px; text-align: center"> is Mapped <InfoTooltip small> Shows whether the sensor is mapped to any component in the Sensor Mapping </InfoTooltip> </th>
               <th class="secondary" style="min-width: 90px; text-align: center"> Actions </th>
              </tr>
           </thead>
           <tbody>
            <template v-for="sensor in this.sensors">
             <tr :key="sensor.id" v-if="!filter_mapped || sensor.is_mapped " @click="selectSensor(sensor)" v-bind:class="{isSelected: sensor.id === selected_sensor.id}">


               <!-- Status -->
               <td class="px-2">
                 <v-progress-circular v-if="loading_sensors.includes(sensor.id)" color="ternary" indeterminate> </v-progress-circular>
                 <v-icon v-else-if="isValid(sensor)" color="#00F700"> mdi-check </v-icon>
                 <v-icon v-else-if="sensor.is_mapped" color="red"> mdi-close </v-icon>
                 <v-icon v-else color="gray" small> mdi-power-off </v-icon>
               </td>

               <!-- SENSOR Name -->
               <td style="text-align: left">
                 {{sensor.raw_name}}
<!--                 <v-chip color="ternary" @click="editSensor(sensor)"> {{sensor.raw_name}} </v-chip>-->
               </td>

              <!-- SENSOR TYPE -->
               <td style="text-align: left">
                 <v-autocomplete
                     v-if="!sensor.is_mapped"
                     dark dense flat outlined hide-details background-color="primary"
                     v-model="sensor['sensor_type']"
                     :items="sensortypes"
                     item-value="name"
                     item-text="description"
                     placeholder="missing"
                     @focus="selectSensor(sensor)"
                     @input="updateSensor(sensor)"
                     :disabled="sensor.is_mapped"
                 ></v-autocomplete>
                 <span v-else> {{ sensortypes.find(val => val.name === sensor['sensor_type'])["description"] }}  </span>
               </td>

               <!-- SENSOR UNIT -->
               <td>
                 <span v-if="!isValidSensortype(sensor)" class="darkgray--text"> <v-icon color="darkgray" small>mdi-arrow-left</v-icon> missing </span>
                 <v-autocomplete
                     v-else
                     dark dense flat outlined hide-details background-color="primary"
                     v-model="sensor['formatted_unit']"
                     :items="getCompatibleUnits(sensor)"
                     placeholder="missing"
                     style="max-width: 130px;"
                     @focus="selectSensor(sensor)"
                     @input="updateSensor(sensor)"
                 ></v-autocomplete>
               </td>

               <!-- Parameters -->
               <td>
                 <v-btn
                     v-if="isMetadataRequired(sensor)"
                     small text
                     :color="isValidMetadata(sensor) ? 'white' : 'red'"
                     @click="editSensor(sensor)"
                 >
                   {{ isValidMetadata(sensor) ? 'edit' : 'missing' }}
                   <v-icon small> mdi-lead-pencil </v-icon>
                 </v-btn>
                 <span v-else class="darkgray--text" style="cursor: default"> not required </span>
               </td>

               <!-- Is Mapped -->
               <td>
                 <span v-if="sensor['is_mapped']"> yes </span>
                 <span v-else class="darkgray--text"> no</span>
               </td>



                <!-- ACTIONS -->
               <td>
                 <v-btn-toggle rounded>
                   <v-btn x-small @click="editSensor(sensor)" color="secondary" title="edit sensor"> <v-icon small> mdi-pencil </v-icon></v-btn>
                   <v-btn x-small @click="requestDelete(sensor)" color="secondary" title="delete sensor"> <v-icon small color="warning" > mdi-delete </v-icon></v-btn>
                 </v-btn-toggle>
               </td>


             </tr>
            </template>
           </tbody>
         </v-simple-table>
       </v-card>


     </v-container>

     <SensorDeleteDialog
         v-model="sensorsToDelete"
         v-on:delete="getSensors"
         v-on:error="(res) => error_message=res"
     ></SensorDeleteDialog>

     <br>

     <v-row>
       <v-btn color="white" light outlined @click="back()"> Back </v-btn>
       <v-spacer></v-spacer>
       <v-btn color="white" outlined dark @click="next()" :disabled="n_missing_sensors > 0"> Next </v-btn>
     </v-row>

  </config-container>
</template>

<script>
  import * as backend from "@/plugins/backend_api";
  import configContainer from "@/components/ConfigContainer";
  import {cloneDeep} from "lodash";
  import {getCompatibleUnits, getSensortype} from "@/pages/helper";
  import SensorDeleteDialog from "@/components/SensorDeleteDialog.vue";
  import InfoTooltip from "@/components/_InfoTooltip.vue";


  export default {
    name: 'SensorConfiguration',
    components: {
      InfoTooltip,
      SensorDeleteDialog,
      configContainer,
    },
    props: {
      plant_id: null,
    },
    data: () => ({
      selected_sensor: {},
      filterActive: [],
      selected_sensorType: {},

      // data from backend
      sensors: [],
      sensortypes: [],

      units: [],

      // helper
      error_message: "",
      sensorsToDelete: null,
      loading_sensors: [],
      filter_mapped: true,
    }),
    
    mounted() {
      this.getSensortypes()
      this.getSensors()
    },
    methods: {
      // -----------------
      // GETTER
      // -----------------
      getSensortypes(){
        return backend.get_sensortypes(this.sensor_id).then(response => {
          this.sensortypes = response["data"]
        }).catch(res => {
          this.error_message = res
        })
      },
      getSensors(){
        // gets (non-virtual) sensors from backend.
        return backend.get_sensors(this.plant_id).then(response => {
          let sensors = response["data"]
          sensors = sensors.filter(sensor => !sensor["is_virtual"])
          sensors.sort((a, b) => a["raw_name"].localeCompare(b["raw_name"]))
          this.sensors = sensors
        }).catch(res => {
          this.error_message = res
        })
      },

      // -----------------
      // Helper-Methods
      // -----------------
      getCompatibleUnits(sensor){
        return getCompatibleUnits(sensor, this.sensortypes)
      },
      getRequiredMeta(sensor){
        let sensor_type = getSensortype(sensor, this.sensortypes)
        return (sensor_type === undefined) ? {} : sensor_type.info_checks
      },

      // Other helper
      isValidSensortype(sensor){
        return sensor.sensor_type !== null
      },
      isMetadataRequired(sensor){
        let required_meta = this.getRequiredMeta(sensor)
        return (Object.values(required_meta).length > 0)
      },
      isValidMetadata(sensor){
        return sensor.is_infos_set
      },
      isValid(sensor){
        return this.isValidSensortype(sensor) && this.isValidMetadata(sensor)
      },


      // ----------
      // Actions
      // ----------
      selectSensor(sensor){
        this.selected_sensor = sensor;
      },
      editSensor(sensor){
        let name = (this.$router.currentRoute.path.includes("/plant/")) ? "editSensor" : "setup_editSensor"
        this.$router.push( {name: name, params: {plant_id: this.plant_id, sensor_id: sensor.id, destroy: true}})
      },
      requestDelete(sensor){
        this.selectSensor(sensor)
        this.sensorsToDelete = sensor
      },
      deleteUnconfiguredSensors(){
        let sensors_to_delete = [...this.not_configured_sensors]
        this.sensorsToDelete = sensors_to_delete
      },

      goToNewSensors(){
        let name = (this.$router.currentRoute.path.includes("/plant/")) ? "newSensors" : "setup_newSensors"
        this.$router.push({name: name, params: {"plant_id": this.plant_id}})
      },
      next() {
        let name = (this.$router.currentRoute.path.includes("/plant/")) ? "plant_start" : "plant_success"
        this.$router.push({name: name, params: {"plant_id": this.plant_id}})
      },
      back(){
        let name = (this.$router.currentRoute.path.includes("/plant/")) ? "sensorMapping" : "setup_sensorMapping"
        this.$router.push({name: name, params: {"plant_id": this.plant_id}})
      },


      // ----------
      // API Updates
      // ----------
      updateSensor(sensor){
        this.error_message = ""
        this.loading_sensors.push(sensor.id)

        // removing adding info => as this page is not supposed to update info
        // the nice thing is that the backend leaves everything that is not in the query as it is (only deletes if set to null)
        let updated_sensor = cloneDeep(sensor)
        delete updated_sensor.info;

        return backend.update_sensor(this.plant_id, updated_sensor).catch(res => {
          this.error_message = res
        }).finally(() => {
          this.loading_sensors.pop(sensor.id)
          return this.getSensors()
        })
      },
    },
    computed:{
      n_missing_sensors(){
        return this.sensors.filter(sensor => sensor["is_mapped"] && !sensor.is_infos_set).length
      },
      not_configured_sensors(){
        return this.sensors.filter(sensor => !sensor["is_mapped"] && !this.isValid(sensor))
      },
      n_not_configured_sensors() {
        return this.not_configured_sensors.length
      },
    }
  }
</script>


<style scoped>

.sensor_table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}
.sensor_table td {
  padding: 3px 10px;
}

/*noinspection ALL*/
.sensor_table tr:hover{
  background-color: var(--v-secondary-base) !important;
}
.sensor_table tr.isSelected{
  background-color: var(--v-secondary-base) !important;
}


</style>



