<template>
  <config-container :error_message="error_message">

    <h1> Collector Arrays </h1>
    <p> Configure which collector arrays are installed at the solar thermal plant</p>

    <!--  CONFIGURING ARRAY  -->
    <v-container>
      <v-row class="ma-2">
        <v-spacer></v-spacer>
        <v-btn color="ternary" to="arrays/new"> Add Array </v-btn>
      </v-row>

      <v-card class="ma-5" elevation="0" outlined color="secondary">
        <v-simple-table height="350" dark fixed-header class="secondary" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="ternary"> Name </th>
                <th class="ternary"> Area  </th>
                <th class="ternary"> Tilt </th>
                <th class="ternary"> Azimuth </th>
                <th class="ternary"> Collector </th>
                <th class="ternary"> Edit </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="array in arrays" :key="array.id">
                <td @click="modifyArray(array)"> {{array["name"]}} </td>
                <td @click="modifyArray(array)"> <span v-if="!!array.area_gr"> {{array["area_gr"]["magnitude"]}} m² </span></td>
                <td @click="modifyArray(array)"> {{array["tilt"]["magnitude"] }}° </td>
                <td @click="modifyArray(array)"> {{array["azim"]["magnitude"] }}° </td>
                <td @click="modifyArray(array)"> {{array["collector"]}} </td>
                <td @click="modifyArray(array, true)"> <v-btn icon small> <v-icon small> mdi-lead-pencil </v-icon></v-btn></td>
              </tr>
              <tr v-if="arrays.length <= 0">
                <td colspan="100%" style="pointer-events: none; text-align: center; opacity: 0.7; height: 50px"> Please use the button above to add a array</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>

      <br>

      <!--  Futher information  -->
      <ConfigSection name="Solar Fluid">

        <v-form ref="form" v-model="form_valid" v-on:submit.prevent>
          <v-row>
            <v-subheader class="label"> Fluid </v-subheader>
            <v-autocomplete
                filled dense hide-details outlined
                class="px-1"
                :class="fluid_name === null ? 'isWarning' : ''"
                label="Fluid Type"
                v-model="fluid_name"
                :items="fluids"
                item-value="name"
                :item-text="['combined_name']"
                auto-select-first
                clearable
                @change="updateFluid"
            > </v-autocomplete>
            <v-text-field
                filled dense hide-details outlined
                v-if="!_isPure(fluid_name)"
                class="px-1"
                style="max-width: 150px;"
                v-model="concentration"
                label="Vol.%"
                :rules="[rules.required, rules.minimum(concentration, 0), rules.maximum(concentration, 100)]"
                @change="updateFluid"
            ></v-text-field>
            <InfoTooltip>
              Solar fluid for all collector arrays (primary side).
              Used for thermal power calculation of the plant (all arrays combined) for volume flow / mass flow measurements on primary side.
            </InfoTooltip>
          </v-row>

          <v-row class="ma-0 pa-0 pl-14 pr-4">
            <span class="caption accent--text" v-if="fluid_name === null">
              WARNING <InfoTooltip small color="accent">
                Setting the fluid is optional. It is required only if thermal power is not available directly as a sensor (in that case, thermal power is calculated based on the fluid).
                Thermal power is required for the Themal Power Check / ISO 24194.
              </InfoTooltip>
            </span>
            <v-spacer></v-spacer>
            <span class="caption light--text"> For more information see the <a class="light--text" target="_blank" href="http://www.coolprop.org/fluid_properties/Incompressibles.html#id124">CoolProp</a> library. </span>
          </v-row>

        </v-form>
      </ConfigSection>
    </v-container>

    <br>

     <v-row>
       <v-btn color="white" light outlined @click="back()"> Back </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="white" outlined dark @click="next()" :disabled="!isReady()"> Next </v-btn>
    </v-row>
  </config-container>
</template>

<script>
 
  import * as backend from "@/plugins/backend_api";
  import configContainer from "@/components/ConfigContainer";
  import ConfigSection from "@/components/ConfigSection.vue";
  import InfoTooltip from "@/components/_InfoTooltip.vue";
  import {INPUT_RULES} from "@/components/Config_TextField.vue";

  export default {
    name: 'ArrayOverview',
    components: {InfoTooltip, ConfigSection, configContainer},
    props: {
      plant_id: {},
    },
    data: () => ({
      error_message: "",
      rules: INPUT_RULES,
      show_warning: false,
      form_valid: true,

      //user selection
      fluid: null,
      fluid_name: null,
      concentration: null,

      // api
      fluids: [],
      arrays: [],

    }),
    mounted() {
      this.getArrays()
      this.getFluids()
      this.getPlantFluid()
    },
    methods: {
      getFluids(){
        return backend.get_fluids().then(res => {
          let fluids = res.data

          // allows filtering both name and description in autocompletion menu
          fluids.forEach(val => val.combined_name = val.name + " (" + val.description+ ")")

          this.fluids = fluids
        }).catch(res => {
          this.error_message = res
        })
      },
      getArrays(){
        return backend.get_arrays(this.plant_id).then(res => {
          this.arrays = res.data
        }).catch(res => {
          this.error_message = res
        })
      },
      getPlantFluid(){
        return backend.get_plant(this.plant_id).then(res => {
          let plant_info = res.data
          this.fluid = plant_info.fluid_solar
          if (this.fluid !== null){
            this.concentration = this.fluid.concentration ? this.fluid.concentration.magnitude : null
            this.fluid_name = this.fluid.fluid
          }
        }).catch(res => {
          this.error_message = res
        })
      },
      _isPure(fluid_name){
        if (fluid_name === null){return true}
        let selected_fluid = this.fluids.find(val => val.name === fluid_name)
        if (selected_fluid === undefined){return false}
        return selected_fluid.is_pure
      },
      modifyArray(array, unlocked=false){
        this.$router.push({
          name: this.$router.currentRoute.path.includes("/plant/") ? "editArray" : "setup_editArray",
          params: {plant_id: this.plant_id, array_id: array.id, unlocked: unlocked},
        })
      },

      updateFluid(){
        let isUndefined = this.fluid_name === null
        let isPure = this._isPure(this.fluid_name)
        let isConcentrationSet = this.concentration > 0 & this.concentration < 100
        if (isPure) {this.concentration = null}

        if (isUndefined) {
          return this._deleteFluid()
        } else if (isPure || isConcentrationSet){
          return this._updateFluid()
        } else {
          setTimeout(this.$refs.form.validate, 200)
        }
      },
      _updateFluid(){
        return backend.update_fluid(this.plant_id, this.fluid_name, this.concentration).then(()=>{
          this.getPlantFluid()
        }).catch(res => {
          this.error_message = res
        })
      },
      _deleteFluid(){
        return backend.delete_fluid(this.plant_id).then(()=>{
          this.getPlantFluid()
        }).catch(res => {
          this.error_message = res
        })
      },
      isReady(){
        return (this.arrays.length > 0)
      },
      next() {
        this.$refs.form.validate()
        if (this.form_valid) {
          let name = (this.$router.currentRoute.path.includes("/plant/")) ? "sensorMapping" : "setup_newSensors"
          this.$router.push({name: name, params: {"plant_id": this.plant_id}})
        }
      },
      back(){
        let name = (this.$router.currentRoute.path.includes("/plant/")) ? "editPlant" : "setup_editPlant"
        this.$router.push({name: name, params: {"plant_id": this.plant_id}})
      },
    },
  }
</script>

<style scoped>

.isWarning >>> fieldset{
  /*border: 2px solid var(--v-ternary-base);*/
}

.row {
  margin: 0;
}

</style>