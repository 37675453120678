<template>
<v-container>
  <top-line-alert :error_message="error_message"></top-line-alert>
  <TopLineAlertNoData v-if="isNoData"></TopLineAlertNoData>
  <template v-else>

    <SunPeekDialog header="Recalculate Sensors" color="ternary" :value="isRecalculationRequested" v-on:confirm="realculateAll" v-on:cancel="isRecalculationRequested=false">
      Do you really want to recalculate all virtual sensors? <br>
      Depending on the accessible data, this might take a while until the operation is finished. <br>
      It will also delete any old virtual sensor data!
    </SunPeekDialog>

    <v-row style="padding: 10px">
      <h2> Sensor Data </h2>
      <InfoTooltip> This page allows to check the sensor values and to recalculate virtual sensors. </InfoTooltip>
      <v-spacer></v-spacer>
    </v-row>

    <v-toolbar color="primary" dark flat outlined>
      <v-subheader> Measurement Period: </v-subheader>
      <v-text-field class="mx-2" label="Start" outlined dense type="date" style="max-width: 160px" hide-details v-model="start"></v-text-field>
      <v-text-field class="mx-2" label="End" outlined dense type="date" style="max-width: 160px" hide-details v-model="end"></v-text-field>
      <v-icon small title="clear range" @click="reset_start_end"> mdi-close</v-icon>
      <v-spacer></v-spacer>
      <v-btn color="secondary" :disabled="Object.keys(shown_sensor).length === 0" @click="showSensor(shown_sensor)"> apply range</v-btn>
<!--      <v-divider vertical class="mx-2"></v-divider>-->
<!--      <v-btn class="primary&#45;&#45;text"  style="font-size: 12px!important;" title="Recalculates all virtual sensors based on the measured sensor values" color="accent" @click="isRecalculationRequested = true">-->
<!--        <v-icon class="mr-1"> mdi-refresh </v-icon> Recalculate <br> Virtuals-->
<!--      </v-btn>-->
    </v-toolbar>

    <v-card min-height="200px" max-height="400px" height="calc(100vh - 250px - 300px)" class="mt-5 mx-6 pa-1" flat color="almost_white">
      <v-progress-linear indeterminate v-if="isDataLoading"></v-progress-linear>
      <SensorTimeseriesPlot :data="shown_data" :sensor="shown_sensor" v-else-if="shown_data.length != 0" :utc_offset="UTC_offset"></SensorTimeseriesPlot>
      <v-subheader v-else> Doubleclick on a sensor or click on the <v-icon small class="mx-2"> mdi-chart-line-variant </v-icon> buttons to display sensor data </v-subheader>
    </v-card>


    <v-card class="my-2" color="verylight" light flat>
      <v-toolbar color="transparent" dense class="ma-0" flat>
        <v-text-field v-model="search_string"
            class="mx-3"
            dense
            clearable
            hide-details
            label="Sensor Name"
            prepend-inner-icon="mdi-magnify"
            single-line
            outlined
            style="max-width: 300px"
        ></v-text-field>
        <v-select v-model="show_virtual" class="mx-3" label="Origin (Measured/Virtual) " :items="is_virtual_options" outlined dense hide-details style="max-width: 180px"> </v-select>
        <v-select v-model="show_mapped" class="mx-3" label="Mapped (yes/no)" :items="is_mapped_options" outlined dense hide-details style="max-width: 180px"> </v-select>
        <v-icon title="reset filter" @click="resetFilter"> mdi-window-close</v-icon>

        <v-spacer></v-spacer>
        <v-btn style="font-size: 12px!important;" title="Recalculates all virtual sensors based on the measured sensor values" color="secondary" @click="isRecalculationRequested = true">
          <v-icon class="mr-1"> mdi-refresh </v-icon> Recalculate <br> Virtuals
        </v-btn>
      </v-toolbar>
    </v-card>

    <v-card outlined color="secondary" class="pa-1 mx-10" dark flat>
      <v-simple-table dense class="primary sensor_table" height="max(200px, calc(100vh - 752px))" fixed-header>
        <thead>
          <tr>
            <th class="secondary " style="min-width: 120px"> Sensor Name <InfoTooltip small> Name of the sensors as it appears in the input data </InfoTooltip></th>
            <th class="secondary " style="min-width: 200px" > Sensor Type <InfoTooltip small> Type of the sensors </InfoTooltip></th>
            <th class="secondary " style="min-width: 80px; text-align: center"> Unit <InfoTooltip small> Measurement unit of the sensors as it appears in the input data </InfoTooltip> </th>
            <th class="secondary" style="min-width: 110px; text-align: center"> is Mapped <InfoTooltip small> Shows whether the sensor is mapped to any component in the Sensor Mapping </InfoTooltip> </th>
            <th class="secondary" style="min-width: 110px; text-align: center"> Origin <InfoTooltip small> Shows whether the sensor is calculated/virtual or a measured directly </InfoTooltip> </th>
            <th class="secondary" style="min-width: 90px; text-align: center"> Actions </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="sensor in this.filtered_sensors">
            <tr :key="sensor.id" v-if="!isSensorFiltered(sensor)" @click="selectSensor(sensor)" @dblclick="showSensor(sensor)" v-bind:class="{isSelected: sensor.id === selected_sensor.id}">

              <td style="text-align: left"> <v-chip small :color="sensor.is_virtual ? 'secondary' : 'ternary'"> {{sensor.raw_name}} </v-chip> </td>
              <td> {{sensor["sensor_type"]}} </td>
              <td> {{sensor["formatted_unit"]}} </td>
              <td>
                <span v-if="sensor['is_mapped']"> yes </span>
                <span v-else class="darkgray--text"> no</span>
              </td>
              <td>
                <span v-if="sensor['is_virtual']"> Virtual </span>
                <span v-else> Measured </span>
              </td>
              <td>
                <v-btn-toggle rounded>
  <!--                <v-btn x-small v-if="sensor.is_virtual" @click="recalculate(sensor)" color="secondary" title="refresh calculation"> <v-icon small> mdi-refresh </v-icon></v-btn>-->
                  <v-btn x-small v-if="!sensor.is_virtual" @click="editSensor(sensor)" color="ternary" title="edit sensor"> <v-icon small> mdi-pencil </v-icon></v-btn>
                  <v-btn x-small @click="showSensor(sensor)" color="gray" title="display data"> <v-icon small> mdi-chart-line-variant </v-icon> </v-btn>
                </v-btn-toggle>
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>

      <LoadingScreen v-model="isCalculating" title="Calculating Virtual Sensors"> Recalculating virtual sensors based on the raw data. The calculation will be completed shortly. </LoadingScreen>
    </v-card>
  </template>

</v-container>
</template>

<script>


import * as backend from "@/plugins/backend_api";
import InfoTooltip from "@/components/_InfoTooltip";
import TopLineAlert from "@/components/TopLineAlert";
import SensorTimeseriesPlot from "@/components/Sensors_TimeseriesPlot.vue";
import LoadingScreen from "@/components/LoadingScreen.vue";
import SunPeekDialog from "@/components/Dialog.vue";
import TopLineAlertNoData from "@/components/TopLineAlert_NoData.vue";
import {apiDateToLocalDate, localDateToAPIString} from "@/pages/helper";

const ORIGIN = {
  ALL: "All Sensors",
  VIRTUAL: "Virtuals",
  MEASURED: "Measured",
}
const IS_MAPPED = {
  ALL: "All Sensors",
  MAPPED: "Mapped",
  UNMAPPED: "Unmapped",
}

export default {
  name: "Plant_SensorValidation",
  components: {
    TopLineAlertNoData,
    SunPeekDialog,
    LoadingScreen,
    SensorTimeseriesPlot,
    TopLineAlert,
    InfoTooltip,
  },
  props: {
    plant_id: {},
  },
  data: () => ({
    UTC_offset: null,

    error_message: "",
    sensors: [],
    selected_sensor: {},

    range: [],
    start: null,
    end: null,
    shown_data: [],
    shown_sensor: {},
    isDataLoading: false,
    isCalculating: false,
    isRecalculationRequested: false,

    search_string: "",
    show_virtual: null,
    show_mapped: null,
    isNoData: false,

    is_virtual_options: Object.values(ORIGIN),
    is_mapped_options: Object.values(IS_MAPPED),
  }),
  mounted() {
    this.getRange()
    this.getSensors()
    this.resetFilter()
    this.getUTCString()
  },
  methods: {
    getUTCString(){
      return backend.get_plant_utc(this.plant_id).then(res => {
        this.UTC_offset = res
        this.reset_start_end()
      })
    },
    getSensors() {
      return backend.get_sensors(this.plant_id).then(res => {
        this.sensors = res.data
      })
    },
    showSensor(sensor){
      this.isDataLoading = true
      let start = localDateToAPIString(this.start, this.UTC_offset/60)
      let end = localDateToAPIString(this.end, this.UTC_offset/60)

      return backend.get_sensordata(this.plant_id, sensor.id, start, end).then(res => {
        this.shown_data = res.data
        this.shown_sensor = sensor
      }).catch(res => {
        this.error_message = res
      }).finally(() => {
        this.isDataLoading = false
      })
    },

    selectSensor(sensor){
      this.selected_sensor = sensor;
    },
    editSensor(sensor){
      let name = (this.$router.currentRoute.path.includes("/plant/")) ? "editSensor" : "setup_editSensor"
      this.$router.push( {name: name, params: {plant_id: this.plant_id, sensor_id: sensor.id, destroy: true}})
    },
    resetFilter(){
      this.show_virtual = ORIGIN.ALL
      this.show_mapped = IS_MAPPED.MAPPED
      this.search_string = ""
    },
    isSensorFiltered(sensor){
      let only_virtual = this.show_virtual === ORIGIN.VIRTUAL
      let only_raw = this.show_virtual === ORIGIN.MEASURED
      let is_filtered_virtual = (only_virtual && !sensor.is_virtual) || (only_raw && sensor.is_virtual)

      let only_mapped = this.show_mapped === IS_MAPPED.MAPPED
      let only_unmapped = this.show_mapped === IS_MAPPED.UNMAPPED
      let is_filtered_mapped = (only_mapped && !sensor.is_mapped) || (only_unmapped && sensor.is_mapped)

      let is_filtered_name = !sensor.raw_name.toLowerCase().includes(this.search_string.toLowerCase())
      return is_filtered_name || is_filtered_virtual || is_filtered_mapped
    },
    realculateAll(){
      this.isRecalculationRequested = false
      this.isCalculating = true
      return backend.recalculateVirtuals(this.plant_id).catch(res => {
        this.error_message = res
      }).finally(() => {
        this.isCalculating = false
      })
    },
    recalculate(sensor){
      // todo: call recalculate one
      console.log("todo, recalculate", sensor)
    },

    getRange(){
      return backend.get_start_end(this.plant_id).then(res => {
        this.range = [res.data.start, res.data.end]
        this.isNoData = (this.range[0] === null) && (this.range[1] === null)
        this.reset_start_end()
      }).catch(res => {
        this.error_message = res
      })
    },
    reset_start_end(){
      let offset = this.UTC_offset
      let start = apiDateToLocalDate(this.range[0], offset/60)
      let end = apiDateToLocalDate(this.range[1], offset/60)
      end.setDate(end.getDate() + 1)    // because of rounding when getting the substring
      this.start = start.toISOString().substring(0, 10)
      this.end = end.toISOString().substring(0, 10)
    },
  },
  computed:{
    filtered_sensors() {
      return [...this.sensors].sort((a, b) => {
        if (a.is_virtual === b.is_virtual){
          //https://stackoverflow.com/questions/51165/how-to-sort-strings-in-javascript
          return ('' + a.raw_name).localeCompare(b.raw_name)
        }
        return a.is_virtual - b.is_virtual
      })
    }
  }
}
</script>

<style scoped>

.row{
  margin: 0px;
}

.v-card{
  padding: 10px 20px;
  margin: 0 auto;
}



.sensor_table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}
.sensor_table td {
  padding: 3px 10px;
}

/*noinspection ALL*/
.sensor_table tr:hover{
  background-color: var(--v-secondary-base) !important;
}
.sensor_table tr.isSelected{
  background-color: var(--v-ternary-base) !important;
}
</style>