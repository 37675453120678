<template>
  <Plotly
      :data="traces"
      :layout="layout"
      doubleClick="autosize"
      :display-mode-bar="false"
      class="scatter_plot"
      @selecting="select"
      @selected="select"
      @relayout="handleRelayout"
      watchShallow
      ref="pc_timeseries_plot"
  ></Plotly>
</template>


<script>
import { Plotly } from 'vue-plotly';
import * as d3 from "d3";


export default {
  name: "pc-scatter",
  components: {
    Plotly
  },
  emits: ['input:value'],
  props: {
    value: {default: []},
    estimated: [],
    measured: [],
    timestamps: [],
    useSafety: {default: true},
    f_total: {default: 100},
    tool: {default: "zoom"},
    reset_counter: {default: 0, type: Number},
  },
  data: () => ({
    layout: {
      dragmode: "zoom",
      margin: {l: 70, b:90, r: 40, t:50, pad:5},
      paper_bgcolor: 'rgba(0,0,0,0)',
      xaxis: {
        autorange: false,
        linecolor: 'grey',
        linewidth: 1,
        mirror: true,
      },
      yaxis: {
        autorange: false,
        title: {text: 'Measured Power [W/m²]', font: {size: 11}},
        linecolor: 'grey',
        linewidth: 1,
        mirror: true,
      },
      title: {text: "<b>Measured-Estimated Comparison</b>", font: {size: 14}},
    },
    options: {
      doubleClick: "reset",
    }
  }),
  mounted() {
    this.updateShapesAndLayout()
    this.updateAxisTitle()
    this.changeTool()
    this.reset_range()
  },
  watch:{
    tool(){
      this.changeTool()
    },
    f_total(){
      this.updateShapesAndLayout()
    },
    useSafety(){
      this.updateShapesAndLayout()
      this.updateAxisTitle()
    },
    measured(){
      this.reset_range()
      this.updateShapesAndLayout()
    },
    estimated(){
      this.reset_range()
    },
    reset_counter(){
      this.reset_range()
    },
  },
  computed: {
    selected_points: {
      get () { return this.value },
      set (value) { this.$emit('input', value); },
    },
    traces(){
      if (this.estimated === null){return []}

      let factor = this.f_total/100
      let estimated = this.estimated.magnitude
      let measured = !this.measured ? estimated.map(x => x*factor) : this.measured.magnitude

      if (this.useSafety) {
        estimated = estimated.map(i => i * factor)
      }

      let opacity = estimated.map((item, i) => this.selected_points.includes(i) ? 0.9 : 0.2)
      if (this.selected_points.length === 0){
        opacity = 0.9
      }

      let data = [{
        x: estimated,
        y: measured,
        type:"scatter",
        name: 'measured power',
        mode: 'markers',
        marker: {"opacity": opacity},
      }]
      return data
    },
  },
  methods: {
    select(e){
      let selectedpoints = !!e && e.points.length > 0 ? e.points[0].data.selectedpoints : []
      this.selected_points = selectedpoints
    },
    handleRelayout(event){
      if ("xaxis.autorange" in event) {
        this.reset_range()
      }
    },
    updateAxisTitle(){
      let xaxis = this.layout.xaxis
      let x_axis_title = this.useSafety ? 'Estimated Power [W/m²]<br>(with safety factor)' : 'Estimated Power [W/m²]<br>(no safety factor)'
      xaxis["title"] =  {text: x_axis_title, font: {size: 11}}
      this.$set(this.layout, "xaxis", xaxis)
    },
    changeTool(){
      this.layout.dragmode = this.tool
      this.layout = {...this.layout}
    },
    reset_range(){
      let layout = {...this.layout}
      let estimated = !this.estimated ? [] : this.estimated.magnitude
      let measured = !this.measured ? [] : this.measured.magnitude
      let margin = 0.1 * Math.max(...estimated, ...measured)    // note that this does not take f_safety into account
      let min = Math.min(...estimated, ...measured) - margin
      let max = Math.max(...estimated, ...measured) + margin
      min = Math.floor(min / 10) * 10
      max = Math.ceil(max / 10) * 10
      layout["yaxis"]["autorange"] = false
      layout["xaxis"]["autorange"] = false
      layout["yaxis"]["range"] = [min, max]
      layout["xaxis"]["range"] = [min, max]
      this.layout = {...layout}
    },
    updateShapesAndLayout(){
      let min = -100000
      let max = 100000

      let lower_triangle = d3.path();
      let chi = this.useSafety ? 1.00 : this.f_total/100

      lower_triangle.moveTo(min, min*chi)
      lower_triangle.lineTo(max, max*chi)
      lower_triangle.lineTo(max, min)
      lower_triangle.closePath()

      let upper_triangle = d3.path();
      upper_triangle.moveTo(min, min*chi)
      upper_triangle.lineTo(max, max*chi)
      upper_triangle.lineTo(min, max)
      upper_triangle.closePath()

      let shapes = [
        {
          type: 'line',
          x0: min,
          y0: min,
          x1: max,
          y1: max,
          line: {width: 1},
          opacity: 0.3,
        },
        {
          type: 'line',
          x0: min,
          y0: min * chi,
          x1: max,
          y1: max * chi,
          line: {width: 0.5, color: "#19a900"},
          opacity: 0.3,
        },
        {
          type: 'path',
          path: lower_triangle.toString(),
          fillcolor: '#f50000',
          opacity: 0.05,
          line: { width: 0 },
          layer: 'below',
        },
        {
          type: 'path',
          path: upper_triangle.toString(),
          fillcolor: '#19a900',
          opacity: 0.05,
          line: { width: 0 },
          layer: 'below',
        },
      ]

      let annotations = [
        {
          xref: 'paper', x: 0,
          yref: "paper", y: 1,
          text: 'within safety',
          font: {color: '#6a9364'},
          showarrow: false,
        },
        {
          xref: 'paper', x: 1,
          yref: 'paper', y: 0,
          text: 'outside safety',
          font: {color: '#e38080'},
          showarrow: false,
        }]


      if (! this.measured){
        annotations = [{
          xref: 'paper', x: 0.5,
          yref: 'paper', y: 1,
          text: 'no measured data available!',
          font: {color: '#000000', size: 15}, opacity: 0.5,
          showarrow: false,
        }]
        shapes = [{
          type: 'rect',
          xref: 'paper',  y0: -0.02, y1: 1.02,
          yref: 'paper',  x0: -0.02, x1: 1.02,
          fillcolor: '#565656', opacity: 0.5,
          line: {width: 0}, layer: 'above',
        },]
      }

      let layout = {...this.layout}
      layout["shapes"] = shapes
      layout["annotations"] = annotations
      this.layout = layout
    },
  },
}
</script>

<style scoped>

.zoomlayer .select-outline{
  stroke-width: 1px;
  stroke: black;
  shape-rendering: auto;
  stroke-dasharray: 1px 1px;
}

</style>