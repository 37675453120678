<template>
  <config-autocomplete
      clearable
      v-model="selected_sensor"
      :items="sensor_names"
      :label="getSlotLabel()"
      :color="getColor()"
      :placeholder="getSlotPlaceholder()"
      :description="getSlotDescription()"
      :required="required || is_required_slot"
      @input="save"
      :clear-icon-cb="save"
      :class="{'black--text': isSensorSet}"
      :disabled="disabled"
  ></config-autocomplete>
</template>

<script>
import ConfigAutocomplete from "@/components/Config_Autocomplete.vue";
import {isSensorCalculatable} from "@/pages/helper";

export default {
  name: "SensorMappingAutocomplete",
  components: {ConfigAutocomplete},
  props: {
    sensor_slot: {type: Object},
    component: {type: Object},
    sensor_names: {type: Array},
    raw_sensors: {type: Array},
    required_slots: {type: Array},
    required: Boolean,
    disabled: Boolean,
  },
  data: () => ({
    selected_sensor: null,
    color_valid: "lightblue2",
    color_missing: "white",
    color_missing_required: "verylightwarning",
  }),
  mounted() {
    this.parse_compontent()
  },
  watch: {
    component() {
      this.parse_compontent()
    },
    sensor_slot(){
      this.parse_compontent()
    }
  },
  computed: {
    isSensorSet(){
      return isSensorCalculatable(this.raw_sensors, this.selected_sensor)
    },
    is_required_slot(){
      if (this.required_slots === undefined){return false}
      return this.required_slots.includes(this.sensor_slot.name)
    }
  },
  methods: {
    parse_compontent(){
      if (this.component !== undefined) {
        this.selected_sensor = "sensor_map" in this.component ? this.component["sensor_map"][this.sensor_slot.name] : null
      }
    },
    save() {
      this.$emit("save", this.sensor_slot.name, this.selected_sensor)
    },
    getSlotLabel(){
      return this.sensor_slot.descriptive_name || ""
    },
    getSlotDescription(){
      return this.sensor_slot.description
    },
    getColor(){
      return this.isSensorSet ? this.color_valid : (this.required || this.is_required_slot ? this.color_missing_required : this.color_missing)
    },
    getSlotPlaceholder() {
      if (Object.keys(this.sensor_slot).length ===0) {return ""}
      let string = "e.g., " + this.sensor_slot.descriptive_name + " sensor"
      if ("compatible_unit_str" in this.sensor_slot.sensor_type){
        string += " [" + this.sensor_slot.sensor_type.compatible_unit_str +"]"
      }
      return this.isSensorSet ? "Calculated" : string
    }
  },
 }
</script>

<style scoped>

>>> .label{
  width: 180px !important;
}

</style>