<template>
  <v-card flat>
    <v-icon size="150" v-if="connected" color="success"> {{ icon }} </v-icon>
    <v-icon size="150" v-if="!connected" :color="unclear ? 'light' : 'warning'" > {{ icon }}-off </v-icon>
    <v-card-subtitle style="text-align: center"> {{ subtitle }} </v-card-subtitle>
  </v-card>
</template>


<script>
export default {
  name: "BackendHelpIcon",
  props: {
    connected: {type: Boolean, default: true},
    unclear: {type: Boolean, default: false},
    icon: {default: ""},
    subtitle: {default: ""},
  }
}
</script>

<style scoped>

</style>