<template>
  <v-container class="pa-0 ma-0">
    <v-form ref="form" v-model="isValid">
      <config-section name="Collector Temperatures" color="white" bordercolor="lightgray">
        <SensorMappingAutocomplete
            :required_slots="REQUIRED_ARRAY_SLOTS"
            :sensor_names="sensor_names"
            :sensor_slot="getSlot('te_in')"
            :component="array"
            @save="saveChange"
            :disabled="isSaving"
            :raw_sensors="raw_sensors"
        ></SensorMappingAutocomplete>
        <SensorMappingAutocomplete
            :required_slots="REQUIRED_ARRAY_SLOTS"
            :sensor_names="sensor_names"
            :sensor_slot="getSlot('te_out')"
            :component="array"
            @save="saveChange"
            :disabled="isSaving"
            :raw_sensors="raw_sensors"
        ></SensorMappingAutocomplete>
      </config-section>

      <config-section name="Irradiance" color="white" bordercolor="lightgray">
        <v-row>
          <v-subheader>
            <i> Alternative Calculation</i>
            <InfoTooltip small type="text">
              Requirements depending on the ISO formula and collectors used: <br>
              Formula 1: uses the global irradiance. <br>
              Formula 2: uses the direct and beam irradiance. <br>
              Formula 2: uses the DNI irradiance. <br>
            </InfoTooltip>
          </v-subheader>
          <v-spacer></v-spacer>
        </v-row>

        <SensorMappingAutocomplete
            :required_slots="REQUIRED_ARRAY_SLOTS"
            :sensor_names="sensor_names"
            :sensor_slot="getSlot('in_global')"
            :component="array"
            @save="saveChange"
            :disabled="isSaving"
            :raw_sensors="raw_sensors"
        ></SensorMappingAutocomplete>
        <SensorMappingAutocomplete
            :required_slots="REQUIRED_ARRAY_SLOTS"
            :sensor_names="sensor_names"
            :sensor_slot="getSlot('in_beam')"
            :component="array"
            @save="saveChange"
            :disabled="isSaving"
            :raw_sensors="raw_sensors"
        ></SensorMappingAutocomplete>
        <SensorMappingAutocomplete
            :required_slots="REQUIRED_ARRAY_SLOTS"
            :sensor_names="sensor_names"
            :sensor_slot="getSlot('in_diffuse')"
            :component="array"
            @save="saveChange"
            :disabled="isSaving"
            :raw_sensors="raw_sensors"
        ></SensorMappingAutocomplete>
        <SensorMappingAutocomplete
            :required_slots="REQUIRED_ARRAY_SLOTS"
            :sensor_names="sensor_names"
            :sensor_slot="getSlot('in_dni')"
            :component="array"
            @save="saveChange"
            :disabled="isSaving"
            :raw_sensors="raw_sensors"
        ></SensorMappingAutocomplete>
      </config-section>

      <config-section name="Array Power" color="white" bordercolor="lightgray" v-if="!hide_optional">
        <SensorMappingAutocomplete
            :required_slots="REQUIRED_ARRAY_SLOTS"
            :sensor_names="sensor_names"
            :sensor_slot="getSlot('tp')"
            :component="array"
            @save="saveChange"
            :disabled="isSaving"
            :raw_sensors="raw_sensors"
        ></SensorMappingAutocomplete>

        <SensorMappingAutocomplete
            :required_slots="REQUIRED_ARRAY_SLOTS"
            :sensor_names="sensor_names"
            :sensor_slot="getSlot('vf')"
            :component="array"
            @save="saveChange"
            :disabled="isSaving"
            :raw_sensors="raw_sensors"
        ></SensorMappingAutocomplete>

        <SensorMappingAutocomplete
            :required_slots="REQUIRED_ARRAY_SLOTS"
            :sensor_names="sensor_names"
            :sensor_slot="getSlot('mf')"
            :component="array"
            @save="saveChange"
            :disabled="isSaving"
            :raw_sensors="raw_sensors"
        ></SensorMappingAutocomplete>
      </config-section>

      <config-section name="Shading" color="white" bordercolor="lightgray" v-if="!hide_optional">
        <SensorMappingAutocomplete
            :required_slots="REQUIRED_ARRAY_SLOTS"
            :sensor_names="sensor_names"
            :sensor_slot="getSlot('is_shadowed')"
            :component="array"
            @save="saveChange"
            :disabled="isSaving"
            :raw_sensors="raw_sensors"
        ></SensorMappingAutocomplete>
      </config-section>
    </v-form>
  </v-container>
</template>

<script>
import ConfigSection from "@/components/ConfigSection.vue";
import * as backend from "@/plugins/backend_api";
import SensorMappingAutocomplete from "@/components/SensorMapping__Autocomplete.vue";
import {REQUIRED_ARRAY_SLOTS} from "@/pages/helper";
import InfoTooltip from "@/components/_InfoTooltip.vue";

export default {
  name: "SensorMapping_Array",
  components: {InfoTooltip, SensorMappingAutocomplete, ConfigSection},
  props: {
    array: {type: Object},
    sensor_names: {type: Array},
    hide_optional: Boolean,
    raw_sensors: {type: Array},
  },
  mounted() {
    this.getArraySlots()
  },
  watch: {
    isValid(){
      this.$emit("isValid", this.isValid)
    },
    array(){
      this.$refs.form.validate()
      let inconsistent = this.isValid !== this.array.isValid
      if (inconsistent) {this.$emit("isValid", this.isValid)}
    }
  },
  data: () => ({
    isSaving: false,
    slots: {},
    isValid: true,
    REQUIRED_ARRAY_SLOTS: REQUIRED_ARRAY_SLOTS,
  }),
  methods: {
    getArraySlots(){
      backend.get_channel_names("array").then(res => {
        this.slots = res.data

      }).catch(res => {
        this.$emit("error", res)
      })
    },
    getSlot(slot_name){
      if (Object.keys(this.slots).length === 0 ){return {}}
      return this.slots.filter(val => val.name === slot_name)[0]
    },
    saveChange(slot_name, slot_value){
      let mapping = {
        "sensor_map": {[slot_name]: slot_value},
        "id": this.array["id"]
      }
      this.isSaving = true
      return backend.update_array(mapping).catch(res => {
        this.$emit("error", res)
      }).finally(() => {
        this.isSaving = false
        this.$emit("save")
      })
    },
  },
}
</script>

<style scoped>

</style>