<template>
  <config-container class="pa-0 ma-0" :error_message="error_message">

    <h2> Power Check Settings </h2>
    <config-section name="Method">
      <config-autocomplete
          @input="markChanged"
          label="Method"
          v-model="saved_settings['evaluation_mode']"
          placeholder="AUTO"
          :items="METHOD_OPTIONS"
          description="Computation method used for computing the results. [ISO] follows the ISO 24194:2022(E) while [Extended] uses a different sampling procedure."
          :disabled="isLoading"
      ></config-autocomplete>
      <config-autocomplete
          @input="markChanged"
          label="Formula"
          v-model="saved_settings['formula']"
          placeholder="AUTO"
          :items="EQ_OPTIONS"
          description="Formula used for the Thermal Power Check (see Chapter 5.2 of ISO 24194:2022(E))."
          :disabled="isLoading"
      ></config-autocomplete>
    </config-section>

    <config-section name="Safety Factors">
      <config-text-field-row
          @input="markChanged"
          label="Measurement Uncertainty"
          :minimum="0"
          :maximum="100"
          v-model="saved_settings['safety_uncertainty']"
          suffix="%"
          placeholder="AUTO"
          description="Safety factor for measurement uncertainty (see Chapter 5.2 of ISO 24194:2022(E)) typically between 90% and 100%"
      ></config-text-field-row>
      <config-text-field-row
          @input="markChanged"
          label="Pipes"
          :minimum="0"
          :maximum="100"
          v-model="saved_settings['safety_pipes']"
          suffix="%"
          placeholder="AUTO"
          description="Safety factor for pipes (see Chapter 5.2 of ISO 24194:2022(E)) typically between 90% and 100%."
      ></config-text-field-row>
      <config-text-field-row
          @input="markChanged"
          label="Other"
          :minimum="0"
          :maximum="100"
          v-model="saved_settings['safety_others']"
          suffix="%"
          placeholder="AUTO"
          description="Safety factor for other influences (see Chapter 5.2 of ISO 24194:2022(E)) typically close to 100%."
      ></config-text-field-row>
      <v-subheader class="pa-0 mt-2 ma-0"> <v-spacer></v-spacer>  Resulting Safety Factor:  {{ safety_total > 0 ? safety_total + "%" : "AUTO"}} </v-subheader>
    </config-section>

    <config-section name="Other Parameters">
      <config-autocomplete
          @input="markChanged"
          label="Use Wind"
          v-model="saved_settings['wind_used']"
          :items="WIND_OPTIONS"
          placeholder="AUTO"
          description="Handles whether the Thermal Power Check considers the wind velocity.
                       If [true] a wind-velocity measurement is required for the method.
                       If [false] the wind-velocity requirement will not be checked for determining valid intervals.
                       If [AUTO], the best options accodrding to the mapped sensors is chosen."
          :disabled="isLoading"
      ></config-autocomplete>
    </config-section>

    <v-row class="ma-0 mt-3 pa-0">
      <v-btn @click="emitCancel" outlined> Cancel </v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="saveSettings" :disabled="!isChanged" color="ternary"> Save </v-btn>
    </v-row>
  </config-container>


</template>

<script>
import ConfigSection from "@/components/ConfigSection.vue";
import ConfigContainer from "@/components/ConfigContainer.vue";
import ConfigAutocomplete from "@/components/Config_Autocomplete.vue";
import ConfigTextFieldRow from "@/components/Config_TextFieldRow.vue";
import * as backend from "@/plugins/backend_api";

export default {
  name: "PC_Method_Settings",
  components: {
    ConfigTextFieldRow,
    ConfigAutocomplete,
    ConfigContainer,
    ConfigSection
  },
  props:{
    plant_id: {},
  },
  data: () => ({
    isLoading: false,
    error_message: "",
    isChanged: false,

    saved_settings: {
      formula: null,
      evaluation_mode: "ISO",
      wind_used: "AUTO",
      safety_pipes: null,
      safety_others: null,
      safety_uncertainty: null,
    },

    METHOD_OPTIONS: ["ISO", "extended"],
    EQ_OPTIONS: [1, 2, "AUTO"],
    WIND_OPTIONS: [true, false, "AUTO"],
  }),
  mounted() {
    this.getPCMethodSettings()
  },
  methods: {
    getPCMethodSettings(){
      this.isLoading = true
      return backend.get_pc_method_settings(this.plant_id)
          .then(res => {
            this.saved_settings = res.data
            this.$emit('update', this.saved_settings)
          })
          .catch(res => this.error_message = res)
          .finally(() => this.isLoading = false)
    },
    markChanged(){
      this.isChanged = true
    },
    emitCancel(){
      this.isChanged = false
      this.$emit('cancel')
      this.getPCMethodSettings()
    },
    saveSettings(){
      this.isLoading = true
      let settings = {...this.saved_settings}
      Object.keys(settings).forEach(key => {
        if (settings[key] === "") {settings[key] = null}
        if (settings[key] === "AUTO") {settings[key] = null}
      })
      return backend.save_pc_method_settings(this.plant_id, settings)
          .then(() => {
            this.getPCMethodSettings()
            this.$emit('saved')
          })
          .catch(res => this.error_message = res)
          .finally(() => this.isLoading = false)
    },
  },
  computed:{
    safety_total() {
      return this.saved_settings['safety_others']/100 * this.saved_settings['safety_pipes']/100 * this.saved_settings['safety_uncertainty']/100 * 100
    },
  }
}
</script>

<style scoped>

</style>