<template>
  <span class="ma-auto pa-auto">
    <v-icon size="50" color="success" v-if="connected"> mdi-arrow-left-right-bold</v-icon>
    <template v-else-if="unclear">
      <v-icon size="50" color="light"> mdi-dots-horizontal</v-icon>
    </template>
    <template v-else>
      <v-icon size="50" color="warning"> mdi-dots-horizontal </v-icon>
    </template>
  </span>
</template>


<script>
export default {
  name: "BackendHelpConnection",
  props: {
    connected: {type: Boolean, default: true},
    unclear: {type: Boolean, default: false},
  }
}
</script>

<style scoped>

</style>