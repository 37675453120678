<template>
    <router-view v-on:back="$emit('back')"/>
</template>

<script>
export default {
  name: "PassThrough"
}
</script>

<style scoped>

</style>