<template>
  <config-container @drop="handleDataDrop" @dragenter="handleDragStart" :error_message="error_message">
    <h1> Data Format </h1>
    <p>
      Please provide a sample data file with all required sensors. <InfoTooltip> The PC method requires ambient temperature, irradiation, thermal power, and flow- and return temperatures for each array</InfoTooltip>  <br>
      Check that the file was parsed correctly and adapt the settings if necessary.
    </p>

    <v-overlay
        absolute
        :color="isHovering ? 'ternary' : 'primary'"
        opacity="0.9"
        :value="isFileDragged"
        class="overlay_data_upload"
        :class="{'dragging_inside': isHovering, 'dragging_outside': isFileDragged & !isHovering}"
        @dragover="isHovering=true"
        @dragleave="isHovering=false"
        @drop="isHovering=false"
    >
      <v-row justify="center" >
        <v-icon x-large> mdi-download </v-icon>
        <v-card-title v-if="!isHovering"> Drop your Files here </v-card-title>
        <v-card-title v-if="isHovering"> Release the Files to upload </v-card-title>
      </v-row>
    </v-overlay>

    <v-container style="min-height: calc(min(60vh - 100px, 400px));">

      <!--  File Input -->
      <v-row class="my-2 pa-0 mx-2" flat>
        <v-file-input
            label="Please select a file"
            v-model="csvFile"
            show-size
            accept="text/csv"
            hide-details
            single-line
            outlined
            @change="getUploadResponse"
            @dragleave.stop.prevent="handleDragLeave"
            :loading="isReadingFile ? 'accent' : false"
            loader-height="5"
            class="mb-1"
        ></v-file-input>


        <!--  Data Upload Settings -->
        <DataUpload_Settings dense v-model="csv_settings" :show="showDataUploadSettings" :plant_id="plant_id" @input="getUploadResponse" @error="res => upload_error_message=res"></DataUpload_Settings>
      </v-row>


      <!-- Table Response -->
      <ConfigSection padding="0px" class="mt-1" name="Parsed File:">
        <v-simple-table dense light class="verylight" fixed-header height="calc(100vh - 550px)" >
          <thead>
          <tr>
            <th class="lighter" style="min-width: 100px!important; font-size: 12px;"> Index </th>
            <th class="lighter" style="min-width: 100px!important; font-size: 11px;" v-for="column in column_names" :key="column"> {{column}} </th>
          </tr>
          </thead>
          <tbody>

            <tr v-if="!!upload_error_message && upload_error_message!==''" disabled>
              <v-subheader class="pa-3 warning--text" style="height: inherit"> Parsing the file was not successful: <br/>
              "{{ "response" in upload_error_message ? upload_error_message.response.data.detail : upload_error_message }}"<br>
                Please check your settings!
              </v-subheader>
            </tr>

            <tr v-if="!isCsvLoaded" disabled>
              <v-subheader> Please use the input above to upload files </v-subheader>
            </tr>

            <tr v-for="(values, i) in column_values" :key="i" v-else>
              <td> {{index_values[i]}}</td>
              <td v-for="(value, j) in values" :key="j"> {{value === null ? "NaN" : value }} </td>
            </tr>
          </tbody>
        </v-simple-table>
      </ConfigSection>

      <v-row class="ma-0 mx-2 pa-0">
        <v-spacer></v-spacer>
        <v-btn class="mx-1" color="white" text @click="showDataUploadSettings = !showDataUploadSettings"> Change Settings </v-btn>
        <v-btn class="mx-1" color="ternary" @click="showConfirmUpload = true" :disabled="!csvFile || !!upload_error_message"> Confirm </v-btn>
      </v-row>
    </v-container>


    <sun-peek-dialog
        header="Was the data parsed correctly?"
        v-model="showConfirmUpload"
        color="ternary"
        btn_confirm="Confirm"
        v-on:confirm="confirmInput"
    > If the data was parsed correctly, please click on the "confirm" button.
      SunPeek will then save your settings, register the sensors (i.e., the columns) and upload the data for your further use.
      <br><br>
      <b> The following timezone is used: </b> <v-chip> {{csv_settings.timezone}} </v-chip>
    </sun-peek-dialog>

    <sun-peek-dialog
        header="Step already done!"
        v-model="isSensorsExist"
        color="info"
        dark
        btn_confirm="Next Step"
        btn_cancel="Delete"
        color_cancel="warning"
        persistent
        v-on:cancel="deleteAllSensors"
        v-on:confirm="goNext"
    > It seems you already completed this step - there are already sensors registered at the plant.
      If you want, you can reset this step and add new sensors. However, this will delete all data associated with the senors!
    </sun-peek-dialog>

    <loading-screen title="Updating Database" v-model="isSaving">
      Please wait a bit while the data is uploaded to SunPeek.
      This will add the sensors to the database and also store the data for your further use.
    </loading-screen>

    <v-row>
      <v-btn color="white" light outlined @click="goBack"> Back </v-btn>
      <v-spacer></v-spacer>
    </v-row>
  </config-container>
</template>

<script>
import * as backend from "@/plugins/backend_api";
import ConfigSection from "@/components/ConfigSection";
import configContainer from "@/components/ConfigContainer";
import InfoTooltip from "@/components/_InfoTooltip.vue";
import SunPeekDialog from "@/components/Dialog.vue";
import LoadingScreen from "@/components/LoadingScreen.vue";
import DataUpload_Settings from "@/components/DataUpload_Settings.vue";

export default {
  name: "InitialUpload",
  components: {
    DataUpload_Settings,
    LoadingScreen, SunPeekDialog, InfoTooltip, configContainer, ConfigSection},
  props: {
    plant_id: {},
  },
  data: () => ({
    // Files
    csvFile: null,
    csv_settings: {},
    showDataUploadSettings: false,
    showConfirmUpload: false,
    isSaving: false,
    isSensorsExist: false,

    // response from backend
    index_name: null,
    column_names: [],
    index_values: [],
    column_values: [],
    used_settings: {},

    //todo: getKnownSensors from backend.
    savedSensors: [],


    // Helper
    upload_error_message: "",
    error_message: "",
    isFileDragged: false,
    isHovering: false,
    isReadingFile: false,
    isCsvLoaded: false,
  }),
  mounted() {
    addEventListener("dragover", this.handleDragStart)
    addEventListener("dragleave", this.handleDragLeave)
    addEventListener("drop", this.handleDragLeave)

    this.getSavedSensors()
  },
  methods: {

    getSavedSensors(){
      backend.get_sensors(this.plant_id).then(res => {
        this.savedSensors = res.data.filter(val => !val.is_virtual)
        this.isSensorsExist = this.savedSensors.length > 0
      })
    },

    // Navigation
    goNext(){
      let name = "setup_sensorMapping"
      this.$router.push({name: name, params: {plant_id: this.plant_id}})
    },
    goBack(){
      let name = "setup_arrayOverview"
      this.$router.push({name: name, params: {plant_id: this.plant_id} })
    },

    // Get Files
    handleDataDrop(e){
      this.handleDragLeave()
      this.csvFile = e.dataTransfer.files[0]
      this.getUploadResponse()
    },
    handleDragStart(){
      this.isFileDragged = true
    },
    handleDragLeave(){
      this.isFileDragged = false
    },

    // Load Sensors from Files
    getUploadResponse() {
      this.isCsvLoaded = false
      if (this.csvFile === null){
        this.resetResponse()
        return
      }

      this.isReadingFile = true
      return backend.getUploadResponse(this.plant_id, this.csvFile, this.csv_settings).then(res => {
        let response = res.data
        this.index_name = response.index
        this.column_names = response.data.columns
        this.column_values = response.data.data
        this.index_values = response.data.index
        this.upload_error_message = ""
      }).catch(res => {
        this.resetResponse()
        this.upload_error_message = res
      }).finally(() => {
        this.isReadingFile = false
        this.isCsvLoaded = true
      })
    },
    resetResponse(){
      this.index_name = null
      this.column_names = []
      this.column_values = []
      this.index_values = []
      this.upload_error_message = ""
    },


    // Posts to Backend API
    // ---------------
    confirmInput(){
      this.showConfirmUpload = false
      this.saveSensors().then(
          this.goNext()
      )
    },
    saveSensors(){
      this.isSaving = true
      let sensor_data = this.column_names.map(item => ({"raw_name": item}))
      return backend.create_sensor(this.plant_id, sensor_data).then(res => {
        this.savedSensors = res.data
      }).catch(res => {
        this.error_message = res
      }).finally(() => {
        this.isSaving = false
      })
    },
    deleteAllSensors(){
      if (this.savedSensors.length === 0){return}
      let sensor_ids = this.savedSensors.map(val => val.id)
      this.isSaving = true
      return backend.delete_multiple_sensors(this.plant_id, sensor_ids).catch(res => {
        this.error_message = res
      }).finally(() => {
        this.isSaving = false
        this.getSavedSensors()
      })
    }
  },
  computed: {
  }
}
</script>

<style scoped>


.dragging_outside {
  margin: 10px;
  border: 1px dashed white;
}
.dragging_inside {
  margin: 10px;
  border: 1px solid white;
}

.v-overlay{
  transition: 0.01s cubic-bezier(0.25, 0.8, 0.5, 1), z-index 1ms;
}

table td{
  border: thin solid rgba(255, 255, 255, 0.12);
}

</style>