<template>
  <v-container outlined class="main_backend_help">


    <h1> Connection Status </h1>

    <v-row class="ma-0 pa-0">
      <template v-if="!isConnected">
        <p> Searching for connection to Backend and Database </p>
        <v-spacer></v-spacer>
        <v-progress-circular size="20" indeterminate color="light" class="ma-auto" v-if="isLoading"> </v-progress-circular>
      </template>
      <template v-else>
        <p> Connection successfull </p>
      </template>
    </v-row>

    <v-card class="ma-4 pa-0" rounded outlined>

      <v-alert v-if="isRedirected" type="info" color="warning" prominent icon="mdi-cloud-off-outline" class="text-left" dismissible>
        It seems you have no connection to the Database. <br>
        You will be redirected as soon as the connection is available again.
      </v-alert>
      <v-alert v-else-if="!isConnected" type="info" color="warning" icon="mdi-cloud-off-outline" class="text-left" dismissible>
        It seems you have no connection to the Database.
      </v-alert>
      <v-alert v-if="isConnected && !isLoading" type="success" color="success" icon="mdi-cloud" class="text-left" dismissible>
        Looking good.
      </v-alert>

      <v-row class="ma-0">
        <v-spacer></v-spacer>
        <BackendHelpIcon connected subtitle="Frontend" icon="mdi-monitor"></BackendHelpIcon><v-spacer></v-spacer>
        <BackendHelpConnection :connected="isAPIConnected"></BackendHelpConnection><v-spacer></v-spacer>
        <BackendHelpIcon :connected="isAPIConnected" subtitle="Backend (API)" icon="mdi-cloud"></BackendHelpIcon><v-spacer></v-spacer>
        <BackendHelpConnection :connected="isDatabaseAvailable" :unclear="!isAPIConnected"></BackendHelpConnection><v-spacer></v-spacer>
        <BackendHelpIcon :connected="isDatabaseAvailable" :unclear="!isAPIConnected" subtitle="Database" icon="mdi-database"></BackendHelpIcon><v-spacer></v-spacer>
      </v-row>
    </v-card>

    <v-card color="veryverylight" class="pa-6"  flat>
      <template v-if="!isConnected ">
        <v-row class="ma-0 pa-0">
          <h3 > Help? </h3>
          <v-spacer></v-spacer>
          <sup><v-btn text outlined class="ml-2" small @click="raiseIssue"> Report </v-btn></sup>
        </v-row>
        <p> Please wait a few minutes, while the frontend listens for the <b> {{!isAPIConnected ? 'SunPeek API' : 'Database'}} </b> ...
          <br> If there is no response soon, please check the following steps:
        </p>
        <ul>
          <li>Please check that Docker was set up correctly and is running.</li>
          <li>
            AdBlockers and VPN clients may interfere with connections to the backend.<br>
            Please try to disable them and reload this page.
          </li>
          <li>For more information, please visit the<a href="https://docs.sunpeek.org/#running-sunpeek" target="_blank"> SunPeek Documentation</a></li>
          <li> Add a new issue describing your problem in <a href="https://gitlab.com/sunpeek/web-ui/-/issues/new" target="_blank"> SunPeek GitLab </a></li>
        </ul>
        <br>
      </template>
      <template v-if="isConnected">

        <v-row class="ma-0 pa-0">
          <h3 > Further Steps? </h3>
          <v-spacer></v-spacer>
          <sup><v-btn class="ml-2" color="primary" small @click="checkBoth"> Check Again </v-btn></sup>
        </v-row>
        <p> It seems the connection to SunPeek is successfull. Congratulations </p>
        <ul>
          <li> To check all available collectors, go to <router-link to="collectors"> Collectors </router-link></li>
          <li> For checking available plants and creating new ones, go to <router-link to="plant_selection"> Plant Selection </router-link></li>
        </ul>
        <br>
      </template>
    </v-card>
  </v-container>
</template>

<script>

import {checkBackendOnline, checkDatabaseOnline} from "@/plugins/backend_api";
import BackendHelpIcon from "@/components/_BackendHelpIcon.vue";
import BackendHelpConnection from "@/components/_BackendHelpConnection.vue";

export default {
  name: 'BackendHelp',
  components: {BackendHelpConnection, BackendHelpIcon},
  data: () => ({
    isLoading: false,
    isAPIConnected: false,
    isDatabaseAvailable: false,
  }),
  computed: {
    redirection_path(){
      return this.$route.query.redirect
    },
    isRedirected() {
      return this.redirection_path !== undefined
    },
    isConnected(){
      let connected = this.isAPIConnected && this.isDatabaseAvailable
      if (connected){this.redirect_to_path()}
      return connected
    }
  },
  mounted() {
    this.checkBoth()
  },

  methods: {
    checkBoth(){
      this.checkBackendOnline()
      this.checkDatabaseOnline()
    },
    checkDatabaseOnline(){
      this.isLoading = true
      checkDatabaseOnline().then(() => {
        this.isDatabaseAvailable = true
      }).catch(() => {
        this.isDatabaseAvailable = false
        setTimeout(this.checkDatabaseOnline, 500)
      }).finally(() => {
        this.isLoading = false
      })
    },
    checkBackendOnline(){
      this.isLoading = true
      checkBackendOnline().then(() => {
        this.isAPIConnected = true
      }).catch(() => {
        this.isAPIConnected = false
        setTimeout(this.checkBackendOnline, 500)
      }).finally(() => {
        this.isLoading = false
      })
    },
    redirect_to_path(){
      if (this.isRedirected){
        this.$router.push(this.redirection_path)
      }
    },
    raiseIssue(){
      let url = "https://gitlab.com/sunpeek/web-ui/-/issues/new"
      window.open(url, '_blank');
    }
  },
}
</script>

<style scoped>

.main_backend_help{
  max-width: 800px;
}

.overlay_card{
  padding: 10px;
  width: min(100vw, max(400px, 10vw));
  justify-content: center;
  vertical-align: middle;
}

.row{
  padding: 20px;
}



</style>