<template>
  <v-container>
    <v-navigation-drawer
        color="verylight"
        permanent clipped app
        :mini-variant="isSmallVariant"
        :expand-on-hover="isSmallVariant"
        :key="isSmallVariant"
      >
        <v-list nav dense>
          <navigation-plant-header :plant_id="plant_id" @click.native="$router.push({name: 'plant_start'})" ></navigation-plant-header>
          <v-divider></v-divider>

          <navigation-item name="Overview" icon="mdi-apps" :route="{name: 'plant_start'}"></navigation-item>

          <navigation-group icon="mdi-cog" label="Configuration" :route="{name: 'config'}">
            <navigation-item name="Plant" icon="mdi-map-marker" small :route="{name: 'editPlant'}" dense></navigation-item>
            <navigation-item name="Arrays" icon="mdi-solar-panel" small :route="{name: 'arrayOverview'}" dense></navigation-item>
            <navigation-item name="Sensor Mapping" icon="mdi-view-list-outline" small :route="{name: 'sensorMapping'}" dense></navigation-item>
            <navigation-item name="Sensors" icon="mdi-thermometer" small  :route="{name: 'sensorOverview'}" dense></navigation-item>
          </navigation-group>

          <navigation-item name="Data Upload" icon="mdi-database-arrow-up" :route="{name: 'dataUpload'}"></navigation-item>
          <navigation-item name="Sensor Data" icon="mdi-chart-line-variant" :route="{name: 'validation'}"></navigation-item>
          <navigation-item name="Performance Check" icon="mdi-chart-areaspline" :route="{name: 'pcMethod'}"></navigation-item>

      </v-list>

    </v-navigation-drawer>

    <v-alert v-if="!isUpToDate" light type="warning" border="left" color="orange" class="ma-0">
      <v-row class="ma-0 pa-0"> Virtual sensor needs recalculation. <v-spacer></v-spacer>
        <v-btn color="orange" small class="mx-10" :to="{name: 'validation'}"> go to Sensor Page </v-btn>
      </v-row>
    </v-alert>

    <router-view v-on:back="$router.push({name: 'plant'})"> </router-view>

</v-container>
</template>

<script>
  
  import NavigationGroup from "@/components/NavigationGroup";
  import NavigationItem from "@/components/NavigationItem";
  import NavigationPlantHeader from "@/components/NavigationPlantHeader.vue";
  import * as backend from "@/plugins/backend_api";
  import {isConfigDone} from "@/pages/helper";

  export default {
    name: 'PlantMain',
    components: {NavigationPlantHeader, NavigationItem, NavigationGroup},
    props: {
      plant_id: {},
    },
    data: () => ({
      isUpToDate: true,
    }),
    mounted() {
      this.getPlant()
    },
    watch: {
      $route() {
        this.getPlant()
      },
    },
    methods: {
      async getPlant() {
        if (this.plant_id === undefined) {
          return
        }
        return await backend.get_plant(this.plant_id).then(res => {
          this.plant_data = res.data
          this.isUpToDate = this.plant_data.virtuals_calculation_uptodate
          this.rerouteIfNotConfigured()
        }).catch(() => {
          this.error_message = "Plant not found! Please check your URL"
        })
      },
      rerouteIfNotConfigured(){
        if (!isConfigDone(this.plant_data)){
          this.$router.push({name: "setup_editPlant", params: {"error_message": "Please finish configuring the plant"}})
        }
      },
    },
    computed: {
      isSmallVariant(){
        return this.$vuetify.breakpoint.mdAndDown
      },
    }
  }
  
</script>

<style scoped>

</style>