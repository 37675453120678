<template>
  <v-container>
  <h1> Collectors </h1>
    <CollectorListComponent @input="editCollector"></CollectorListComponent>
  </v-container>
</template>
<script>

import CollectorListComponent from "@/components/CollectorListComponent.vue";
  export default {
    name: 'CollectorList',
    components: {CollectorListComponent},
    methods: {
      editCollector(collector){
        this.$router.push({path: this.$router.currentRoute.fullPath + "/" + collector.id, params: {collector_id: collector.id}})
      },
    },
  }
</script>

<style scoped>

</style>