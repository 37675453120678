<template>
    <v-container class="pa-0 ma-0">
      <v-form ref="form" v-model="isValid">

        <config-section name="Ambient" color="white" bordercolor="lightgray">
          <SensorMappingAutocomplete
              :required_slots="REQUIRED_PLANT_SLOTS"
              :sensor_names="sensor_names"
              :sensor_slot="getSlot('te_amb')"
              :component="plant"
              @save="saveChange"
              :disabled="isSaving"
              :raw_sensors="raw_sensors"
          ></SensorMappingAutocomplete>
          <SensorMappingAutocomplete
              :required_slots="REQUIRED_PLANT_SLOTS"
              :sensor_names="sensor_names"
              :sensor_slot="getSlot('ve_wind')"
              :component="plant"
              @save="saveChange"
              :disabled="isSaving"
              v-if="!hide_optional"
              :raw_sensors="raw_sensors"
          ></SensorMappingAutocomplete>
        </config-section>


        <config-section name="Thermal Power" color="white" bordercolor="lightgray">
          <SensorMappingAutocomplete
              :required_slots="REQUIRED_PLANT_SLOTS"
              :sensor_names="sensor_names"
              :sensor_slot="getSlot('tp')"
              :component="plant"
              @save="saveChange"
              :disabled="isSaving"
              :raw_sensors="raw_sensors"
          ></SensorMappingAutocomplete>

          <template>
            <v-row>
              <v-subheader>
                <i>Alternative calculation<InfoTooltip small>
                  The thermal power can also be calculated with SunPeek <br>
                  You need to provide the temperature and volume flow fields below. <br>
                  In addition, the fluid property must be set at the "Arrays" page <br>
                </InfoTooltip>: </i>
              </v-subheader>
              <v-spacer></v-spacer>
            </v-row>

            <SensorMappingAutocomplete
                :required_slots="REQUIRED_PLANT_SLOTS"
                :sensor_names="sensor_names"
                :sensor_slot="getSlot('te_in')"
                :component="plant"
                @save="saveChange"
                :disabled="isSaving"
                :raw_sensors="raw_sensors"
            ></SensorMappingAutocomplete>
            <SensorMappingAutocomplete
                :required_slots="REQUIRED_PLANT_SLOTS"
                :sensor_names="sensor_names"
                :sensor_slot="getSlot('te_out')"
                :component="plant"
                @save="saveChange"
                :disabled="isSaving"
                :raw_sensors="raw_sensors"
            ></SensorMappingAutocomplete>
            <SensorMappingAutocomplete
                :required_slots="REQUIRED_PLANT_SLOTS"
                :sensor_names="sensor_names"
                :sensor_slot="getSlot('vf')"
                :component="plant"
                @save="saveChange"
                :disabled="isSaving"
                :raw_sensors="raw_sensors"
            ></SensorMappingAutocomplete>
            <SensorMappingAutocomplete
                :required_slots="REQUIRED_PLANT_SLOTS"
                :sensor_names="sensor_names"
                :sensor_slot="getSlot('mf')"
                :component="plant"
                @save="saveChange"
                :disabled="isSaving"
                :raw_sensors="raw_sensors"
            ></SensorMappingAutocomplete>
          </template>
        </config-section>

        <config-section name="Others" color="white" bordercolor="lightgray" v-if="!hide_optional">
          <SensorMappingAutocomplete
              :required_slots="REQUIRED_PLANT_SLOTS"
              :sensor_names="sensor_names"
              :sensor_slot="getSlot('rh_amb')"
              :component="plant"
              @save="saveChange"
              :disabled="isSaving"
              :raw_sensors="raw_sensors"
          ></SensorMappingAutocomplete>

          <SensorMappingAutocomplete
              :required_slots="REQUIRED_PLANT_SLOTS"
              :sensor_names="sensor_names"
              :sensor_slot="getSlot('te_dew_amb')"
              :component="plant"
              @save="saveChange"
              :disabled="isSaving"
              :raw_sensors="raw_sensors"
          ></SensorMappingAutocomplete>

          <SensorMappingAutocomplete
              :required_slots="REQUIRED_PLANT_SLOTS"
              :sensor_names="sensor_names"
              :sensor_slot="getSlot('pr_amb')"
              :component="plant"
              @save="saveChange"
              :disabled="isSaving"
              :raw_sensors="raw_sensors"
          ></SensorMappingAutocomplete>
        </config-section>
      </v-form>

    </v-container>
</template>

<script>
import ConfigSection from "@/components/ConfigSection.vue";
import * as backend from "@/plugins/backend_api";
import SensorMappingAutocomplete from "@/components/SensorMapping__Autocomplete.vue";
import {REQUIRED_PLANT_SLOTS} from "@/pages/helper";
import InfoTooltip from "@/components/_InfoTooltip.vue";

export default {
  name: "SensorMapping_Plant",
  components: {InfoTooltip, SensorMappingAutocomplete, ConfigSection},
  props: {
    plant: {type: Object},
    sensor_names: {type: Array},
    raw_sensors: {type: Array},
    hide_optional: Boolean,
  },
  mounted() {
    this.getPlantSlots()
  },
  watch: {
    isValid(){
      this.$emit("isValid", this.isValid)
    },
    plant(){
      this.$refs.form.validate()
      let inconsistent = this.isValid !== this.plant.isValid
      if (inconsistent) {this.$emit("isValid", this.isValid)}
    }
  },
  data: () => ({
    isSaving: false,
    slots: {},
    isValid: false,
    REQUIRED_PLANT_SLOTS: REQUIRED_PLANT_SLOTS,
  }),
  methods: {
    getPlantSlots(){
      backend.get_channel_names("plant").then(res => {
        this.slots = res.data
      }).catch(res => {
        this.$emit("error", res)
      })
    },
    getSlot(slot_name){
      if (Object.keys(this.slots).length === 0 ){return {}}
      return this.slots.filter(val => val.name === slot_name)[0]
    },
    saveChange(slot_name, slot_value){
      let mapping = {
        "sensor_map": {[slot_name]: slot_value},
        "id": this.plant["id"]
      }

      this.isSaving = true
      return backend.update_plant(mapping).catch(res => {
        this.$emit("error", res)
      }).finally(() => {
        this.$emit("save")
        this.isSaving = false
      })
    },
  },
}
</script>

<style scoped>

</style>