<template>
  <div class="pa-0 ma-0">

      <v-card color="transparent" flat dark class="px-4">
        <v-row dense class="py-2 d-flex justify-start" style="row-gap: 10px;">
          <v-text-field :loading="isLoading" class="px-2" :dense="dense" style="max-width: 100px;" outlined hide-details :disabled="!editSettings || isLoading" label="Separator" v-model="csv_separator" @change="saveCsvSettings"></v-text-field>
          <v-text-field :loading="isLoading" class="px-2" :dense="dense" style="max-width: 90px;" outlined hide-details :disabled="!editSettings || isLoading" label="Decimal" v-model="csv_decimal" @change="saveCsvSettings" ></v-text-field>
          <v-text-field :loading="isLoading" class="pl-2" :dense="dense" style="max-width: 80px;" outlined hide-details :disabled="!editSettings || isLoading" label="Index" v-model="index_col" @change="saveCsvSettings" ></v-text-field>
          <InfoTooltip :color="editSettings ? 'white' : 'gray'"> Number of the datetime-column which serves as index (typically the first column 0). </InfoTooltip>
          <v-text-field :loading="isLoading" class="pl-2" :dense="dense" style="max-width: 120px; " outlined hide-details :disabled="!editSettings || isLoading" label="Encoding" v-model="csv_encoding" @change="saveCsvSettings"></v-text-field>
          <InfoTooltip @click="showEncodingOptions()" :color="editSettings ? 'white' : 'gray'"> Can be used to specify encoding in case characters are not understood. Click on the info symbol to get to a list of options. </InfoTooltip>
          <v-autocomplete :rules="[required]" :loading="isLoading" class="pl-2" :dense="dense" style="max-width: 270px; " outlined hide-details :disabled="!editSettings || isLoading" label="Timezone / UTC offset" v-model="timezone" :items="timezone_options" @change="saveCsvSettings()"></v-autocomplete>
          <InfoTooltip :color="editSettings ? 'white' : 'gray'"> Timezone of datetime-column (e.g. "ETC/GMT+1" or "Europe/Vienna"). The default is based on your browsers location. </InfoTooltip>
          <br>
          <v-select :loading="isLoading" class="pl-2" :dense="dense" style="max-width: 190px;" outlined hide-details :disabled="!editSettings || isLoading" label="Date-Order" v-model="datetime_template" :items="datetime_template_options" @change="saveCsvSettings"></v-select>
          <InfoTooltip :color="editSettings ? 'white' : 'gray'"> Order of day/month/year values apearing in your datetime-column. The default is "day_month_year". </InfoTooltip>
          <v-text-field :loading="isLoading" class="pl-2" :dense="dense" style="max-width: 190px;" outlined hide-details :disabled="!editSettings" label="Date-Format" v-model="datetime_format" @change="saveCsvSettings"></v-text-field>
          <InfoTooltip @click="showDatetimeFormats()" :color="editSettings ? 'white' : 'gray'"> Can be used to explicitly set the Date format. Overrides "Date-Order", while Time is inferred automatically. Click on the info symbol to get to a list of format options. </InfoTooltip>
        </v-row>
      </v-card>
    </div>


</template>

<script>
// import InfoTooltip from "@/components/_InfoTooltip.vue";
import * as backend from "@/plugins/backend_api";
import InfoTooltip from "@/components/_InfoTooltip.vue";

export default {
  name: "DataUpload_SettingToolbar",
  components: {InfoTooltip},
  // components: {InfoTooltip},
  props: {
    value: Object,
    plant_id: {},
    reduced: Boolean,
    dense: Boolean,
  },
  data: () => ({
    isSettingsOpen: false,

    isLoading: false,
    editSettings: true,

    required: value => !!value || value === 0 || 'Required.',

    csv_settings: {},
    result_settings: {},

    csv_separator: null,
    csv_decimal: null,
    csv_encoding: null,
    timezone: null,
    datetime_template: null,
    datetime_format: null,
    index_col: null,

    datetime_template_options: ["year_month_day", "day_month_year", "month_day_year"],
    timezone_options: [],
  }),
  mounted() {
    this.getCsvSettings()
    this.getTimezones()
  },
  methods: {

    getSettingValue(key, suffix=""){
      let used = !this.result_settings[key] ? '' : ' (' + this.result_settings[key] + suffix + ')'
      let saved = this.csv_settings[key] === null ? 'AUTO' + used : this.csv_settings[key] + suffix
      return saved
    },

    getCsvSettings(){
      this.isLoading = true
      return backend.get_plant(this.plant_id).then(res => {
        this.csv_settings = res.data.data_upload_defaults
        this.parseSettings()
        this.updateSettings()
        this.$emit('input', this.csv_settings)
      }).catch(res => {
        this.$emit("error", res)
      }).finally(() =>{
        this.isLoading = false
      })
    },
    parseSettings(){
      this.csv_separator= this._parse_one_setting("csv_separator", ";")
      this.csv_decimal = this._parse_one_setting("csv_decimal", ".")
      this.csv_encoding = this._parse_one_setting("csv_encoding", "utf8")
      this.timezone = this._parse_one_setting("timezone", "UTC offset included in data")
      this.datetime_template = this._parse_one_setting("datetime_template", "year_month_day")
      this.datetime_format = this._parse_one_setting("datetime_format", null)
      this.index_col = this._parse_one_setting("index_col", 0)
    },
    _parse_one_setting(key, default_value){
      return !this.csv_settings || !(key in this.csv_settings) || this.csv_settings[key] === null ? default_value : this.csv_settings[key]
    },
    saveCsvSettings(){
      if (this.timezone === null) {return}

      this.updateSettings()
      let csv_settings = {"data_upload_defaults": this.csv_settings, "id": this.plant_id}
      this.isLoading = true

      return backend.update_plant(csv_settings).catch(res => {
        this.$emit("error", res)
      }).finally(this.getCsvSettings)
    },


    getTimezones(){
      backend.getTimezoneOptions().then(res => {
        this.timezone_options = res.data
      }).catch(res => {
        this.$emit("error", res)
      })
    },
    replaceEmptyString(value){
      return value === "" ? null : value
    },
    updateSettings () {
      this.csv_settings = {
        csv_decimal: this.replaceEmptyString(this.csv_decimal),
        csv_encoding: this.replaceEmptyString(this.csv_encoding),
        csv_separator: this.replaceEmptyString(this.csv_separator),
        timezone: this.replaceEmptyString(this.timezone),
        datetime_template: this.replaceEmptyString(this.datetime_template),
        datetime_format: this.replaceEmptyString(this.datetime_format),
        index_col: this.replaceEmptyString(this.index_col),
      }
    },
    saveSettings(){
      this.updateSettings()
      this.saveCsvSettings(this.csv_settings)
    },

    showDatetimeFormats(){
      let url = "https://pandas.pydata.org/docs/reference/api/pandas.Period.strftime.html"
      window.open(url, '_blank').focus();
    },
    showEncodingOptions(){
      let url = "https://docs.python.org/3/library/codecs.html#standard-encodings"
      window.open(url, '_blank').focus();
    },
  },
}
</script>

<style scoped>

::v-deep .v-toolbar__content{
  align-items: inherit !important;
  flex-wrap: wrap !important;
}

</style>