<template>
  <Plotly
      :data="traces"
      :layout="layout"
      :display-mode-bar="false"
      class="timeseries_plot"
      doubleClick="autosize"
      watchShallow
      @selecting="select"
      @selected="select"
      @relayout="relayout_triggered"
  ></Plotly>
</template>


<script>
import { Plotly } from 'vue-plotly'

export default {
  name: "pc-timeseries",
  components: {
    Plotly
  },
  emits: ['input:value'],
  props: {
    value: {default: []},
    estimated: [],
    measured: [],
    timestamps: [],
    useSafety: {default: true},
    f_total: {default: 100},
    tool: {default: "select", type: String},
    reset_counter: {default: 0, type: Number},
    UTC_OFFSET: {default: "", type: String}
  },
  data: () => ({
    layout: {
      paper_bgcolor: 'rgba(0,0,0,0)',
      title: {text: "<b>Timeseries</b>", font: {size: 14}},
      margin: {l: 50, b:90, r: 50, t:50, pad:5},
      legend: {
        "orientation": "h",
        "x": 1.02,
        "xanchor": "center",
        "y": 1.0,
        "yanchor": "bottom"
      },
      xaxis: {
        type: 'date',
        linecolor: 'grey',
        linewidth: 1,
        hoverformat: "%Y-%m-%d <br> %H:%M",
        mirror: true,
      },
      yaxis: {
        title: {text: 'Difference to estimated power [%]', font: {size: 11}},
        rangemode: 'tozero',
        linecolor: 'gray',
        linewidth: 1,
        mirror: true
      },
    },
  }),
  mounted(){
    this.reset_range()
    this.plot_background()
  },
  watch: {
    tool(){
      let layout = this.layout
      layout["dragmode"] = this.tool
      this.layout = {...layout}
    },
    measured(){
      this.plot_background()
    },
    reset_counter(){
      this.reset_range()
    },
    useSafety(){
      this.plot_background()
    },
    f_total(){
      this.plot_background()
    },
    UTC_OFFSET(){
      this.plot_background()
    }
  },
  methods: {
    relayout_triggered(event) {
      if ("xaxis.autorange" in event) {
        this.reset_range()
      }
    },
    select(e){
      let selectedpoints = !!e && e.points.length > 0 ? e.points[0].data.selectedpoints : []
      this.selected_points = selectedpoints
    },
    reset_range(){
      let layout = this.layout
      layout["xaxis"]["autorange"] = true
      layout["yaxis"]["autorange"] = false
      layout["yaxis"]["range"] = [0, 180]
      this.layout = {...layout}
    },
    plot_background() {
      let annotations = [
          {
            x: 0,
            y: 180 - 7,
            xref: 'paper',
            yref: 'y',
            text: 'within safety',
            font: {color: '#6a9364'},
            showarrow: false,
          },
          {
            x: 1,
            y: 7,
            xref: 'paper',
            yref: 'y',
            text: 'outside safety',
            font: {color: '#e38080'},
            showarrow: false,
          }]

      let shapes = [
          {
            type: 'rect',
            xref: 'paper',
            yref: 'y',
            x0: 0,
            y0: -100000,
            x1: 1,
            y1: this.useSafety ? 100 : this.f_total,
            fillcolor: '#f50000',
            opacity: 0.05,
            line: {width: 0},
            layer: 'below',
          },
          {
            type: 'rect',
            xref: 'paper',
            yref: 'y',
            x0: 0,
            y0: this.useSafety ? 100 : this.f_total,
            x1: 1,
            y1: 100000,
            fillcolor: '#19a900',
            opacity: 0.05,
            line: {width: 0},
            layer: 'below',
          },
          {
            type: 'line',
            xref: 'paper',
            yref: 'y',
            x0: 0,
            x1: 1,
            y0: this.useSafety ? 100 : this.f_total,
            y1: this.useSafety ? 100 : this.f_total,
            line: {width: 0.5, color: "#19a900"},
            opacity: 0.3,
          },
          {
            type: 'line',
            xref: 'paper',
            yref: 'y',
            x0: 0,
            x1: 1,
            y0: 100,
            y1: 100,
            line: {width: 0.3, color: "black"},
            opacity: 1.0,
          },
        ]

      if (! this.measured){
        annotations = [{
          xref: 'paper', x: 0.5,
          yref: 'paper', y: 1,
          text: 'no measured data available!',
          font: {color: '#000000', size: 15}, opacity: 0.5,
          showarrow: false,
        }]
        shapes = [{
          type: 'rect',
          xref: 'paper',  y0: -0.02, y1: 1.02,
          yref: 'paper',  x0: -0.01, x1: 1.01,
          fillcolor: '#565656', opacity: 0.5,
          line: {width: 0}, layer: 'above',
        },]
      }

      let layout = this.layout
      layout["annotations"] = annotations
      layout["shapes"] = shapes
      layout["xaxis"]["hoverformat"] = "%Y-%m-%d <br> %H:%M " + this.UTC_OFFSET
      this.layout = {...layout}
    },
  },
  computed: {
    selected_points: {
      get() {return this.value},
      set(value) { this.$emit('input', value);},
    },
    traces() {
      if (this.estimated === null){return []}

      let factor = this.useSafety ? this.f_total/100 : 1.00
      let estimated = this.estimated.magnitude
      let measured = !this.measured ? estimated.map(x => x*factor) : this.measured.magnitude

      let opacity = estimated.map((item, i) => this.selected_points.includes(i) ? 0.9 : 0.2)
      if (this.selected_points.length === 0){
        opacity = 0.9
      }

      let percent_above_estimated = estimated.map((value, i) => measured[i] / (value * factor) * 100 );

      let data = [{
        x: this.timestamps,
        y: percent_above_estimated,
        type:"scatter",
        name: 'measured power',
        mode: 'markers',
        marker: {"opacity": opacity},
      }]
      return data
    },
  },
}
</script>

<style scoped>

g .shapelayer{
  shape-rendering: crispedges;
  stroke-width: 1px;
  opacity: 0.3;
}

</style>