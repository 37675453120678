import { render, staticRenderFns } from "./Config_02_Arrays__Edit.vue?vue&type=template&id=b365461a&scoped=true&"
import script from "./Config_02_Arrays__Edit.vue?vue&type=script&lang=js&"
export * from "./Config_02_Arrays__Edit.vue?vue&type=script&lang=js&"
import style0 from "./Config_02_Arrays__Edit.vue?vue&type=style&index=0&id=b365461a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b365461a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VChip,VDialog,VForm,VIcon,VRow,VSpacer,VSubheader,VTextField})
