<template>
  <v-alert v-if="message" v-model="isActive" type="warning" color="warning" prominent onclose="clear_error_message" dismissible>
    <h3 class="text-h5" > Error {{error_status}}
      <sup><v-btn text outlined class="ml-2" x-small @click="reportIssue"> Report this issue </v-btn></sup>
    </h3>
    <p v-if="error_msg !== ''"> <b> Message: </b> <span class="font-italic" >{{ error_msg }} </span></p>
    <p>
      <b>Detail:</b>
      <span v-if="error_detail.length < 300"> {{error_detail}} </span>
      <v-btn v-else text x-small @click="showModal = true" class="font-italic"> show details </v-btn>
    </p>

    <v-dialog v-model="showModal" width="calc(max(600px, min(700px, 80%)))">
      <v-card>
        <v-card-title class="warning text-h5 white--text">  Error {{error_status}}  </v-card-title>
        <v-card-subtitle class="warning white--text "> {{ error_msg }}</v-card-subtitle>
        <v-card-text style="white-space: pre-line; ">
          <b> Detailed Response: </b><br>
          {{ error_detail }}
        </v-card-text>
      </v-card>
    </v-dialog>


  </v-alert>
</template>

<script>

import {exportDump} from "@/plugins/backend_api";

export default {
  props: {
    error_message: {default: ""},
  },
  name: "TopLineAlert",
  data: () => ({
    isActive: true,
    message: "",
    error_status: "",
    error_detail: "",
    error_msg: "",

    showModal: false,
  }),
  watch: {
    error_message: function() {
      this.isActive = true
      this.message = this.error_message
      this.handleErrorMessage()
    }
  },
  methods: {
    handleErrorMessage(){
      let isString = (typeof this.message === 'string' || this.message instanceof String)
      if (isString) {
        this.error_status = ""
        this.error_detail = this.message
        this.error_msg = ""
      } else {

        // assuming we have a Response type error message (catch Promise)
        let response = this.message
        this.error_status = response.response.status

        if (this.error_status === 0){
          // Network Error
          this.error_msg = response.message
          this.error_detail =
              "If a Network error occurred, this most likely means that the Database is offline or a critical error occurred within the API. " +
              "Please check the log in Docker for more information.  \n" +
              "If you need help, please raise an issue by clicking the 'Report this issue' button above. "
        } else if (this.error_status === 409) {
          this.error_msg = response.error
          this.error_detail = response.response.data.message
        } else if (this.error_status === 500) {
          this.error_msg = response.message
          this.error_detail = response.response.data ? response.response.data.detail :
              "A critical error occurred within the Backend API. " +
              "Please check the log in Docker for more information. \n " +
              "If you need help, please raise an issue by clicking the 'Report this issue' button above."
        } else {
          // Custom error from Backend API
          this.error_detail = response.response.data ? response.response.data.detail : "Unknown Error"
          this.error_msg = response.response.data ? response.response.data.message : "Unknown Error. Please raise an issue by clicking the 'Report issue' button above."
        }
      }
    },
    clear_error_message(){
      this.message = ""
    },
    reportIssue(){
      exportDump()
      let body = "Thank you for helping us to improve SunPeek. Please describe below how we can help you. We will get back to you as soon as possible. The content of your mail will be handled confidentially by the SunPeek maintainers."
      window.open('mailto:support@sunpeek.org?subject=subject&body='+body,"_self");
      },
  },
}
</script>

<style scoped>

p {
  margin-bottom: 0;
}
</style>