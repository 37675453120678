<template>
  <config-container :error_message="error_message">
    <h1> Sensor Mapping </h1>
    <p> Configure which sensor belongs to which part of the plant.</p>

    <v-container class="mapSensorsContainer pt-0 mt-0 pb-0">

      <v-row class="px-0 py-1 ma-auto mt-1" dense>
        <span
            v-if="!allValid"
            style="font-size: 12px" class="px-2 lightwarning--text"
        > {{components.length - nrValid }} component not configured yet </span>
        <span
            v-if="allValid"
            style="font-size: 12px" class="px-2 ternary--text"
        > all components configured </span>

        <v-spacer></v-spacer>
        <v-btn-toggle background-color="primary" class="mx-3" >
          <v-btn x-small text @click="hide_optional = !hide_optional">
            <v-icon x-small class="pr-1"> {{hide_optional ? 'mdi-eye-off' : 'mdi-eye'}}</v-icon>
            hide optional slots
          </v-btn>
        </v-btn-toggle>
      </v-row>

      <v-card class="main_row">
        <v-list dense color="ternary" dark class="component_list" max-width="200" >
          <v-list-item-group mandatory active-class="is_selected" v-model="selected_component_name">

            <template v-for="component_type in ['Plant', 'Array']">
              <v-subheader :key="component_type"> {{ component_type }}</v-subheader>
              <v-list-item
                  v-for="component in components.filter(val => val.type === component_type)"
                  :key="component_type + component.name" :value="component.name"
              >

                <v-list-item-content> {{component.name}}
                </v-list-item-content>
                <v-icon right small v-if="component.isValid"> mdi-check  </v-icon>
                <v-icon right small v-else title="Mapping incomplete" color="lightwarning" > mdi-alert-circle </v-icon>
              </v-list-item>
            </template>
          </v-list-item-group>

        </v-list>


        <!--  Sensor Mapping -->
        <v-card tile color="white" light style="flex: 0 1 100%; overflow: auto; height: calc(100vh - 370px); min-height: 200px; padding: 10px;">
          <v-subheader v-if="selected_subcomponent === undefined"> Please select a component </v-subheader>
          <SensorMapping
              v-for="component in components"
              v-show="component.name === selected_component_name"
              :key="component.name"
              :component="component"
              :raw_sensors="raw_sensors"
              :sensor_names="sensor_names"
              :hide_optional="hide_optional"
              v-on:save="updateAll"
              v-on:error="res => error_message = res"
              v-on:isValid="res => setValid(component, res)"
              v-on:switch="res => selectNextComponent(res)"
          ></SensorMapping>
        </v-card>

      </v-card>
    </v-container>

    <v-row class="pa-2 ma-0">
      <v-spacer></v-spacer>
      <v-btn-toggle dense rounded :max="0" multiple :value="[]">
        <v-btn x-small color="secondary" dark @click="selectNextComponent(-1)" :disabled="current_index ===0"> Prev</v-btn>
        <v-btn x-small color="secondary" dark @click="selectNextComponent(+1)" :disabled="current_index === this.components.length -1"> Next</v-btn>
      </v-btn-toggle>
    </v-row>


    <br>
    <v-row>
      <v-btn color="white" light outlined @click="back()"> Back </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="white" @click="next()" outlined :disabled="!allValid"> Next </v-btn>
    </v-row>
  </config-container>
</template>

<script>
import * as backend from "@/plugins/backend_api";
import configContainer from "@/components/ConfigContainer";
import SensorMapping from "@/components/SensorMapping_Wrapper.vue";
import {cloneDeep} from "lodash";




export default {
  name: 'MapSensors',
  components: {SensorMapping, configContainer},
  props: {
    plant_id: null,
  },
  data: () => ({
    // data from API
    plant_data: {},
    sensor_names: [],
    raw_sensors: [],

    // Selections from user
    components: [],

    selected_component_name: null,

    // helper
    hide_optional: false,
    error_message: "",
  }),
  mounted() {
    this.updateAll()
  },
  methods:{
    // Getter
    // ---------------
    updateAll() {
      this.getPlant().then(() => {
        this.parseSensors()
      })
    },
    getPlant() {
      return backend.get_plant(this.plant_id).then(res => {
        this.plant_data = res.data
        this.parseComponents()
      }).catch(res => {
        this.error_message = res
      })
    },
    parseComponents(){
      //parse Plant
      let plant_data = this.plant_data
      plant_data.name = this.plant_data.name
      plant_data.type = "Plant"

      // parse array
      let array_data = this.plant_data["arrays"]
      array_data.forEach(val => {val.type = "Array"})

      //combine
      this.components = [plant_data, ...array_data]
    },
    parseSensors() {
      this.raw_sensors = this.plant_data.raw_sensors
      let real_sensors = this.raw_sensors.filter(item => !item.is_virtual)
      this.sensor_names = real_sensors.map(item => item.raw_name).sort()
    },
    setValid(component, isValid){
      component.isValid = isValid
      this.components = cloneDeep(this.components) // clone deep required so Vue updates all components
    },
    selectNextComponent(increment=1){
      let max_index = this.components.length - 1
      let new_index = Math.max(0, Math.min(this.current_index + increment, max_index))
      this.selected_component_name = this.components[new_index].name
    },
    next() {
      let name = (this.$router.currentRoute.path.includes("/plant/")) ? "sensorOverview" : "setup_sensorOverview"
      this.$router.push({name: name, params: {"plant_id": this.plant_id}})
    },
    back(){
      let name = (this.$router.currentRoute.path.includes("/plant/")) ? "arrayOverview" : "setup_newSensors"
      this.$router.push({name: name, params: {"plant_id": this.plant_id}})
    },
  },
  computed: {
    current_index(){
      return this.components.findIndex(val => val.name === this.selected_component_name)
    },
    selected_subcomponent(){
      return this.components.filter(item => item.name === this.selected_component_name)[0]
    },
    nrValid(){
      return this.components.reduce((partialSum, val) => partialSum + val.isValid, 0)
    },
    allValid(){
      return this.components.every(val => val.isValid);
    }
  }
}
</script>

<style scoped>

.mapSensorsContainer{
  min-height: calc(min(60vh - 100px, 400px));
}

.main_row{
  display: flex;
  flex-wrap: inherit;
}

.main_row .component_list{
  flex: 1 0 auto;
  min-width: 120px;
  max-width: 120px;
}


.is_selected{
  border-left: 3px solid white;
  color: white;
}


</style>