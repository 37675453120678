<template>
  <v-menu
      eager
      v-model="showDataUploadSettings"
      offset-y
      nudge-bottom="-5px"
      nudge-left="-60px"
      top left
      :close-on-click="false"
      :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }" >
      <v-btn class="mx-2" height="55px" color="darkgray" outlined v-bind="attrs" v-on="on" > <v-icon color="white" > mdi-cog</v-icon></v-btn>
    </template>

    <v-card color="secondary_light" outlined  dark class="pa-5">
      <v-row class="pb-4 mx-2 ma-0">
        <h4> Upload settings</h4>
        <v-spacer></v-spacer>
        <v-btn tile x-small color="white" light @click="showDataUploadSettings = false"><v-icon x-small> mdi-close</v-icon></v-btn>
      </v-row>

      <DataUpload_SettingToolbar :dense="dense" v-model="value_internal" :plant_id="plant_id" @input="onInput" @error="onError"></DataUpload_SettingToolbar>
    </v-card>
  </v-menu>
</template>

<script>

import DataUpload_SettingToolbar from "@/components/DataUpload_SettingToolbar.vue";

export default {
  name: "DataUpload_Settings",
  components: {DataUpload_SettingToolbar},
  props: {
    value: Object,
    plant_id: {},
    show: Boolean,
    dense: Boolean,
  },
  data: () => ({
    value_internal: {},
    showDataUploadSettings: false,
  }),
  mounted() {
    this.update_settings()
  },
  watch: {
    value() {
      this.update_settings()
    },
    show(){
      this.showDataUploadSettings = !this.showDataUploadSettings
    },
  },
  methods:{
    update_settings(){
      this.value_internal = this.value
    },
    onInput(){
      this.$emit('input', this.value_internal)
    },
    onError(e){
      this.$emit('error', e)
    }
  }
}

</script>