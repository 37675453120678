import Vue from 'vue'
import VueRouter from "vue-router"
Vue.use(VueRouter);
import {checkBackendOnline} from "@/plugins/backend_api";


// import pages
import LandingPage from "@/pages/_LandingPage";
import BackendHelp from "@/pages/_BackendHelp";
import LoadDemo from "@/pages/_LoadDemo"

import ConfigMain from "@/pages/Config";
import ConfigPlant from "@/pages/Config_01_Plant.vue";
import ArraysOverview from "@/pages/Config_02_Arrays.vue";
import ArrayDetails from "@/pages/Config_02_Arrays__Edit.vue";
import SensorConfiguration from "@/pages/Config_05_Sensors.vue";
import CreateSensors from "@/pages/Config_05_Sensors__NewSensors.vue";
import MapSensors from "@/pages/Config_04_SensorMapping.vue";
import EditSensor from "@/pages/Config_05_Sensors__Details.vue";

import PlantSelection from "@/pages/PlantSelection.vue";
import PlantMain from "@/pages/Plant.vue";
import Plant_PCmethod from "@/pages/Plant_05_PCmethod.vue";
import PassThrough from "@/pages/PassThrough.vue";
import CollectorList from "@/pages/Collector_OverviewPage.vue";
import Collector_Edit from "@/pages/Collector_EditPage.vue";

import Plant_SensorValidation from "@/pages/Plant_04_SensorData.vue";
import Plant_DataUploadHistory from "@/pages/Plant_03_DataUpload.vue";
import Plant_Success from "@/pages/Plant_00_Success.vue";
import Plant_Overview from "@/pages/Plant_01_Overview.vue";
import InitialUpload from "@/pages/Config_03_DataFormat.vue";


let router = new VueRouter({
    mode: 'history',
    routes: [

        {
            path: "/start",
            name: "landing_page",
            props: true,
            component: LandingPage,
            meta: {onBackendOffline: true}
        },
        {
            path: "/connection",
            name: "connection",
            props: true,
            component: BackendHelp,
            meta: {onBackendOffline: true}
        },
        {
            path: "/load_demo",
            name: "load_demo",
            props: true,
            component: LoadDemo,
            meta: {onBackendOffline: true}
        },
        {
            path: "/collectors",
            name: "collectors",
            props: true,
            component: CollectorList,
        },
        {
            path: "/collectors/:id?",
            alias: "/collectors/new",
            name: "edit_collector",
            props: true,
            component: Collector_Edit,
        },


        // CONFIG
        {
            path: "/setup",
            component: ConfigMain,
            props: true,
            children: [
                {
                    path: "",
                    name: "setup_newPlant",
                    props: true,
                    component: ConfigPlant,
                },
                {
                    path: ":plant_id",
                    name: "setup_editPlant",
                    props: true,
                    component: ConfigPlant,
                },
                {
                    path: ":plant_id/arrays",
                    name: "setup_arrayOverview",
                    props: true,
                    component: ArraysOverview,
                },
                {
                    path: ":plant_id/arrays/new",
                    name: "setup_newArray",
                    props: true,
                    component: ArrayDetails,
                },
                {
                    path: ":plant_id/arrays/:array_id",
                    name: "setup_editArray",
                    props: true,
                    component: ArrayDetails,
                },
                {
                    path: ":plant_id/create_sensors",
                    name: "setup_newSensors",
                    props: true,
                    component: InitialUpload,
                },
                {
                    path: ":plant_id/map_sensors",
                    name: "setup_sensorMapping",
                    props: true,
                    component: MapSensors,
                },
                {
                    path: ":plant_id/sensors",
                    name: "setup_sensorOverview",
                    props: true,
                    component: SensorConfiguration,
                },
                {
                    path: ":plant_id/sensors/:sensor_id",
                    name: "setup_editSensor",
                    props: true,
                    component: EditSensor,
                },
            ],
        },

        // MAIN
        {
            path: "/plant_selection",
            name: "plant_selection",
            props: true,
            component: PlantSelection,
        },
        {
            path: "/plant/:plant_id",
            name: "plant",
            props: true,
            component: PlantMain,
            redirect: {name: "plant_start"},

            children: [
                {
                    path: "success",
                    name: "plant_success",
                    props: true,
                    component: Plant_Success,
                },
                {
                    path: "start",
                    name: "plant_start",
                    props: true,
                    component: Plant_Overview,
                },
                {
                path: "config",
                name: "config",
                props: true,
                redirect: {name: "editPlant"},
                component: PassThrough,
                children: [
                    // CONFIGURATION
                    {
                        path: "plant",
                        name: "editPlant",
                        props: true,
                        component: ConfigPlant,
                    },
                    {
                        path: "arrays",
                        name: "arrayOverview",
                        props: true,
                        component: ArraysOverview,
                    },
                        {
                            path: "arrays/new",
                            name: "newArray",
                            props: true,
                            component: ArrayDetails,
                        },
                        {
                            path: "arrays/:array_id",
                            name: "editArray",
                            props: true,
                            component: ArrayDetails,
                        },
                    {
                        path: "sensors",
                        name: "sensorOverview",
                        props: true,
                        component: SensorConfiguration,
                    },
                    {
                        path: "sensors/:sensor_id",
                        name: "editSensor",
                        props: true,
                        component: EditSensor,
                    },
                    {
                        path: "mapping",
                        name: "sensorMapping",
                        props: true,
                        component: MapSensors,
                    },
                    {
                        path: "create_sensors",
                        name: "newSensors",
                        props: true,
                        component: CreateSensors,
                    },
                    ],
                },
                // Others
                {
                    path: "validation",
                    name: "validation",
                    props: true,
                    component: Plant_SensorValidation,
                },
                {
                    path: "upload",
                    name: "dataUpload",
                    props: true,
                    component: Plant_DataUploadHistory,
                },
                {
                    path: "pc_method",
                    name: "pcMethod",
                    props: true,
                    component: Plant_PCmethod,
                },
            ]
        },

        {path: "/", redirect: "/plant_selection"},
        {path: "*", redirect: "/plant_selection"}
    ]
})



router.beforeEach((to, from, next) => {
    let requiresBackendOnline = to.matched.some(record => !record.meta.onBackendOffline)
    if (requiresBackendOnline) {
        checkBackendOnline().then(() => {
            next()
        }).catch(() => {
            next({
                name: "connection",
                params: {router_message: "It seems that the Backend is not listening!!"},
                query: {redirect: to.fullPath}
            })
        })
    } else {
        next()
    }
})

export default router


