<template>
  <v-card height="100%" width="45px" class="pa-0 ma-0" elevation="1">
    <v-navigation-drawer mini-variant height="100%" permanent mini-variant-width="45px" color="veryverylight">
      <div class="ma-auto pt-2 pb-0" style="font-size: 8px; text-align: center"> TOOLS </div>
      <v-divider></v-divider>
      <v-list-item-group :value="value" @change="updateTool">
        <v-list-item dense value="zoom">
          <v-icon small> mdi-magnify </v-icon>
        </v-list-item>
        <v-list-item dense value="select">
          <v-icon small> mdi-cursor-default </v-icon>
        </v-list-item>
      </v-list-item-group>

      <v-divider></v-divider>

      <template v-slot:append>
        <v-list-item @click="$emit('home')" :disabled="disabled">
          <vertical-button icon="mdi-arrow-expand-all" text="Resize" :disabled="disabled"></vertical-button>
        </v-list-item>
        <v-list-item @click="$emit('export')" :disabled="disabled" >
          <vertical-button icon="mdi-download" text="Export" :disabled="disabled"></vertical-button>
        </v-list-item>
      </template>
    </v-navigation-drawer>
  </v-card>
</template>
<script>
import VerticalButton from "@/components/VerticalButton.vue"

export default {
  name: 'FigureToolbar',
  components: {VerticalButton},
  props: {
    value: String,
    disabled: Boolean,
  },
  methods: {
    updateTool(new_tool){
      this.$emit('input', new_tool)
    }
  },
}
</script>
<style scoped>



</style>