import axios from 'axios';
import qs from "qs";


export default function runtimeConfig(name) {
    return window.config && window.config[name] ? window.config[name] : process.env[name];
}

export const AXIOS = axios.create({
    baseURL: runtimeConfig("HIT_API_BASE_URL")
})


// get infos about the api with: http://127.0.0.1:8000/docs

export function checkBackendOnline() {
    const path = "config/ping_backend?token=harvestIT"
    return AXIOS({method: "get", "url": path, timeout: 2000})
}

export function checkDatabaseOnline() {
    const path = "config/ping_database?token=harvestIT"
    return AXIOS({method: "get", "url": path, timeout: 2000})
}


export function getDebugInfo() {
    const path = "debug_info?token=harvestIT"
    return AXIOS({method: "get", "url": path, timeout: 2000})
}

export function exportDump(){
    getDebugInfo().then(res => {
        let jsonStr = JSON.stringify(res);
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(jsonStr));
        element.setAttribute("download", "debug_dump.json");
        element.click();
    })
}


export function getAbout() {
    const path = "/about?token=harvestIT"
    return AXIOS.get(path)
}



export function getErrorMessage(res){
    let status = res.response.status
    let detail = res.response.data.detail
    let message = res.response.data.message

    if (Array.isArray(detail)){
        detail = detail.map(val => _parseErrorDetail(val))
        detail = detail.join("\n")
        message = message.join("\n")
    }
    return "ERROR!\n Status: '" + status + "'.\n Detail: '" + detail + "'.\n Message: '" + message + "'"
}


export function _parseErrorDetail(error_detail){
    return error_detail.msg + " at location " + JSON.stringify(error_detail.loc)
}

// PLANT
export function get_plants(){
    const path = "plants/summary?token=harvestIT"
    return AXIOS.get(path)
}

export function get_plant(plant_id){
    const path = "plants/"+encodeURIComponent(plant_id)+"?token=harvestIT"
    return AXIOS.get(path)
}
export function create_plant(plant_data){
    const path = "plants/new?token=harvestIT"
    return AXIOS.post(path, plant_data)
}
export function update_plant(plant_data){
    const path = "plants/" + encodeURIComponent(plant_data.id) + "?token=harvestIT"
    return AXIOS.post(path, plant_data)
}
export function delete_plant(plant_id){
    const path = "plants/"+encodeURIComponent(plant_id)+"?token=harvestIT"
    return AXIOS.delete(path)
}

export function get_plant_utc(plant_id){
    return get_plant(plant_id).then(res => {
        return res.data.tz_data_offset
    })

}



// ARRAY
export function create_array(plant_id, array_data){
    const path = "plants/"+encodeURIComponent(plant_id)+"/arrays/new?token=harvestIT"
    return AXIOS.post(path, array_data)
}

export function get_arrays(plant_id){
    const path = "plants/" + encodeURIComponent(plant_id) + "/arrays?token=harvestIT"
    return AXIOS.get(path)
}
export function get_array(array_id){
    const path = "plants/-/arrays/" + encodeURIComponent(array_id) + "?token=harvestIT"
    return AXIOS.get(path)
}
export function update_array(array_data){
    const path = "plants/-/arrays/"+encodeURIComponent(array_data.id)+"?token=harvestIT"
    return AXIOS.post(path, array_data)
}
export function delete_array(array_id){
    const path = "plants/-/arrays/" + encodeURIComponent(array_id) + "?token=harvestIT"
    return AXIOS.delete(path)
}


// SENSORS
export function get_sensors(plant_id) {
    const path = "plants/" + encodeURIComponent(plant_id) + "/sensors?token=harvestIT"
    return AXIOS.get(path)
}
export function create_sensor(plant_id, sensor_data){
    // sensor needs raw_name: "name of sensor in csv columns"
    const path = "plants/"+encodeURIComponent(plant_id)+"/sensors/new?token=harvestIT"
    return AXIOS.post(path, sensor_data)
}

export function get_sensor(plant_id, sensor_id){
    const path = "plants/-/sensors/"+encodeURIComponent(sensor_id) + "?token=harvestIT"
    return AXIOS.get(path)
}

export function update_sensor(plant_id, sensor_data){
    // needed as formatted_unit from backend is "None" but must be set to "" if there is a dimensionless unit
    sensor_data["native_unit"] = sensor_data["formatted_unit"] === "None" ? "" : sensor_data["formatted_unit"]
    const path = "plants/-/sensors/"+encodeURIComponent(sensor_data.id) + "?token=harvestIT"
    return AXIOS.post(path, sensor_data)
}
export function delete_sensor(plant_id, sensor_id){
    const path = "plants/-/sensors/" + encodeURIComponent(sensor_id) + "?token=harvestIT"
    return AXIOS.delete(path)
}
export function delete_multiple_sensors(plant_id, sensor_ids){
    sensor_ids = sensor_ids.map(val => String(val))
    const path = "plants/" + encodeURIComponent(plant_id) + "/sensors?token=harvestIT"
    return AXIOS.delete(path, {
        params: {ids: sensor_ids},
        paramsSerializer: p => {return qs.stringify(p, {arrayFormat: 'repeat'})}
    })
}



export function recalculateVirtuals(plant_id){
    const path = "plants/" + encodeURIComponent(plant_id) + "/sensors/recalculate_virtuals?token=harvestIT"
    return AXIOS.get(path)
}



// FLUID

export function get_channel_names(component_type){
    const path = "config/sensor_slots?component_type="+encodeURIComponent(component_type)+"&token=harvestIT"
    return AXIOS.get(path)
}


export function get_collectors(){
    const path = "/config/collectors?token=harvestIT"
    return AXIOS.get(path)
}
export function get_collector(collector_id){
    const path = "/config/collectors/" + encodeURIComponent(collector_id) + "?token=harvestIT"
    return AXIOS.get(path)
}
export function update_collector(collector_data){
    const path = "/config/collectors/" + encodeURIComponent(collector_data.id) + "?token=harvestIT"
    return AXIOS.post(path, collector_data)
}
export function delete_collector(collector_id){
    const path = "/config/collectors/" + encodeURIComponent(collector_id) + "?token=harvestIT"
    return AXIOS.delete(path)
}
export function add_collector(collector_data){
    const path = "/config/collectors/new?token=harvestIT"
    return AXIOS.post(path, collector_data)
}





export function get_sensortypes() {
    const path = "config/sensor_types?token=harvestIT"
    return AXIOS.get(path)
}

export function get_fluids() {
    const path = "config/fluid_definitions?token=harvestIT"
    return AXIOS.get(path)
}
export function update_fluid(plant_id, fluid_name, fluid_concentration_vol){
    let relevant_plant_data = {
        "id": plant_id,
        "fluid_solar": {
            "fluid": fluid_name,
        },
    }
    if (fluid_concentration_vol !== null){
        relevant_plant_data["fluid_solar"]["concentration"] = {
            "units": "dimensionless",
            "magnitude": fluid_concentration_vol/100
        }
    }
    const path = "plants/" + encodeURIComponent(plant_id) + "?token=harvestIT"
    return AXIOS.post(path, relevant_plant_data)
}

export function delete_fluid(plant_id){
    let relevant_plant_data = {
        "id": plant_id,
        "fluid_solar": null,
    }
    const path = "plants/" + encodeURIComponent(plant_id) + "?token=harvestIT"
    return AXIOS.post(path, relevant_plant_data)
}

export function create_demo_plant(){
    const path = "plants/create_demo_plant?include_data=true&accept_license=true&token=harvestIT"
    return AXIOS.get(path)
}



// FILE UPLOAD

export function getTimezoneOptions(){
    const path = "/available_timezones?token=harvestIT"
    return AXIOS.get(path)
}

const onUploadProgressEmtpy = function(){}

function addFileUploadParamsToPath(path, csv_settings){
    path += (csv_settings['datetime_template'] !== null ? `&datetime_template=${encodeURIComponent(csv_settings['datetime_template'])}` : "")
    path += (csv_settings['datetime_format'] !== null ? `&datetime_format=${encodeURIComponent(csv_settings['datetime_format'])}` : "")
    path += (csv_settings['timezone'] !== null ? `&timezone=${encodeURIComponent(csv_settings['timezone'])}` : "")
    path += (csv_settings['csv_separator'] !== null ? `&csv_separator=${encodeURIComponent(csv_settings['csv_separator'])}` : "")
    path += (csv_settings['csv_decimal'] !== null ? `&csv_decimal=${encodeURIComponent(csv_settings['csv_decimal'])}` : "")
    path += (csv_settings['csv_encoding'] !== null ? `&csv_encoding=${encodeURIComponent(csv_settings['csv_encoding'])}` : "")
    path += (csv_settings['index_col'] !== null ? `&index_col=${encodeURIComponent(csv_settings['index_col'])}` : "")
    return path
}



export function getUploadResponse(plant_id, files, csv_settings){
    files = Array.isArray(files) ? files : [files]

    let formData = new FormData();
    files.forEach(file => {formData.append("files", file)})
    let path = `plants/${plant_id}/data/columns?token=harvestIT`
    path = addFileUploadParamsToPath(path, csv_settings)

    return AXIOS({
        method: 'post',
        url: path,
        data: formData,
        headers: {
            "Accept": "application/json",
            "Content-Type": "multipart/form-data",
        },
    })

}


export function upload_data(plant_id, files, csv_settings, onUploadProgress=onUploadProgressEmtpy) {
    files = Array.isArray(files) ? files : [files]

    let formData = new FormData();
    files.forEach(file => {formData.append("files", file)})

    let path = `plants/${plant_id}/data?token=harvestIT`
    path = addFileUploadParamsToPath(path, csv_settings)

    return AXIOS({
        method: 'post',
        url: path,
        data: formData,
        onUploadProgress: onUploadProgress,
        headers: {
            "Accept": "application/json",
            "Content-Type": "multipart/form-data",
        },
    })
}

export function get_data_upload_history(plant_id){
    const path = "plants/" + encodeURIComponent(plant_id) + "/data/history?token=harvestIT"
    return AXIOS.get(path)
}

export function delete_history_entry(plant_id, history_id){
    const path = "plants/" + encodeURIComponent(plant_id) + "/data/history/" + encodeURIComponent(history_id) + "?token=harvestIT"
    return AXIOS.delete(path)
}

export function get_sensordata(plant_id, sensor_id, start=null, end=null) {
    let start_query = (start === null) || (start === "") ? "" : "&eval_start=" + encodeURIComponent(start)
    let end_query = (end === null) || (end === "") ? "" : "&eval_end=" + encodeURIComponent(end)
    const path = "plants/" + encodeURIComponent(plant_id) + "/sensors/"+  encodeURIComponent(sensor_id) + "/data?token=harvestIT"+ start_query + end_query
    return AXIOS.get(path)
}

export function get_start_end(plant_id){
    const path = "plants/" + encodeURIComponent(plant_id) + "/data_start_end?token=harvestIT"
    return AXIOS.get(path)
}

export function delete_data(plant_id, start, end){
    const path = "plants/" + encodeURIComponent(plant_id) + "/data?token=harvestIT&start="+ encodeURIComponent(start) + "&end=" + encodeURIComponent(end)
    return AXIOS.delete(path)
}

export function delete_all_data(plant_id){
    const path = "plants/" + encodeURIComponent(plant_id) + "/data/all?token=harvestIT"
    return AXIOS.delete(path)
}


// PC-Method
export function get_pc_method_settings(plant_id){
    const path = "plants/" + encodeURIComponent(plant_id) + "/evaluations/pc_method_settings?token=harvestIT"
    return AXIOS.get(path)
}

export function save_pc_method_settings(plant_id, settings){
    const path = "plants/" + encodeURIComponent(plant_id) + "/evaluations/pc_method_settings?token=harvestIT"
    return AXIOS.post(path, settings)
}

export function get_report(plant_id, settings, start=null, end=null){
    let settings_query = getPCMethodQuerySettings(settings)
    let start_query = (start === null) || (start === "") ? "" : "&eval_start=" + encodeURIComponent(start)
    let end_query = (end === null) || (end === "") ? "" : "&eval_end=" + encodeURIComponent(end)
    const path = "plants/" + encodeURIComponent(plant_id) + "/evaluations/pc_method_report?token=harvestIT" + settings_query  + start_query + end_query
    return AXIOS.get(path, {responseType: 'blob'})
}

export function run_pc(plant_id, settings, start=null, end=null) {
    let settings_query = getPCMethodQuerySettings(settings)
    let start_query = (start === null) || (start === "") ? "" : "&eval_start=" + encodeURIComponent(start)
    let end_query = (end === null) || (end === "") ? "" : "&eval_end=" + encodeURIComponent(end)
    const path = "plants/" + encodeURIComponent(plant_id) + "/evaluations/pc_method?token=harvestIT" + settings_query + start_query + end_query
    return AXIOS.get(path)
}

function getPCMethodQuerySettings(settings){
    let formula_query = (settings.formula === null) ? "" : "&formula=" + encodeURIComponent(settings.formula)
    let method_query = settings.evaluation_mode === null ? "" : "&method=" + encodeURIComponent(settings.evaluation_mode.toLowerCase())
    let fp_query = settings.safety_pipes === null ? "" : "&safety_pipes=" + settings.safety_pipes/100
    let fu_query = settings.safety_uncertainty === null ? "" : "&safety_uncertainty=" + settings.safety_uncertainty/100
    let fo_query = settings.safety_others === null ? "" : "&safety_others=" + settings.safety_others/100
    let use_wind_query = settings.wind_used === null ? "" : "&ignore_wind=" + !settings.wind_used
    return method_query + formula_query + fp_query + fu_query + fo_query + use_wind_query
}



export function exportPlant(plant){
    const path = "plants/" + encodeURIComponent(plant.id) + "/export_config?token=harvestIT"
    return AXIOS.get(path)
}

export function importPlant(plant_json){
    const path = "plants/import?token=harvestIT"
    return AXIOS.post(path, plant_json)
}