<template>
  <v-list-group
      :prepend-icon="icon"
      class="icon"
      :value="isParentRoute"
  >
    <template v-slot:activator>
      <v-list-item-title @click="goToRoute"> {{ label }} </v-list-item-title>
    </template>

    <v-list-item-group>
      <slot></slot>
    </v-list-item-group>

  </v-list-group>
</template>

<script>
export default {
  props: ["icon", "label", "group", "route"],
  name: "NavigationGroup",
  data: () => ({
    isActive: false,
  }),
  methods: {
    goToRoute(){
      this.$router.push(this.route)
    },
  },
  computed: {
    isParentRoute(){
      let matched_routes = this.$route.matched
      let key = Object.keys(this.route)
      let nrMatchedRoutes = matched_routes.filter(route => {
        return route[key] == this.route[key]
      }).length
      return nrMatchedRoutes === 1
    }
  }
}
</script>

<style scoped>

.icon >>> .v-list-item__icon{
  margin-right: 12px !important;
}
</style>