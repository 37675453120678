<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <sup><v-icon tabindex="-1" :disabled="disabled" class="info_icon" @click="$emit('click')" :x-small="small" :small="!small" v-bind="attrs" v-on="on" :color="color"> mdi-information-outline</v-icon></sup>
    </template>

    <div> <slot></slot></div>
    <span class="warning"> {{ error_message }}</span>
  </v-tooltip>
</template>


<script>
export default {
  name: "InfoTooltip",
  data: () => ({
  }),

  props: {
    disabled: Boolean,
    small: Boolean,
    color: {default: "inherit"},
    error_message: {default: ""},
    type: {default: "input", options: ["input", "label", "text"]},
  }
}
</script>

<style scoped>

.v-tooltip__content.menuable__content__active {
  opacity: 1.0!important;
}


.info_icon{
  padding: 2px 1px;
  opacity: 0.7;
}


div {
  max-width: 500px;
}


</style>