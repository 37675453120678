<template>
  <config-container :error_message="error_message" :success_message="success_message" max_width="800" color="verylight" :dark="false" >

    <LoadingScreen title="Saving" v-model="isSaving"> Saving collector. Please wait </LoadingScreen>

    <div ref="ConfigEditorTop" style="position: absolute; top: -500px; left: 0"></div>

    <v-row class="py-1" >
      <h1>{{ isNew ? "New Collector"  : "Edit Collector"}}</h1>
      <v-spacer></v-spacer>
      <v-chip v-if="!isNew" label class="mx-2" color="secondary"> #{{collector_id}}</v-chip>
    </v-row>

    <v-form v-model="form_valid" lazy-validation @submit="updateCollector" ref="form">

        <ConfigSection name="Basics" color="verylight" bordercolor="lightgray">
          <configTextFieldRow
              v-model="manufacturer"
              label="Manufacturer"
              description="Name of Manufacturer"
              placeholder="Manufacturer Name"
              :disabled="is_locked"
              required
          ></configTextFieldRow>
          <configTextFieldRow
              v-model="collector_name"
              label="Collector Name"
              description="Product Name of Collector."
              placeholder="Collector Name"
              :disabled="is_locked"
              required
          ></configTextFieldRow>
          <configTextFieldRow
              v-model="licence_nr"
              label="Licence Number"
              description="Solar Keymark Reference Number"
              placeholder="Licence Number"
              :disabled="is_locked"
              required
          ></configTextFieldRow>
          <config-text-field-row
              v-model="certificate_date_issued"
              label="Date issued"
              type="date"
              description="'Date issued' field on Solar Keymark certificate."
              :disabled="is_locked"
          ></config-text-field-row>


          <v-row>
            <v-subheader  class="label"> Collector Type
              <InfoTooltip>
                Construction type of the collector, as defined in Solar Keymark / ISO 9806.
                Main distinction is between flat plate and concentrating collectors.
              </InfoTooltip>
            </v-subheader>
            <v-radio-group :disabled="is_locked" mandatory dense row v-model="collector_type" hide-details class="pa-auto pt-3 ma-0">
              <v-radio label="Flat Plate" :value="COLLECTOR_TYPES.FLAT"></v-radio>
              <v-radio label="Concentrating" :value="COLLECTOR_TYPES.CONCENTRATING"></v-radio>
            </v-radio-group>
            <v-spacer></v-spacer>
          </v-row>

          <configTextFieldRow
              v-if="collector_type===COLLECTOR_TYPES.CONCENTRATING"
              v-model="concentration_ratio"
              label="Concentration Ratio"
              description="Geometric concentration ratio: Factor by which solar irradiance is concentrated onto the collector's
            absorbing surface. When applying a ISO 24194 Thermal Power Check, the `concentration_ratio` is used to determine which of the
            3 formulae defined in ISO 24194 to apply."
              placeholder="20 (example only)"
              :disabled="is_locked"
              minimum="1"
          ></configTextFieldRow>

        </ConfigSection>


      <ConfigSection name="Size" color="verylight" bordercolor="lightgray">
        <configTextFieldRow
            v-model="area_gr"
            label="Gross Area"
            description="Gross area of collector"
            placeholder="13.57 (example only)"
            :disabled="is_locked"
            required
            suffix="m²"
            minimum="0"
        ></configTextFieldRow>
        <configTextFieldRow
            v-model="area_ap"
            label="Aperture Area"
            description="Aperature area of collector (required if parameters are related to Aperature Area (AP)"
            placeholder="12.51 (example only)"
            :disabled="is_locked"
            :required="test_reference_area === TEST_REFERENCES.ap"
            suffix="m²"
            minimum="0"
        ></configTextFieldRow>
        <configTextFieldRow
            v-model="gross_length"
            label="Gross Length"
            description="Gross length of collector"
            placeholder="5973 (example only)"
            :disabled="is_locked"
            required
            suffix="mm"
            minimum="0"
        ></configTextFieldRow>
        <configTextFieldRow
            v-model="gross_width"
            label="Gross Width"
            description="Gross width of collector, measured parallel to the ground from left to right"
            placeholder="2272 (example only)"
            :disabled="is_locked"
            required
            minimum="0"
            suffix="mm"
        ></configTextFieldRow>
        <configTextFieldRow
            v-model="gross_height"
            label="Gross Height"
            description="Gross height of collector, measured perpendicular to the ground from bottom to top"
            placeholder="145 (example only)"
            :disabled="is_locked"
            required
            suffix="mm"
            minimum="0"
        ></configTextFieldRow>
      </ConfigSection>

      <ConfigSection name="Keymark Parameter" color="verylight" bordercolor="lightgray">

        <v-row>
          <v-subheader  class="label"> Reference Area
            <InfoTooltip> Keymarks are always refering to either aperature or gross area. Using the correct reference does have a high influence on the parameters</InfoTooltip>
          </v-subheader>
          <v-radio-group :disabled="is_locked" mandatory dense row v-model="test_reference_area" hide-details class="pa-auto ma-0">
            <v-radio label="Gross Area" :value="TEST_REFERENCES.gr"></v-radio>
            <v-radio label="Aperture Area" :value="TEST_REFERENCES.ap"></v-radio>
          </v-radio-group>
          <v-spacer></v-spacer>
        </v-row>

        <v-row>
          <v-subheader  class="label"> Test Type
            <InfoTooltip> Collectors are either tested according to SST or QDT. One of the differences is that the QDT test distinguishes between beam and diffuse irradiance </InfoTooltip>
          </v-subheader>
          <v-radio-group :disabled="is_locked" mandatory dense row v-model="test_type" hide-details class="pa-auto ma-0">
            <v-radio label="Quasi Dynamic Test (QDT)" :value="TEST_TYPES.QDT"></v-radio>
            <v-radio label="Steady State Test (SST)" :value="TEST_TYPES.SST"></v-radio>
          </v-radio-group>
          <v-spacer></v-spacer>
        </v-row>


        <configTextFieldRow
            v-if="test_type === TEST_TYPES.QDT"
            v-model="eta0b"
            label="eta0b"
            description="Peak collector efficiency (i.e. no heat losses) based on beam irradiance."
            placeholder="0.74 (example only)"
            :disabled="is_locked"
            suffix=""
            minimum="0"
            required
        ></configTextFieldRow>

        <configTextFieldRow
            v-if="test_type === TEST_TYPES.SST"
            v-model="eta0hem"
            label="eta0hem"
            description="Peak collector efficiency (i.e. no heat losses) based on hemispherical irradiance."
            placeholder="0.73 (example only)"
            :disabled="is_locked"
            suffix=""
            required
            minimum="0"
        ></configTextFieldRow>

        <template v-if="test_reference_area === TEST_REFERENCES.gr">
          <configTextFieldRow
              v-model="a1"
              label="a1"
              description="First order heat loss coefficient of collector"
              placeholder="2.14 (example only)"
              :disabled="is_locked"
              suffix="W/m².K"
              minimum="0"
              required
          ></configTextFieldRow>

          <configTextFieldRow
              v-model="a2"
              label="a2"
              description="Second order heat loss coefficient of collector"
              placeholder="0.009 (example only)"
              :disabled="is_locked"
              suffix="W/m².K²"
              required
              minimum="0"
          ></configTextFieldRow>

          <configTextFieldRow
              v-model="a5"
              :label="test_type === TEST_TYPES.SST ? 'Ceff' : 'a5'"
              description="Effective thermal capacity of collector. In some sheets this is denoted as Ceff, in some this is denoted a5 or c5."
              placeholder="6015 (example only)"
              :disabled="is_locked"
              suffix="J/m².K"
              required
              minimum="0"
          ></configTextFieldRow>

          <configTextFieldRow
              v-if="collector_type === COLLECTOR_TYPES.CONCENTRATING"
              v-model="a8"
              label="a8"
              description="Radiation losses dependence. Only used for concentrating collectors with formula. Default values is zero."
              placeholder="0 (example only)"
              :disabled="is_locked"
              :required="collector_type === COLLECTOR_TYPES.CONCENTRATING"
              suffix="W/(m².K⁴)"
              minimum="0"
          ></configTextFieldRow>
        </template>

        <template v-if="test_reference_area === TEST_REFERENCES.ap">
          <configTextFieldRow
              v-model="a1_ap"
              label="a1"
              description="First order heat loss coefficient of collector"
              placeholder="2.14 (example only)"
              :disabled="is_locked"
              suffix="W/m².K"
              minimum="0"
              required
          ></configTextFieldRow>

          <configTextFieldRow
              v-model="a2_ap"
              label="a2"
              description="Second order heat loss coefficient of collector"
              placeholder="0.009 (example only)"
              :disabled="is_locked"
              suffix="W/m².K²"
              required
              minimum="0"
          ></configTextFieldRow>

          <configTextFieldRow
              v-model="a5_ap"
              :label="test_type === TEST_TYPES.SST ? 'Ceff' : 'a5'"
              description="Effective thermal capacity of collector. In some sheets this is denoted as Ceff, in some this is denoted a5 or c5."
              placeholder="6015 (example only)"
              :disabled="is_locked"
              suffix="J/m².K"
              required
              minimum="0"
          ></configTextFieldRow>

          <configTextFieldRow
              v-if="collector_type === COLLECTOR_TYPES.CONCENTRATING"
              v-model="a8_ap"
              label="a8"
              description="Radiation losses dependence. Only used for concentrating collectors with formula. Default values is zero."
              placeholder="0 (example only)"
              :disabled="is_locked"
              suffix="W/(m².K⁴)"
              minimum="0"
          ></configTextFieldRow>
        </template>

        <configTextFieldRow
            v-model="kd"
            label="kd"
            :required="test_type===TEST_TYPES.QDT"
            description="Incidence angle modifier for diffuse solar radiation."
            placeholder="0.91 (example only)"
            :disabled="is_locked"
            suffix=""
            minimum="0"
            maximum="2"
        ></configTextFieldRow>
      </ConfigSection>

      <ConfigSection name="Incidence Angle Modifier (IAM)" color="verylight" bordercolor="lightgray">
        <config-autocomplete
            v-model="iam_method_type"
            :items="Object.values(IAM_TYPES)"
            label="Reference"
            description=""
            placeholder="please select IAM method type"
            :disabled="is_locked"
            suffix=""
        ></config-autocomplete>

        <template v-if="iam_method_type === IAM_TYPES.interpolated">
          <v-subheader class="mt-7 mb-0" style="height: 20px;"> please insert the IAM values for each angle of incidence below: </v-subheader>
          <v-card color="verylight" flat outlined>
            <v-simple-table dense class="verylight pa-2" >
              <thead>
                <tr>
                  <td style="padding: 0 5px;"></td>
                  <td style="font-size: 12px" v-for="i in aoi_reference" :key="i"> {{ i }}° </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="padding: 0 5px;"> T </td>
                 <td class="pa-0" style="font-size: 12px;" v-for="i in Array(aoi_reference.length).keys()" :key="i" >
                    <v-text-field dense outlined hide-details v-model="iam_reference[0][i]" placeholder="1.00" :disabled="is_locked"></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td style="padding: 0 5px;"> L </td>
                  <td class="pa-0" style="font-size: 12px;" v-for="i in Array(aoi_reference.length).keys()" :key="i" >
                    <v-text-field dense outlined hide-details v-model="iam_reference[1][i]" placeholder="1.00" :disabled="is_locked"></v-text-field>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </template>


        <template v-if="iam_method_type === IAM_TYPES.ashrae">
          <configTextFieldRow
              v-model="b"
              label="b0"
              description=""
              placeholder=""
              :disabled="is_locked"
              suffix=""
              maximum="1"
              minimum="0"
          ></configTextFieldRow>
        </template>

        <template v-if="iam_method_type === IAM_TYPES.k50">
          <configTextFieldRow
              v-model="k50"
              label="k50"
              description=""
              placeholder=""
              :disabled="is_locked"
              suffix=""
              maximum="2"
              minimum="0"
          ></configTextFieldRow>
        </template>


      </ConfigSection>

      <v-row class="pa-0 mx-3 my-3 mb-n2">
        <template v-if="is_locked">
          <v-btn color="primary" outlined @click="$router.go(-1)"> Back </v-btn>
          <v-spacer></v-spacer>
        </template>

        <template v-else>
          <v-btn color="primary" outlined @click="$emit('cancel')"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn :color="form_valid? 'primary' : 'error'" :class="{'disable-events': !form_valid}" @click="updateCollector"> Save </v-btn>
        </template>
      </v-row>

      <v-row>
        <v-spacer></v-spacer>
        <span  v-if="!form_valid" style="font-size: 12px" class="px-2 lightwarning--text" > There seem to be missing or invalid inputs </span>
      </v-row>



    </v-form>

  </config-container>
</template>

<script>

import * as backend from "@/plugins/backend_api";
import configContainer from "@/components/ConfigContainer";
import ConfigSection from "@/components/ConfigSection";
import configTextFieldRow from "@/components/Config_TextFieldRow.vue";
import ConfigAutocomplete from "@/components/Config_Autocomplete.vue";
import InfoTooltip from "@/components/_InfoTooltip.vue";
import LoadingScreen from "@/components/LoadingScreen.vue";

const COLLECTOR_TYPES = {
  FLAT: "flat_plate",
  CONCENTRATING: "concentrating"
}
const TEST_REFERENCES = {
  gr: "gross",
  ap: "aperture",
}
const TEST_TYPES = {
  QDT: "QDT",
  SST: "SST",
}
const IAM = {
  interpolated: "IAM_Interpolated",
  ashrae: "IAM_ASHRAE",
  k50: "IAM_K50",
}

const AOI_REFERENCE = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90];
const IAM_REFERENCE = [
    [1.00, null, null, null, null, null, null, null, null, 0],
    [1.00, null, null, null, null, null, null, null, null, 0]];


export default {
  name: 'CollectorEditor',
  components: {
    LoadingScreen,
    InfoTooltip,
    ConfigAutocomplete,
    configContainer,
    configTextFieldRow,
    ConfigSection
  },
  props: {
    collector_id: {},
  },
  data: () => ({
    collector: {},
    error_message: "",
    success_message: "",
    is_locked: false,
    show_delete: false,
    form_valid: false,
    isSaving: false,

    // inputs
    collector_name: null,
    manufacturer: null,
    licence_nr: null,
    certificate_date_issued: null,
    gross_width: null,
    gross_length: null,
    gross_height: null,
    area_gr: null,
    area_ap: null,

    TEST_REFERENCES: TEST_REFERENCES,
    TEST_TYPES: TEST_TYPES,
    COLLECTOR_TYPES: COLLECTOR_TYPES,

    test_reference_area: TEST_REFERENCES.gr,
    test_type: TEST_TYPES.QDT,
    collector_type: COLLECTOR_TYPES.FLAT,

    eta0b: null,
    eta0hem: null,
    a1: null,
    a2: null,
    a5: null,
    a8: null,
    a1_ap: null,
    a2_ap: null,
    a5_ap: null,
    a8_ap: null,

    kd: null,
    concentration_ratio: null,

    iam_method_type: IAM.interpolated,
    IAM_TYPES: IAM,
    // in case of interpolated
    aoi_reference: AOI_REFERENCE,
    iam_reference: IAM_REFERENCE,
    b: null,  // in case of IAM ASHRAE
    k50: null, // in case of IAM k50
  }),
  watch: {
    collector_id(){
      this.error_message=""
      this.getAndParseCollector()
    },
  },
  mounted() {
    this.getAndParseCollector()
  },
  computed: {
    isNew(){
      return (this.collector_id === undefined || this.collector_id === null || this.collector_id === "new")
    }
  },
  methods: {
    getAndParseCollector(){
      if (this.isNew) {
        this.collector = {}
        this.parseCollector()
        return
      }
      this.getCollector().then(() => {
        this.parseCollector()
      })
    },
    parseCollector(){
      //Basics
      this.collector_name = this.collector["product_name"] || null
      this.manufacturer = this.collector["manufacturer_name"] || null
      this.licence_nr = this.collector["licence_number"] || null
      this.certificate_date_issued = this.collector["certificate_date_issued"] === null ? null : this.collector["certificate_date_issued"].slice(0, 10)
      this.collector_type = this.collector["collector_type"] || null
      this.concentration_ratio = !this.collector["concentration_ratio"] ? null : this.collector["concentration_ratio"]["magnitude"]

      //sizes
      this.gross_width = !this.collector["gross_width"] ? null : this.collector["gross_width"]["magnitude"]
      this.gross_length = !this.collector["gross_length"] ? null : this.collector["gross_length"]["magnitude"]
      this.gross_height = !this.collector["gross_height"] ? null : this.collector["gross_height"]["magnitude"]
      this.area_gr = !this.collector["area_gr"] ? null : this.collector["area_gr"]["magnitude"]
      this.area_ap = !this.collector["area_ap"] ? null : this.collector["area_ap"]["magnitude"]

      //keymark params
      this.test_reference_area = this.collector["test_reference_area"] || TEST_REFERENCES.gr
      this.test_type = this.collector["test_type"]
      this.a1 = !this.collector["a1"] ? null : this.collector["a1"]["magnitude"]
      this.a2 = !this.collector["a2"] ? null : this.collector["a2"]["magnitude"]
      this.a5 = !this.collector["a5"] ? null : this.collector["a5"]["magnitude"]
      this.a8 = !this.collector["a8"] ? null : this.collector["a8"]["magnitude"]
      this.kd = !this.collector["kd"] ? null : this.collector["kd"]["magnitude"]
      this.eta0hem = !this.collector["eta0hem"] ? null : this.collector["eta0hem"]["magnitude"]
      this.eta0b = !this.collector["eta0b"] ? null : this.collector["eta0b"]["magnitude"]

      // Keymark Aperature parameters
      let ap_params = this.collector["aperture_parameters"]
      this.a1_ap = !ap_params || !ap_params["a1"] ? null : ap_params["a1"]["magnitude"]
      this.a2_ap = !ap_params || !ap_params["a2"] ? null : ap_params["a2"]["magnitude"]
      this.a5_ap = !ap_params || !ap_params["a5"] ? null : ap_params["a5"]["magnitude"]
      this.a8_ap = !ap_params || !ap_params["a8"] ? null : ap_params["a8"]["magnitude"]

      // todo: ceff and a5 are the same? It seems there is no ceff parameter anymore
      this.a5 = this.collector["ceff"] && (this.a5 === null) ?  this.collector["ceff"]["magnitude"] : this.a5

      // iam method
      this.iam_method_type = !this.collector["iam_method"] ? null : this.collector["iam_method"]["method_type"]
      this.b = !this.collector["iam_method"] || !this.collector["iam_method"]["b"] ? null : this.collector["iam_method"]["b"]["magnitude"]
      this.k50 = !this.collector["iam_method"] || !this.collector["iam_method"]["k50"] ? null : this.collector["iam_method"]["k50"]["magnitude"]
      this.aoi_reference = !this.collector["iam_method"] || !this.collector["iam_method"]["aoi_reference"] ? AOI_REFERENCE : this.collector["iam_method"]["aoi_reference"]["magnitude"][0]
      this.iam_reference = !this.collector["iam_method"] || !this.collector["iam_method"]["iam_reference"] ? IAM_REFERENCE : this.collector["iam_method"]["iam_reference"]["magnitude"]
    },
    parseBackCollector(){
      let collector = {
        name: this.collector_name,
        product_name: this.collector_name,
        manufacturer_name: this.manufacturer,
        licence_number: this.licence_nr,
        certificate_date_issued: this.certificate_date_issued === "" ? null : this.certificate_date_issued,
        collector_type: this.collector_type,

        gross_width: {magnitude: this.gross_width, units: "mm"},
        gross_length: {magnitude: this.gross_length, units: "mm"},
        gross_height: {magnitude: this.gross_height, units: "mm"},
        area_gr: {magnitude: this.area_gr, units: "m²"},

        test_reference_area: this.test_reference_area,
        test_type: this.test_type,
        a1: {magnitude: this.test_reference_area === TEST_REFERENCES.ap ? this.a1_ap : this.a1, units: "W/m²/K"},
        a2: {magnitude: this.test_reference_area === TEST_REFERENCES.ap ? this.a2_ap : this.a2, units: "W/m²/K²"},
      }

      // optional parameters
      if (!this.isNew) {collector["id"] = this.collector_id}
      collector["area_ap"] = (this.area_ap !== null && this.area_ap !== "")? {magnitude: this.area_ap, units: "m²"} : null
      collector["kd"] = (this.kd !== null && this.kd !== "") ? {magnitude: this.kd, units: ""} : null
      collector["concentration_ratio"] = (this.concentration_ratio !== null && this.concentration_ratio !== "") ? {magnitude: this.concentration_ratio, units: ""} : null
      let a8 = this.test_reference_area === TEST_REFERENCES.ap ? this.a8_ap : this.a8
      collector["a8"] = (a8 !== null && a8 !== "") ? {magnitude: a8, units: "W/m²/K^4"} : null

      // IAM specific data
      if (this.iam_method_type === IAM.interpolated){
        collector["iam_method"] = {
          method_type: this.iam_method_type,
          aoi_reference: {magnitude: [this.aoi_reference, this.aoi_reference], units: "°"},
          iam_reference: {magnitude: this.iam_reference, units: ""},
        }
      } else if (this.iam_method_type === IAM.ashrae) {
        collector["iam_method"] = {
          method_type: this.iam_method_type,
          b: {magnitude: this.b, units: ""},
        }
      } else if (this.iam_method_type === IAM.k50) {
        collector["iam_method"] = {
          method_type: this.iam_method_type,
          k50: {magnitude: this.k50, units: ""},
        }
      }

      // TEST-Type specific
      if (this.test_type === TEST_TYPES.QDT){
        collector["eta0b"] = {magnitude: this.eta0b, units:""}
        collector["a5"] = {magnitude: this.test_reference_area === TEST_REFERENCES.ap ? this.a5_ap : this.a5, units:"J/m²/K"}
      } else {
        collector["eta0hem"] = {magnitude: this.eta0hem, units:""}
        collector["ceff"] = {magnitude: this.test_reference_area === TEST_REFERENCES.ap ? this.a5_ap : this.a5, units:"J/m²/K"}
      }

      return collector
    },
    updateCollector(){
      this.$refs.form.validate()
      this.success_message = ""
      this.error_message = ""
      this.isSaving = true
      let collector = this.parseBackCollector()
      let backend_call = this.isNew ? backend.add_collector(collector) : backend.update_collector(collector)
      return backend_call.then(res => {
        this.collector = Array.isArray(res.data) ? res.data[0] : res.data
        this.parseCollector()
        if (this.isNew){
          this.$emit("save", this.collector)
          this.success_message = "Collector added to the database!"
        }
        else {
          this.$emit("update", this.collector)
          this.success_message = "Update successfull!"
        }
      }).catch(res => {
        this.error_message = res
      }).finally(() => {
        this.$refs.ConfigEditorTop.scrollIntoView({behavior: "smooth", offset: -100});
        this.isSaving = false
      })
    },
    getCollector(){
      if (!this.isNew){
        return backend.get_collector(this.collector_id).then(res => {
          this.collector = res.data
        }).catch(res => {
          this.error_message = res
        })
      }
    },
  },
}
</script>

<style scoped>

.toolbar{
  padding: 10px  30px 5px 30px;
}


sup.is_invalid{
  color: #dc6565;
}


.label{
  width: 150px;
}

.disable-events {
  opacity: 0.5;
  pointer-events: none;
}


::v-deep .v-text-field__suffix{
  margin-top: 0 !important;
}


.collector_info{
  opacity: 0.5;
  padding: 0 30px 0 30px;
  font-size: 14px;
}
</style>