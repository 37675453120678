<template>
  <v-overlay :value="value" @input="$emit('input', $event.target.value)">
    <v-card width="400" style="padding: 10px" color="secondary" dark>
      <v-list-item>
        <v-list-item-avatar>
          <v-progress-circular indeterminate color="accent"> </v-progress-circular>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title> {{title}} </v-list-item-title>
          <v-card-text class="text_subheader"> <slot></slot> </v-card-text>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  props:{
    value: {type: Boolean, default: false},
    title: {default: ""},
  },
  name: "LoadingScreen",
}
</script>

<style scoped>

.text_subheader{
  opacity: 0.7;
  padding: 2px;
}
</style>