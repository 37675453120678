<template>
  <config-container :error_message="error_message" @drop="handleDataDrop" @dragenter="handleDragStart">
      <h1> Register Sensors </h1>
      <p>
        Please provide a measurement data to update the registered sensors.<br>
      </p>

      <v-container class="collectorArrayContainer mt-10">

        <!--  DATA Upload -->
        <v-row>
          <v-file-input
              label="Click to select a file or drop it here"
              v-model="csvFile"
              show-size
              accept="text/csv"
              single-line
              outlined
              @change="getSensorNamesFromCsv"
              @dragleave.stop.prevent="handleDragLeave"
              :loading="isReadingFile ? 'accent' : false"
              loader-height="5"
              class="mb-5"
          ></v-file-input>
          <DataUpload_Settings v-model="csv_settings" :plant_id="plant_id"> </DataUpload_Settings>
        </v-row>

        <v-overlay
            absolute
            :color="hovering ? 'ternary' : 'primary'"
            opacity="0.9"
            :value="is_file_dragged"
            class="overlay_data_upload"
            :class="{'dragging_inside': hovering, 'dragging_outside': is_file_dragged & !hovering}"
            @dragover="hovering=true"
            @dragleave="hovering=false"
            @drop="hovering=false"
        >
          <v-row justify="center" >
            <v-icon x-large> mdi-download </v-icon>
            <v-card-title v-if="!hovering"> Drop your Files here </v-card-title>
            <v-card-title v-if="hovering"> Release the Files to upload </v-card-title>
          </v-row>
        </v-overlay>


        <!-- Summary Infos -->
        <template v-if="plantHasSensors || csvLoaded">

          <!-- Sensor List -->
          <ConfigSection name="Sensors" style="height: calc(100vh - 600px)">
            <v-chip v-if="!!index" :key="index" color="gray" text-color="black" title="used as index"> {{index}} </v-chip>
            <v-chip v-for="(sensor, i) in savedSensors" :close="!csvLoaded" @click:close="sensorToDelete=sensor" :key="sensor.raw_name+i" :color="plantHasSensors ? 'ternary' : 'gray'" title="stored sensor">  {{sensor.raw_name}} </v-chip>
            <v-chip v-for="(sensor, i) in newSensorsCsv" close @click:close="ignoreCsvSensor(sensor)" :key="sensor+i" :color="csvLoaded ? 'accent' : 'gray'" text-color="black" title="new sensor"> {{sensor}} </v-chip>
          </ConfigSection>

          <v-row class="ma-2 pa-0 px-4">
            <div>
              <span :class="[plantHasSensors ? 'ternary--text' : 'gray--text']" style="font-size: 16px; padding: 0 4px;"> {{plantSummaryInfo}} </span> <br>
              <span :class="[csvLoaded ? 'accent--text' : 'gray--text']" style="font-size: 16px; padding: 0 4px;"> {{csvSummaryInfo}} </span> <br>
            </div>
          </v-row>

          <v-row class="ma-0 pa-0 px-4">
            <v-btn color="warning" @click="sensorToDelete=savedSensors"> Delete all </v-btn>
            <v-spacer></v-spacer>
            <v-btn :disabled="!csvLoaded" @click="discardNewSensors" outlined color="white" class="my-auto mx-2"> <v-icon small> mdi-close</v-icon> cancel </v-btn>
            <v-btn :disabled="newSensorsCsv <= 0" @click="handleSaveSensors" color="accent black--text" class="ma-auto"> Save new Sensors </v-btn>
          </v-row>

        </template>
      </v-container>

      <SensorDeleteDialog
          v-model="sensorToDelete"
          v-on:delete="getSavedSensors"
          v-on:error="(res) => error_message=res"
      ></SensorDeleteDialog>

      <br>
      <v-row>
        <v-btn color="white" light outlined @click="goBack"> Back </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="white" outlined dark @click="goNext" :disabled="!plantHasSensors"> Next </v-btn>
      </v-row>
    </config-container>
</template>

<script>
import * as backend from "@/plugins/backend_api";
import ConfigSection from "@/components/ConfigSection";
import configContainer from "@/components/ConfigContainer";
import SensorDeleteDialog from "@/components/SensorDeleteDialog.vue";
import DataUpload_Settings from "@/components/DataUpload_Settings.vue";

export default {
  name: "CreateSensors",
  components: {DataUpload_Settings, SensorDeleteDialog, configContainer, ConfigSection},
  props: {
    plant_id: {},
  },
  data: () => ({
    // Files
    csvFile: [],
    csv_settings: {},


    error_message: "",
    is_file_dragged: false,
    hovering: false,

    // Sensors From User
    newSensorsCsv: [],

    // Sensors from Backend/Database
    savedSensors: null,
    index: null,

    // Info

    // Helper
    isReadingFile: false,
    csvLoaded: false,
    plantHasSensors: false,
    sensorToDelete: null,
  }),
  mounted() {
    addEventListener("dragover", this.handleDragStart)
    addEventListener("dragleave", this.handleDragLeave)
    addEventListener("drop", this.handleDragLeave)
    this.getSavedSensors()
  },
  methods: {
    // Navigation
    goNext(){
      let name = (this.$router.currentRoute.path.includes("/plant/")) ? "sensorOverview" : "setup_sensorMapping"
      this.$router.push({name: name, params: {plant_id: this.plant_id}})
    },
    goBack(){
      let name = (this.$router.currentRoute.path.includes("/plant/")) ? "sensorOverview" : "setup_arrayOverview"
      this.$router.push({name: name, params: {plant_id: this.plant_id} })
    },

    // Get Files
    handleDataDrop(e){
      this.handleDragLeave()
      this.csvFile = e.dataTransfer.files[0]
      this.getSensorNamesFromCsv()
    },
    handleDragStart(){
      this.is_file_dragged = true
    },
    handleDragLeave(){
      this.is_file_dragged = false
    },

    // Load Sensors from Files
    discardNewSensors(){
      this.csvFile = null
      this.getSensorNamesFromCsv()
    },
    getSensorNamesFromCsv() {
      if (this.csvFile === null){
        this.newSensorsCsv = []
        this.csvLoaded = false
        return
      }

      this.isReadingFile = true
      return backend.getUploadResponse(this.plant_id, this.csvFile, this.csv_settings).then(res => {

        this.sensorsCsv = res.data.sensors
        this.newSensorsCsv = this.sensorsCsv.filter(sensor => !this.savedSensors.map(item => item.raw_name).includes(sensor))
        this.index = res.data.index
        this.csvLoaded = true
      }).catch(res => {
        this.error_message = res
      }).finally(() => {
        this.isReadingFile =false
      })
    },

    // Getter from Backend API
    // ---------------
    // Load Sensors from Backend/Database
    getSavedSensors() {
      return backend.get_sensors(this.plant_id).then(res => {
        let all_sensors = res.data
        let sensors = all_sensors.filter(item => !item["is_virtual"])
        this.savedSensors = sensors
        this.plantHasSensors = Object.keys(sensors).length > 0
      }).catch(res => {
        this.error_message = res
      })
    },

    // Posts to Backend API
    // ---------------

    handleSaveSensors(){
      let res = this.saveNewSensors(this.newSensorsCsv).then(() => {
        this.getSavedSensors()
        this.newSensorsCsv = []
      }).catch(res => {
        this.error_message = res
      })
      return res
    },
    ignoreCsvSensor(sensor){
      let filteredCsvSensors = this.newSensorsCsv.filter(item => !(item === sensor))
      this.newSensorsCsv = filteredCsvSensors
    },
    saveNewSensors(sensor_names){
      let sensor_data = sensor_names.map(item => ({"raw_name": item}))
      return backend.create_sensor(this.plant_id, sensor_data).catch(res => {
        this.error_message = res
      })
    },
  },
  computed: {
    csvSummaryInfo(){
      return !this.csvLoaded ? "insert a file to register new sensors" : this.newSensorsCsv.length + " new sensors in csv file"
    },
    plantSummaryInfo(){
      return Object.keys(this.savedSensors).length === 0 ? "Plant has no sensors yet" : Object.keys(this.savedSensors).length+" sensors saved in plant"
    },
  }
}
</script>

<style scoped>

.collectorArrayContainer{
  min-height: calc(min(60vh - 100px, 400px));
}


.dragging_outside {
  margin: 10px;
  border: 1px dashed white;
}
.dragging_inside {
  margin: 10px;
  border: 1px solid white;
}

.v-overlay{
  transition: 0.01s cubic-bezier(0.25, 0.8, 0.5, 1), z-index 1ms;
}

</style>