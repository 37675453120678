<template>
    <v-container class="px-6 ma-0 py-0" @click="highlighted_collector = null" fluid>
      <top-line-alert :error_message="error_message"></top-line-alert>
      <v-alert type="info" v-if="(info_message !== null) & (info_message !== '')" dismissible> {{info_message}}</v-alert>

      <v-toolbar class="mt-2 pa-0" color="white" outlined flat rounded>
        <v-text-field autofocus hide-details style="max-width: 300px" outlined dense v-model="search" placeholder="Filter Collectors" @input="filterCollectors" append-icon="mdi-magnify"></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="ternary" dark @click="newCollector"> Add New </v-btn>
      </v-toolbar>

      <v-card class="mt-2 pa-0" dark>
        <v-simple-table fixed-header height="calc(100vh - 350px)" dense class="veryverylight" light>
            <thead>
              <tr>
                <th class="primary white--text" > ID</th>
                <th class="primary white--text"> Collector Name</th>
                <th class="primary white--text"> Manufacturer </th>
                <th class="primary white--text" style="text-align: right;" v-if="$vuetify.breakpoint.mdAndUp"> &eta;<sub>0hem</sub> [-] </th>
                <th class="primary white--text" style="text-align: right;" v-if="$vuetify.breakpoint.lgAndUp"> &eta;<sub>0b</sub> [-] </th>
                <th class="primary white--text" style="text-align: right;" v-if="$vuetify.breakpoint.mdAndUp"> a<sub>1</sub> [W/m²K]</th>
                <th class="primary white--text" style="text-align: right;" v-if="$vuetify.breakpoint.mdAndUp"> a<sub>2</sub> [W/m²K²]</th>
                <th class="primary white--text" style="text-align: right;" v-if="$vuetify.breakpoint.lgAndUp"> a<sub>5</sub> [J/m²K]</th>
                <th class="primary white--text" style="text-align: right;" v-if="$vuetify.breakpoint.lgAndUp"> Gross Area [m²]</th>
                <th class="primary white--text"> License </th>
                <th class="primary white--text"> </th>
              </tr>
            </thead>
          <tbody>
            <tr v-for="collector in filteredCollectors"
                :key="collector.id"
                @click.stop="highlighted_collector = collector"
                @dblclick="selectCollector(collector)"
                :class="{'highlighted': highlighted_collector === collector, 'selected': selected_collector === collector.name}"
            >
              <td > {{ collector["id"] }}</td>
              <td> {{ collector["name"] }}</td>
              <td> {{ collector["manufacturer_name"] }} </td>

              <td style="text-align: right;font-size: 12px" v-if="$vuetify.breakpoint.lgAndUp"> {{ parseValue(collector, "eta0hem", 2) }} </td>
              <td style="text-align: right;font-size: 12px" v-if="$vuetify.breakpoint.mdAndUp"> {{ parseValue(collector, "eta0b", 2) }} </td>
              <td style="text-align: right;font-size: 12px" v-if="$vuetify.breakpoint.mdAndUp"> {{ parseValue(collector, "a1", 2)}} </td>
              <td style="text-align: right;font-size: 12px" v-if="$vuetify.breakpoint.mdAndUp"> {{ parseValue(collector, "a2", 3)}} </td>
              <td style="text-align: right;font-size: 12px" v-if="$vuetify.breakpoint.lgAndUp"> {{ parseValue(collector, "a5", 0)}} </td>
              <td style="text-align: right;font-size: 12px" v-if="$vuetify.breakpoint.lgAndUp"> {{ parseValue(collector, "area_gr", 1)}} </td>
              <td style="font-size: 12px"> <a :href="'https://www.google.com/search?q=Solar Keymark '+decodeURI(collector['licence_number'])" target="_blank"> {{ collector["licence_number"] }} </a> </td>

              <td>
                <v-btn-toggle rounded>
                  <v-btn x-small color="error" @click="markForDeletion(collector)" title="delete collector"> <v-icon small> mdi-trash-can</v-icon></v-btn>
                  <v-btn x-small color="ternary" @click="editCollector(collector)" title="select collector"> <v-icon small> mdi-pencil </v-icon></v-btn>
                </v-btn-toggle>
              </td>
            </tr>

          </tbody>
        </v-simple-table>
      </v-card>
      <v-row class="my-5 mx-0">
        <v-btn v-if="!!cancelButton" color="primary" outlined @click="$emit('cancel')"> {{ cancelButton }} </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="!!selectButton" color="primary" @click="selectCollector(highlighted_collector)" :disabled="!highlighted_collector"> {{ selectButton }} </v-btn>
      </v-row>


      <v-dialog v-model="show_modal" max-width="800" >
        <v-card>
          <CollectorEditor :collector_id="'id' in editing_collector ? editing_collector.id : 'new'" @cancel="show_modal=false" @save="handle_saved" @update="getCollectors"></CollectorEditor>
        </v-card>
      </v-dialog>

      <delete-dialog :show="deleting_collector !== null" header="Delete Collector?" @delete="deleteCollector(deleting_collector)">
        Do you really want to delete the collector <v-chip> {{!deleting_collector ? "" : deleting_collector.name}} </v-chip> ? <br>
        This change cannot be undone!
      </delete-dialog>

    </v-container>
</template>

<script>
  import * as backend from "@/plugins/backend_api";
  import {checkIfIncluded} from "@/pages/helper";
  import CollectorEditor from "@/components/CollectorEditor.vue";
  import DeleteDialog from "@/components/DialogDelete.vue";
  import TopLineAlert from "@/components/TopLineAlert.vue";

  export default {
    name: 'CollectorListComponent',
    components: {TopLineAlert, DeleteDialog, CollectorEditor},
    props: {
      cancelButton: {default: false},
      selectButton: {default: "Select"},
      selected_collector: {default: null},
    },
    mounted() {
      this.getCollectors()
    },
    data: () => ({
      // Data from Backend
      collectors: [],
      filteredCollectors: [],

      editing_collector: {},
      highlighted_collector: null,

      search: "",
      show_modal: false,
      deleting_collector: null,

      info_message: "",
      error_message: "",
    }),
    methods: {
      getCollectors(){
        this.error_message = ""
        backend.get_collectors().then(res =>{
          this.collectors = res.data
          this.filterCollectors()
        }).catch(res => {
          this.error_message = res
        })
      },
      selectCollector(collector){
        this.$emit("input", collector)
      },
      editCollector(collector){
        this.show_modal = true
        this.editing_collector = collector
        // this.$router.push({path: this.$router.currentRoute.fullPath + "/" + collector.id, params: {collector_id: collector.id}})
      },
      markForDeletion(collector){
        this.deleting_collector = collector
      },
      deleteCollector(collector){
        this.error_message = ""
        this.info_message = ""
        this.deleting_collector = null
        backend.delete_collector(collector.id)
            .then(() => {
              this.getCollectors()
              this.info_message = "Collector deleted sucessfully."
            })
            .catch(res => this.error_message = res)
      },
      handle_saved(collector){
        this.editing_collector = collector
        this.getCollectors()
      },
      newCollector(){
        this.show_modal = true
        this.editing_collector = {}
      },
      filterCollectors(){
        this.filteredCollectors = []
        for (let i = 0; i < this.collectors.length; i = i + 1) {
          let collector = this.collectors[i];
          let searchString = this.search.toLowerCase();
          let isNameIncluded = checkIfIncluded(collector["name"], searchString)
          let isManufacturerIncluded = checkIfIncluded(collector["manufacturer_name"], searchString)
          let isLicenceNrIncluded = checkIfIncluded(collector["licence_number"], searchString)
          let isProductNameIncluded = checkIfIncluded(collector["product_name"], searchString)
          let isIDIncluded = checkIfIncluded(collector["id"], searchString)
          let isIncludedAnywhere = isNameIncluded || isManufacturerIncluded || isIDIncluded || isLicenceNrIncluded || isProductNameIncluded
          if (isIncludedAnywhere) {
            this.filteredCollectors.push(collector)
          }
        }
        this.filteredCollectors.sort((a, b) => a.id - b.id)
      },
      parseValue(collector_data, key, decimals=2){
        return !collector_data[key] ? "-----" : collector_data[key]["magnitude"].toFixed(decimals)
      }
    },
    computed: {}
  }
  
</script>

<style scoped>


tr.highlighted{
  background-color: var(--v-verylight-base);
}
tr.selected{
  background-color: var(--v-light-base);
}
</style>