<template>
  <v-text-field
      outlined
      dense
      hide-details
      filled
      :disabled="disabled"
      :value="value"
      @input="emitValue"
      :suffix="suffix"
      :placeholder="placeholder"
      :rules="rule_array"
      :type="type"
  ></v-text-field>
</template>

<script>


export default {
  name: "configTextField",
  emits: ['input:value'],
  props: {
    value: {default: ""},
    label: {default:""},
    suffix: {default:""},
    rules: {default: () => []},
    description: {default: ""},
    placeholder: {default: ""},
    type: {default: "text"},
    maximum: {},
    minimum: {},
    required: Boolean,
    disabled: Boolean,
  },
  data: () => ({
  }),
  methods: {
    emitValue(newValue){
      this.$emit('input', newValue);
    },
    emitError(){
      this.$emit('error', this.error_message);
    },
  },
  watch: {
    error_message() {
      this.emitError()
    },
  },
  computed: {
    rule_array(){
      let rules_array = [
        this.required ? INPUT_RULES.required(this.value) : true,
        !isNaN(this.maximum) || !isNaN(this.minimum) ? INPUT_RULES.number(this.value) : true,
        !isNaN(this.maximum) ? INPUT_RULES.maximum(this.value, this.maximum) : true,
        !isNaN(this.minimum) ? INPUT_RULES.minimum(this.value, this.minimum) : true,
        this.type === "number" ? INPUT_RULES.number(this.value) : true
      ]
      return [...rules_array, ...this.rules.map(rule => rule(this.value))]
    },
    error_message() {
      let error_messages = this.rule_array.filter(val => val !== true)
      return error_messages[0] || ""
    },
  }
}


export const INPUT_RULES = {
  required: value => !!value || value === 0 || 'Required.',
  number: value => !isNaN(value) || 'Please insert a number (use . for decimals).',
  minimum: (value, minimum) => !value || value >= minimum || 'Value is below minimum of ' + minimum.toString() + ". ",
  maximum: (value, maximum) => !value || value <= maximum || 'Value is above maximum of ' + maximum.toString() + ". ",
}
</script>

<style scoped>

.label{
  width: 150px;
}

</style>