<template>
  <v-overlay >
    <v-card color="almost_white" max-width="600" light class="pa-5 ma-10">
      <v-list-item>
        <v-list-item-icon>
          <v-icon size="150" color="accent" >mdi-white-balance-sunny </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-card-title > Well done!</v-card-title>
          <v-card-text class="pb-0">
            You have successfully added a new plant! <br>
            Now you can access the full functionality of SunPeek.
            <v-subheader style="height: 20px" class="ma-0 mt-2 pl-0"> Enjoy your time! </v-subheader>
          </v-card-text>
          <v-card-actions>
            <v-spacer> </v-spacer>
            <v-btn color="secondary" dark :to="{name: 'plant'}"> Continue </v-btn>
          </v-card-actions>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-overlay>
</template>

<script>

  import * as backend from "@/plugins/backend_api";

  export default {
    props: ["plant_id"],
    name: 'Plant_Success',
    data: () => ({
    }),
    mounted() {
      this.recalculateVirtualSensors()
    },
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.smAndDown;
      }
    },
    methods: {
      recalculateVirtualSensors(){
        backend.recalculateVirtuals(this.plant_id)
      },
    },
  }
</script>

<style scoped>

.landing_page_container{
  height: 100%;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.landing_page_container .v-card{
  padding: 30px;
  max-width: 400px;
  margin: 0 auto;
}

.landing_page_container .v-btn{
  height: unset !important;
}

</style>