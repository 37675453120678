<template>
  <v-list-item >
    <v-list-item-avatar color="grey" size="30" rounded>
      <span class="white--text text-subtitle-1"> {{ plant_id }} </span>
    </v-list-item-avatar>

    <v-list-item-content>
      <span class="text-truncate" style="max-width:150px"> {{ plant_data.name }} </span>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import * as backend from "@/plugins/backend_api";

export default {
  name: "NavigationPlantHeader",
  props: {
    plant_id: {},
  },
  data: () => ({
    plant_data: {},
  }),
  mounted() {
    this.getPlant()
  },
  methods: {
    getPlant(){
      return backend.get_plant(this.plant_id).then(res => {
        this.plant_data = res.data
      })
    },
  }
}
</script>

<style scoped>

</style>