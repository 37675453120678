<template>
  <v-card color="transparent" flat style="width: 100%; height: 100%">
    <!-- Table Response -->
    <ConfigSection padding="0px" class="mt-1" name="Parsed File:">
      <v-simple-table dense light class="verylight" fixed-header height="calc(100vh - 550px)" >
        <thead>
        <tr>
          <th class="lighter" style="min-width: 100px!important; font-size: 12px;"> Index </th>
          <th class="lighter" style="min-width: 100px!important; font-size: 11px;" v-for="column in column_names" :key="column"> {{column}} </th>
        </tr>
        </thead>
        <tbody>

        <tr v-if="csvFile === null" disabled>
          <td colspan="100%"> <v-subheader> Please use the input above to upload files </v-subheader></td>
        </tr>
        <tr v-else-if="isReadingFile" disabled>
          <td colspan="100%">
            <v-row class="pa-2 ma-0">
              <v-progress-circular indeterminate width="3" size="20"></v-progress-circular>
              <v-subheader style="height: 20px !important;"> Loading data </v-subheader>
            </v-row>
          </td>
        </tr>
        <tr v-else-if="!!upload_error_message && upload_error_message!==''" disabled>
          <v-subheader class="pa-3 warning--text" style="height: inherit"> Parsing the file was not successful: <br/>
            "{{ "response" in upload_error_message ? upload_error_message.response.data.detail : upload_error_message }}"<br>
            Please check your settings!
          </v-subheader>
        </tr>
        <tr v-else v-for="(values, i) in column_values" :key="i">
          <td> {{index_values[i]}}</td>
          <td v-for="(value, j) in values" :key="j"> {{value === null ? "NaN" : value }} </td>
        </tr>
        </tbody>
      </v-simple-table>
    </ConfigSection>

  </v-card>
</template>

<script>
import * as backend from "@/plugins/backend_api";
import ConfigSection from "@/components/ConfigSection.vue";

export default {
  name: "DataUpload_InspectionTable",
  components: {
    ConfigSection
  },
  props: {
    plant_id: {},
    csvFile: {default: null},
    csv_settings: {default: null},
  },
  data: () => ({
    // information from backend
    savedSensors: [],

    // response from backend
    index_name: null,
    column_names: [],
    index_values: [],
    column_values: [],
    used_settings: {},

    // Helper
    upload_error_message: "",
    isReadingFile: false,
    isCsvLoaded: false,
  }),
  mounted() {
    this.getSavedSensors()
    this.getUploadResponse()
  },
  watch: {
    csvFile() {
      this.getUploadResponse()
    },
    csv_settings() {
      this.getUploadResponse()
    }
  },
  methods: {
    getSavedSensors() {
      backend.get_sensors(this.plant_id).then(res => {
        this.savedSensors = res.data.filter(val => !val.is_virtual)
        this.isSensorsExist = this.savedSensors.length > 0
      })
    },

    // Load Sensors from Files
    getUploadResponse() {
      this.isCsvLoaded = false
      if ((this.csvFile === null) || (this.csv_settings === null)) {
        this.resetResponse()
        return
      }

      this.isReadingFile = true
      return backend.getUploadResponse(this.plant_id, this.csvFile.file, this.csv_settings).then(res => {
        let response = res.data
        this.index_name = response.index
        this.column_names = response.data.columns
        this.column_values = response.data.data
        this.index_values = response.data.index
        this.upload_error_message = ""
      }).catch(res => {
        this.resetResponse()
        this.upload_error_message = res
      }).finally(() => {
        this.isReadingFile = false
        this.isCsvLoaded = true
      })
    },
    resetResponse() {
      this.index_name = null
      this.column_names = []
      this.column_values = []
      this.index_values = []
      this.upload_error_message = ""
    },
  },
}
</script>

<style scoped>


table td{
  border: thin solid rgba(255, 255, 255, 0.12);
}

</style>