<template>
  <config-container :error_message="error_message" max_width="800">

    <v-row class="px-3 py-3">
      <h1> Sensor Details </h1>
      <v-spacer></v-spacer>
      <v-btn color="error" small v-if="!is_locked" @click="deleteRequested=true"> <v-icon small dark left > mdi-delete </v-icon> Delete Sensor </v-btn>
    </v-row>

    <p> This shows all information about the sensor </p>

    <delete-dialog
        :show="deleteRequested"
        header="Delete Sensor"
        @delete="deleteSensor"
        @cancel="cancelDelete"
    >
       Do you really want to delete the sensor <v-chip label dense> {{sensor_data.raw_name}} </v-chip>? <br>
       This deletes all data attached to it and removes it from the mapping.
    </delete-dialog>

    <v-form v-model="form_valid" lazy-validation @submit="updateSensor" ref="form">

      <v-row class="px-4 py-1">
        <v-spacer></v-spacer>
        <v-btn color="ternary" small v-if="is_locked" @click="is_locked = !is_locked"> <v-icon small dark left > mdi-pencil </v-icon> Edit </v-btn>
        <v-btn color="ternary" small v-else text @click="is_locked = !is_locked"> <v-icon small dark left > mdi-pencil </v-icon> Cancel Edit </v-btn>
      </v-row>

      <config-section name="Basics">
        <config-text-field-row
            label="Id"
            :value="sensor_data.id"
            disabled
            description="Unique ID of the sensor in the SunPeek database"
        ></config-text-field-row>
        <config-text-field-row
            label="Name"
            :value="sensor_data.raw_name"
            disabled
            description="Name of the sensor as it appears in the measurement data"
        ></config-text-field-row>
        <config-text-field-row
            label="Calculated"
            :value="sensor_data.is_virtual"
            disabled
            description="Specifies whether this sensor is calculated based on other inputs"
        ></config-text-field-row>
      </config-section>

      <config-section name="Details">
        <config-autocomplete
            label="SensorType"
            v-model="sensor_data.sensor_type"
            :items="sensortype_names"
            required
            :disabled="is_locked || sensor_data.is_mapped"
            :description="sensor_data.is_mapped ? 'Type of measurement done with the sensor. Cannot be changed as the sensor is mapped' : 'Type of measurement done with the sensor.'"
        ></config-autocomplete>
        <config-autocomplete
            label="Unit"
            v-model="sensor_data.formatted_unit"
            :items="getCompatibleUnits(sensor_data)"
            required
            :disabled="is_locked"
            description="Measurement Unit of this sensor as it appears in the data."
        ></config-autocomplete>
      </config-section>

      <config-section name="Properties / Info">
        <config-text-field-row
            v-for="(_, label) in sensor_data.info"
            :key="label"
            :label="label"
            :suffix="get_InfoCheckAttribute(sensor_data, label, 'unit')"
            :maximum="get_InfoCheckAttribute(sensor_data, label, 'maximum')"
            :minimum="get_InfoCheckAttribute(sensor_data, label, 'minimum')"
            :description="get_InfoCheckAttribute(sensor_data, label, 'description')"
            required
            :disabled="is_locked"
            v-model="_.magnitude"
        ></config-text-field-row>
        <span v-if="isSet(sensor_data)" style="opacity: 0.3;"> No additional properties required </span>
      </config-section>


      <br>
      <v-row>
        <v-btn color="white" outlined light @click="$router.go(-1)"> Back </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="!is_locked" color="white" light :class="{'disable-events': !form_valid}" @click="updateSensor"> Save </v-btn>
      </v-row>
    </v-form>

  </config-container>
</template>

<script>

import * as backend from "@/plugins/backend_api";
import configContainer from "@/components/ConfigContainer";
import DeleteDialog from "@/components/DialogDelete.vue";
import ConfigSection from "@/components/ConfigSection";
import configTextFieldRow from "@/components/Config_TextFieldRow.vue";
import ConfigAutocomplete from "@/components/Config_Autocomplete";
import {getCompatibleUnits, getSensortype} from "@/pages/helper";

export default {
  name: 'EditSensor',
  components: {ConfigAutocomplete, configTextFieldRow, ConfigSection, DeleteDialog, configContainer},
  props: {
    plant_id: {},
    sensor_id: {},
  },
  data: () => ({
    sensor_data: {},
    sensortypes: [],
    sensortype_names: [],

    form_valid: false,
    deleteRequested: false,
    error_message: "",
    is_locked: false,
  }),
  mounted() {
    this.getSensorTypes()
    this.getSensor()
  },
  methods: {
    getSensorTypes(){
      return backend.get_sensortypes(this.sensor_id).then(response => {
        this.sensortypes = response["data"]
        this.sensortype_names = this.sensortypes.map(type => type.name)
      }).catch(res => {
        this.error_message = res
      })
    },
    getSensor() {
      return backend.get_sensor(this.plant_id, this.sensor_id).then(response => {
        this.sensor_data = response["data"]
        this.sensor_data = this.appendSensorWithRequiredInfo(this.sensor_data)
      }).catch(res => {
        this.error_message = res
      })
    },
    isSet(sensor_data){
      return sensor_data.info === undefined || Object.values(sensor_data.info).length === 0
    },
    appendSensorWithRequiredInfo(sensor){
      let sensorInfoNotSet = this.isSet(sensor)
      if (sensorInfoNotSet) {
        // adding info check with magnitude and units
        let sensorTypeInfo = {}
        let infoChecks = this.get_InfoChecks(sensor)
        Object.entries(infoChecks).forEach(([infoName, infoValues]) => {
          sensorTypeInfo[infoName] = {"magnitude": null, "units": infoValues.unit}
        })
        sensor.info = sensorTypeInfo
      }
      return sensor
    },


    // get relations based on sensor
    getCompatibleUnits(sensor) {
      return getCompatibleUnits(sensor, this.sensortypes)
    },
    getRelatedSensorType(sensor){
      return getSensortype(sensor, this.sensortypes)
    },



    get_InfoChecks(sensor){
      let sensorType = this.getRelatedSensorType(sensor)
      return (!!sensorType && !!sensorType.info_checks) ? sensorType.info_checks : {}
    },
    get_InfoCheckAttribute(sensor, property, check) {
      let infoChecks = this.get_InfoChecks(sensor)
      return infoChecks ? infoChecks[property][check] : null
    },

    

    cancelDelete() {
      this.deleteRequested = false
    },
    deleteSensor() {
      this.deleteRequested = false
      return backend.delete_sensor(this.plant_id, this.sensor_id).then(() => {
        let name = (this.$router.currentRoute.path.includes("/plant/")) ? "sensorOverview" : "setup_sensorOverview"
        this.$router.push({name: name, params: {plant_id: this.plant_id}})
      }).catch(res => {
        this.error_message = res
      })
    },
    updateSensor(){
      this.$refs.form.validate()

      // casting from string inputs to numbers (if there are any infos)
      Object.keys(this.sensor_data.info).forEach(info_label => {
        let info_item = this.sensor_data.info[info_label]
        if ("magnitude" in info_item){
          info_item["magnitude"] = parseFloat(info_item.magnitude)
        }
      })

      return backend.update_sensor(this.plant_id, this.sensor_data).then(() =>{
        this.is_locked = true
        this.goNext()
      }).catch(res => {
        this.error_message = res
        return this.getSensor()
      })
    },
    goNext(){
      let name = (this.$router.currentRoute.path.includes("/plant/")) ? "sensorOverview" : "setup_sensorOverview"
      this.$router.push({name: name})
    }
  }
}
</script>

<style scoped>

.toolbar{
  padding: 10px  30px 5px 30px;
}


sup.is_invalid{
  color: #dc6565;
}


.label{
  width: 150px;
}

.disable-events {
  opacity: 0.5;
}

</style>