<template>
    <CollectorEditor :collector_id="id" @cancel="$router.push({name: 'collectors'})" @save="editCollector"></CollectorEditor>
</template>
<script>

import CollectorEditor from "@/components/CollectorEditor.vue";
  export default {
    name: 'CollectorList',
    components: {CollectorEditor},
    props: ["id"],
    methods: {
      editCollector(collector){
        this.$router.push({params: {collector_id: collector.id}})
      },
    },
  }
</script>

<style scoped>

</style>