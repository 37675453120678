<template>
  <v-list-item
      :value="step"
      :disabled="!enabled"
      @click="routerTo"
  >
    <v-list-item-avatar
        class="white--text"
        size="25"
        style="justify-content: center"
        :color="enabled ? 'primary': 'grey' "
    >
      {{ step }}
    </v-list-item-avatar>

    <v-list-item-content>
      <slot></slot>
    </v-list-item-content>
  </v-list-item>

</template>

<script>
export default {
  name: "navStepper",
  props: {
    activeStep: {},
    step: {},
    route: {},
    enabled: Boolean,
  },
  methods: {
    routerTo(){
      this.$router.push({name: this.route}).catch(() => {})
    }
  },
}
</script>

<style scoped>

</style>