<template>
  <v-container style="max-width: 900px;">
    <h1> Overview  </h1>
    <v-card-title> Plant: {{ plant_data.name }} </v-card-title>

    <v-card :color="item.disabled ? 'light' : 'primary'" dark v-for="item in content" :key="item.header" class="mx-5 my-3" :to="item.to" :disabled="item.disabled">
      <div class="d-flex flex-no-wrap justify-space-between">
        <div>
          <v-card-title class="text-h5" v-text="item.header"></v-card-title>
          <v-card-subtitle v-text="item.text"></v-card-subtitle>
          <v-card-subtitle v-if="item.disabled" style="color: var(--v-primary-base)"> Please upload data first! </v-card-subtitle>
        </div>

        <v-avatar class="ma-3" size="120" tile>
          <v-img class="white--text align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.4)">
            <v-icon size="60" color="lightblue2"> {{ item.icon }} </v-icon>
          </v-img>
        </v-avatar>
      </div>
    </v-card>

    <v-row class="ma-0 mt-10 pa-0">
      <v-btn color="secondary" outlined :to="{name: 'plant_selection'}"> Back to Plant List </v-btn>
    </v-row>

  </v-container>
</template>

<script>

  import * as backend from "@/plugins/backend_api";

  export default {
    name: 'Plant_Overview',
    props: {
      plant_id: {},
    },
    data: () => ({
      error_message: "",
      plant_data: {},
      uploaded_files: [],

      content: [
        {id: 4, header: "Performance Check", text: "Evaluate your plant using the Thermal Power Check method", to: {name: "pcMethod"}, icon: "mdi-chart-areaspline", disabled: true},
        {id: 3, header: "Sensor Validation", text: "View sensor values and recalculate virtual sensors", to: {name: "validation"}, icon: "mdi-chart-line-variant", disabled: true},
        {id: 2, header: "Data Upload", text: "Upload new measurement data", to: {name: "dataUpload"}, icon: "mdi-database-arrow-up", disabled: false},
        {id: 1, header: "Configuration", text: "Check and adapt the configuration of your plant", to: {name: "config"}, icon: "mdi-cog", disabled: false},
      ],
    }),
    mounted() {
      this.getPlant()
      this.getUploadHistory()
    },
    methods: {
      getPlant() {
        if (this.plant_id === undefined) {
          return
        }
        return backend.get_plant(this.plant_id).then(res => {
          this.plant_data = res.data
        }).catch(() => {
          this.error_message = "Plant not found! Please check your URL"
        })
      },
      getUploadHistory(){
        backend.get_data_upload_history(this.plant_id).then(res => {
          this.uploaded_files = res.data
          let uploads_exist = this.uploaded_files.length >= 1

          this.content.forEach(val => {
            if ((val.id>=2) && uploads_exist) {
              val.disabled = false
            }
          })

        }).catch(res => {
          this.error_message = res
        })
      },
    },

  }
</script>

<style scoped>

</style>