<template>
  <v-app>
    <SunPeekHeader/>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>

import SunPeekHeader from "@/components/SunPeekHeader";

export default {
  name: 'App',
  components: {
    SunPeekHeader,
  },
};
</script>
