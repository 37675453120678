<template>
  <SunPeekDialog
      :value="show"
      :header="header"
      btn_confirm="Delete"
      color="warning"
      v-on:cancel="$emit('cancel')"
      v-on:confirm="$emit('delete')"
  >
    <slot></slot>
  </SunPeekDialog>
</template>


<script>
import SunPeekDialog from "@/components/Dialog.vue";

export default {
  name: "DeleteDialog",
  components: {SunPeekDialog},
  props: {
    show: Boolean,
    header: {default: ""},
  },
}
</script>

<style scoped>

</style>