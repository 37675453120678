<template>
  <v-container full-height>

    <v-navigation-drawer permanent clipped app class="pa-8" v-if="$vuetify.breakpoint.lgAndUp">
        <h3 style="color: grey; text-align: center"> New Plant</h3>
        <v-list dense nav>
          <v-list-item-group dense v-model="activeStep">
            <nav-stepper :step="1" route="setup_editPlant" enabled> Add Plant </nav-stepper>
            <nav-stepper :step="2" route="setup_arrayOverview" :enabled="plant_exists"> Add Arrays </nav-stepper>
            <nav-stepper :step="3" route="setup_newSensors" :enabled="arrays_exist"> Data Format </nav-stepper>
            <nav-stepper :step="4" route="setup_sensorMapping" :enabled="sensors_exist"> Map Sensors </nav-stepper>
            <nav-stepper :step="5" route="setup_sensorOverview" :enabled="at_least_one_sensor_mapped"> Configure Sensors </nav-stepper>
          </v-list-item-group>
        </v-list>
    </v-navigation-drawer>

    <v-stepper v-model="activeStep" height="50px" flat dense max-width="500px" class="mx-auto" v-else>
      <v-stepper-header>
        <v-card-title> Setup new Plant:  </v-card-title>
        <v-stepper-step :step="1" complete></v-stepper-step><v-divider></v-divider>
        <v-stepper-step :step="2" :complete="plant_exists"></v-stepper-step><v-divider></v-divider>
        <v-stepper-step :step="3" :complete="arrays_exist"></v-stepper-step><v-divider></v-divider>
        <v-stepper-step :step="4" :complete="sensors_exist"></v-stepper-step><v-divider></v-divider>
        <v-stepper-step :step="5" :complete="at_least_one_sensor_mapped"></v-stepper-step><v-divider></v-divider>
      </v-stepper-header>
    </v-stepper>

    <top-line-alert :error_message="error_message_internal + error_message"></top-line-alert>

    <router-view v-if="!error_message_internal" v-on:back="$router.push('/home')"> </router-view>

  </v-container>
</template>
<script>

import NavStepper from "@/components/_navStepper";
import TopLineAlert from "@/components/TopLineAlert";
import * as backend from "@/plugins/backend_api";
import {hasArray, hasSensors, isSensorsMapped, plantExists} from "@/pages/helper";

export default {
  name: "ConfigMain",
  props: {
    plant_id: {},
    error_message: {default: ""},
  },
  components: {TopLineAlert, NavStepper},
  data: () => ({
    activeStep: 1,
    error_message_internal: "",

    plant_data: null,
    reroutes: [
      {route: "setup_newPlant", step: 1},
      {route: "setup_editPlant", step: 1},
      {route: "setup_arrayOverview", step: 2},
      {route: "setup_newArray", step: 2},
      {route: "setup_editArray", step: 2},
      {route: "setup_newSensors", step: 3},
      {route: "setup_sensorMapping", step: 4},
      {route: "setup_sensorOverview", step: 5},
      {route: "setup_editSensor", step: 5},
    ],
  }),
  watch: {
    '$route'(){
      this.getPlant()
      this.selectStepByRoute()
    },
  },
  mounted() {
    this.selectStepByRoute()
    this.getPlant()
  },
  methods: {
    async getPlant() {
      if (this.plant_id === undefined){
        return
      }
      return await backend.get_plant(this.plant_id).then(res => {
        this.plant_data = res.data
      }).catch(() => {
        this.error_message_internal = "Plant not found! Please check your URL"
      })
    },
    selectStepByRoute(){
      let currentRoute = this.$router.currentRoute
      let route_name = currentRoute.name
      let reroute = this.reroutes.filter(item => item.route === route_name)[0]
      this.activeStep = reroute.step
    },
  },
  computed: {
    plant_exists(){
      return plantExists(this.plant_data)
    },
    arrays_exist(){
      return hasArray(this.plant_data)
    },
    sensors_exist(){
      return hasSensors(this.plant_data)
    },
    at_least_one_sensor_mapped(){
      return isSensorsMapped(this.plant_data)
    },
  }
}
</script>

<style scoped>

</style>