<template>
  <v-container style="height: 100%">
    <h5> {{name}} </h5>
    <v-card  :color="color" outlined :style="'padding:' + padding + '; border-color: '+bordercolor +' !important; overflow: auto; max-height: calc(100% - 20px)'">
      <slot ></slot>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ConfigSection",
  props: {
    name: {},
    color: {default: "primary"},
    bordercolor: {default: "#2e3a4b"},
    padding: {default: "30px"},
  },
}
</script>


<style scoped>

</style>