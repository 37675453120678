<template>
   <delete-dialog
       v-if="sensorsToDelete__internal.length > 0 && sensorsToDelete__internal[0] !== null"
       :show="sensorsToDelete__internal.length > 0 && sensorsToDelete__internal[0] !== null && Object.keys(sensorsToDelete__internal[0]).length > 0"
       header="Delete Sensor"
       @delete="deleteSensor"
       @cancel="cancel"
   >
     Do you really want to delete
        <template v-if="sensorsToDelete__internal.length > 1"> <v-chip dense> multiple ({{sensorsToDelete__internal.length}}) </v-chip> Sensors </template>
        <template v-else> the sensor <v-chip dense>{{sensorsToDelete__internal[0].raw_name}} </v-chip> </template>
     ? <br>
     This deletes all data attached to it and removes it from the mapping!

     <loading-screen title="Deleting" v-model="isLoading"> Please wait a few seconds while the sensors get deleted.  </loading-screen>
   </delete-dialog>
</template>


<script>
import DeleteDialog from "@/components/DialogDelete.vue";
import * as backend from "@/plugins/backend_api";
import LoadingScreen from "@/components/LoadingScreen.vue";

export default {
  name: "SensorDeleteDialog",
  components: {LoadingScreen, DeleteDialog},
  props:  ["sensorToDelete"],
  model: {
    prop: 'sensorToDelete',
    event: 'update'
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    sensorsToDelete__internal() {
      return Array.isArray(this.sensorToDelete) ? this.sensorToDelete : [this.sensorToDelete]
    },
  },
  methods: {
    deleteSensor(){
      this.isLoading = true
      let sensor_ids = this.sensorsToDelete__internal.map(val => val.id)
      let plant_id = this.sensorsToDelete__internal.map(val => val.plant_id)[0]
      return backend.delete_multiple_sensors(plant_id, sensor_ids).catch(res => {
        this.$emit("error", res)
      }).finally(() => {
        this.$emit("update", null)
        this.$emit('delete')
        this.isLoading = false
      })
    },
    cancel(){
      this.$emit("update", null)
      this.$emit('cancel')
      this.isLoading = false
    }
  },
}
</script>

<style scoped>

</style>