<template>
  <v-row>
    <v-subheader class="label">
      {{ label }}
      <sup v-if="required"> (*) </sup>
    </v-subheader>
    <config-text-field
      :disabled="disabled"
      :value="value"
      :suffix="suffix"
      :placeholder="placeholder"
      :rules="rules"
      :description="description"
      :label="label"
      :maximum="maximum"
      :minimum="minimum"
      :required="required"
      :type="type"
      @input="emitValue"
      @error="parseError"
    ></config-text-field>
    <InfoTooltip v-if="description || error_message" :error_message="error_message"> {{description}} </InfoTooltip>
  </v-row>
</template>

<script>
import ConfigTextField from "@/components/Config_TextField.vue";
import InfoTooltip from "@/components/_InfoTooltip.vue";

export default {
  name: "configTextFieldRow",
  components: {InfoTooltip, ConfigTextField},
  emits: ['input:value'],
  props: {
    value: {default: ""},
    label: {default:""},
    suffix: {default:""},
    rules: {default: () => []},
    description: {default: ""},
    placeholder: {default: ""},
    type: {default: "text"},
    maximum: {},
    minimum: {},
    required: Boolean,
    disabled: Boolean,
  },
  data: () => ({
    error_message: "",
  }),
  methods: {
    parseError(error_message){
      this.error_message = error_message
    },
    emitValue(newValue){
      this.$emit('input', newValue);
    },
  },
}


</script>

<style scoped>

.label{
  width: 150px;
}

</style>